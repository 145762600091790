<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"
    ></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <div class="col-md-12 text-nowrap">
            <div class="d-flex flex-column flex-sm-row justify-content-between">
              <h5 class="title-header">Orders</h5>

              <button
                  @click="newOrder"
                  class="btn text-capitalize btn-add-product mt-2 mt-sm-0">
                <span class="pr-3"><i class="fas fa-plus"></i></span>
                New Order
              </button>
            </div>
          </div>
        </div>

        <!--    Filter starts   -->
        <div class="row">
          <div class="col-md-12">
            <div class="bg-white text-nowrap d-flex flex-column flex-sm-row">
              <button class="btn btn-filter">Add Filter</button>

              <div class="pl-4 w-100 d-flex align-items-center">
                <img :src="'./assets/icons/page/search.svg'"/>

                <input
                    v-model="searchQuery"
                    @keyup="getOrdersByAdmin"
                    type="text"
                    class="w-100 field"
                    placeholder="Search by order Id or Customer Email"
                />
              </div>
            </div>
          </div>
        </div>
        <!--    Filter ends   -->

        <!--    Table starts   -->
        <div class="row">
          <div class="col-md-12">
            <div class="table-bg">
              <table class="table mb-0 text-nowrap">
                <thead class="thead-bg">
                <tr>
                  <th class="text-left">Order Id</th>
                  <th class="text-center">Date</th>
                  <th class="text-center">Total</th>
                  <th class="text-center">Payment Status</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Sales Channel</th>
                  <th class="text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="order in orderList" :key="order.id" class="t-rows">
                  <td class="text-left">{{ order.hash }}</td>
                  <td class="text-center">
                    {{ formatDate(order.createdAt) }}
                  </td>
                  <td class="text-center">
                    {{ toDisplayUnit(order.grandTotal) }} {{ currency }}
                  </td>
                  <td class="text-center">
                    <button
                        class="btn text-capitalize"
                        :class="{
                          'btn-confirmed':
                            order.paymentStatus === 'Paid',
                          'btn-reject':
                            order.paymentStatus === 'Failed',
                          'btn-reverted':
                            order.paymentStatus === 'Refunded',
                          'btn-pending':
                            order.paymentStatus === 'Pending',
                        }"
                    >
                      {{ order.paymentStatus }}
                    </button>
                  </td>
                  <td class="text-center">
                      <span
                          class="btn text-capitalize"
                          :class="{
                          'btn-confirmed': order.status === 'Completed',
                          'btn-reject': order.status === 'Cancelled',
                          'btn-deliver': order.status === 'Delivered',
                          'btn-pending': order.status === 'Pending',
                          'btn-processing': order.status === 'Processing',
                          'btn-shipping': order.status === 'Shipping',
                        }">{{ order.status }}
                      </span>
                  </td>
                  <td class="text-center">
                    {{ order.salesChannel }}
                  </td>
                  <td class="text-center">
                    <button v-on:click="viewOrder(order.id)" class="btn btn-view-details">View Details</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!--    Table ends   -->
        <div class="my-5">
          <BPagination
              align="center"
              :limit="10"
              :hide-goto-end-buttons="true"
              @input="getOrdersByAdmin"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage">
          </BPagination>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {BPagination} from "bootstrap-vue";
import axios from "axios";

import Header from "../../components/Header";
import PleaseWait from "../PleaseWait";
import ErrorComponent from "../ErrorComponent";
import Settings from "../../common/settings";
import SideBarLiveMart from "../SideBarLiveMart";
import Helpers from "@/helpers/helpers";
import SessionStore from "../../common/session_store";

export default {
  name: "OrdersView",
  components: {
    SideBarLiveMart,
    ErrorComponent,
    PleaseWait,
    Header,
    BPagination,
  },
  data() {
    return {
      // Flags
      isLoading: false,
      errors: "",

      // Variables
      rows: 100000000,
      currentPage: null,
      perPage: 25,
      searchQuery: "",

      // Data
      orderList: [],

      currency: ''
    };
  },
  mounted() {
    if (this.currentPage === null) {
      this.currentPage = SessionStore.getCurrentPage('orders');
    }
    this.currency = Settings.GetStoreCurrency();
    this.getOrdersByAdmin()
  },
  methods: {
    viewOrder: function (id) {
      window.open(`/#/store/order/${id}`, '_blank')
    },
    formatDate: function (v) {
      return Helpers.ParseDate(v)
    },
    newOrder: function () {
      this.$router.push('/store/orders/new');
    },
    getOrdersByAdmin: function () {
      this.isLoading = true;
      let payload = null

      if (SessionStore.getQuery('orders') !== this.searchQuery) {
        this.currentPage = 1;
      }
      SessionStore.setQuery('orders', this.searchQuery);

      if (this.searchQuery === "") {
        payload = {
          query: `
          query { ordersByAdmin(sort: { by: CreatedAt direction: Desc }, pagination: {perPage: ${this.perPage} page: ${this.currentPage}}){
                    id
                    hash
                    shippingCharge
                    paymentProcessingFee
                    subtotal
                    grandTotal
                    discountedAmount
                    status
                    paymentStatus
                    createdAt
                    updatedAt
                    salesChannel
            }}`,
        };
      } else {
        let termQuery = ""
        if (Helpers.IsEmail(this.searchQuery)) {
          termQuery = `customerEmail: "${this.searchQuery}"`
        } else {
          termQuery = `orderHash: "${this.searchQuery}"`
        }

        payload = {
          query: `
          query { ordersByAdmin(params: {${termQuery}}, sort: { by: CreatedAt direction: Desc }, pagination: {perPage: ${this.perPage} page: ${this.currentPage}}){
                    id
                    hash
                    shippingCharge
                    paymentProcessingFee
                    subtotal
                    grandTotal
                    discountedAmount
                    status
                    paymentStatus
                    createdAt
                    updatedAt
                    salesChannel
            }}`,
        };
      }

      axios
          .post(Settings.GetApiUrl(), payload, {
            headers: {
              "store-key": Settings.GetLiveMartKey(),
              "store-secret": Settings.GetLiveMartSecret(),
            },
          })
          .then((resp) => {
            this.isLoading = false;
            if (resp.status === 200) {
              let rs = resp.data.data;
              if (rs === null) {
                this.errors = resp.data.errors[0].message;
                return;
              }
              this.orderList = rs.ordersByAdmin;
              SessionStore.setCurrentPage('orders', this.currentPage);
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.errors = err.errors[0].message;
          });
    },
    toDisplayUnit(v) {
      return (v / 100).toFixed(2);
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  padding: 0 30px;
}

.bg-white,
.table-bg {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-top: 5px;
}

.btn-filter {
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #132eaa;
  padding: 15px 38px;
  border-right: 1px solid #e4eaec;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.helper img {
  display: block;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

input[type="text"] {
  height: 49px;
  font-size: 14px;
  border: 0;
  padding: 0 10px;
  outline: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaafbc;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

.field {
}

.table-bg {
  margin-top: 24px;
  overflow-y: visible;
}

.thead-bg {
  background-color: #ecf1fe;
}

.pl-34 {
  padding-left: 34px;
}

.thead-bg th {
  border: 0;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
}

.t-rows {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373b3d;
}

tbody tr td {
  vertical-align: middle;
}

.btn-processing,
.btn-confirmed,
.btn-pending,
.btn-reject,
.btn-view-details,
.btn-shipping,
.btn-deliver,
.btn-reverted {
  border-radius: 14px;
  background-color: #caece7;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #287166;
  padding: 6px 16px;
  border: 0;
  outline: none;
}

.btn-pending {
  cursor: default !important;
  background-color: #fae5bd;
  color: #8e620b;
}

.btn-reject {
  cursor: default !important;
  background-color: #fac7bd;
  color: #8e210b;
}

.btn-processing,
.btn-shipping,
.btn-reverted {
  cursor: default !important;
  background-color: #dfcaec;
  color: #552871;
}

.btn-deliver {
  cursor: default !important;
  background-color: #e1e4ea;
  color: #363b4a;
}

.btn-view-details {
  border: solid 1px #c2d2ff;
  background-color: #ffffff;
  color: #132eaa;
}

.btn-confirmed:focus,
.btn-reject:focus,
.btn-pending:focus,
.btn-view-details:focus,
.btn-shopping:focus,
.btn-deliver:focus,
.btn-reverted:focus {
  outline: none;
  border: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-add-product {
  border-radius: 3px;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 11px 19px;
  border: 0;
  outline: none;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }

  .btn-filter {
    border-right: 0;
    border-bottom: 1px solid #e4eaec;
  }
}
</style>
