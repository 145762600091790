<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"
    ></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <!--    Left side start     -->
          <div class="col-lg-8 mb-5">
            <!--    Head start     -->
            <div class="row head">
              <div class="col-md-12 d-flex align-items-center">
                <i
                    @click="$router.go(-1)"
                    class="fas fa-chevron-left img-nav"
                ></i>
                <h5 class="title-header">New Order</h5>
              </div>
            </div>

            <!--    Products list start  -->
            <div class="form-bg p-0">
              <div class="d-block d-md-flex p-24 overflow-auto">
                <h5 class="title-header pl-0">
                  Products in Cart
                </h5>
                <div class="ml-auto">
                  <button
                      class="
                      btn
                      text-capitalize
                      assign-product
                      text-nowrap
                      mt-2 mt-md-0
                    "
                      data-controls-modal="productListForAssign"
                      data-backdrop="static"
                      data-keyboard="false"
                      data-toggle="modal"
                      data-target="#productListForAssign">
                    <i class="fas fa-plus mr-2"></i>
                    Add Product
                  </button>
                  &nbsp;
                  <button v-on:click="onSelectCustomer"
                          class="
                      btn
                      text-capitalize
                      assign-product
                      text-nowrap
                      mt-2 mt-md-0
                    ">
                    <i class="fas fa-plus mr-2"></i>
                    Add Customer
                  </button>

                  <div class="modal fade" id="productListForAssign">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content modal-bg">
                        <div class="d-flex justify-content-between mx-24">
                          <h4 class="modal-title">Add product</h4>
                          <i data-dismiss="modal" class="fas fa-times mt-1"></i>
                        </div>

                        <div class="body-modal">
                          <div class="d-inline-flex container-search">
                            <span class="ml-3">
                              <span class="helper"></span>
                              <img :src="'./assets/icons/page/search.svg'"/>
                            </span>
                            <input
                                v-model="searchQuery"
                                @keyup="onProductSearch"
                                type="text"
                                class="field w-100"
                                placeholder="Search..."
                            />
                          </div>

                          <div
                              v-for="product in productList"
                              :key="product.id"
                              class="d-flex list-main">
                            <div>
                              <span class="helper"></span>
                              <img
                                  :src="getFullImagePath(product.images)"
                                  class="rounded-circle mr-2"
                                  width="53"
                                  height="53"
                                  alt=""
                              />
                            </div>
                            <div class="list-texts">
                              <p class="title">{{ product.name }}</p>
                              <p>Stock: {{ product.stock }} | Price: {{ toDisplayUnit(product.price) }}
                                {{ currency }}</p>
                            </div>
                            <div class="form-check-inline ml-auto mr-0">
                              <label class="form-check-label">
                                <input
                                    :disabled="product.stock===0"
                                    v-model="selectedProducts"
                                    v-bind:value="product.id"
                                    type="checkbox"
                                    class="form-check-input"
                                />
                              </label>
                            </div>
                          </div>
                        </div>

                        <div class="modal-footer">
                          <div class="d-flex m-0">
                            <button
                                class="btn btn-close mr-2"
                                data-dismiss="modal">
                              Close
                            </button>
                            <button
                                @click="updateProductList"
                                class="btn btn-publish btn-assign"
                                data-dismiss="modal">
                              Update Product List
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--    Table starts   -->
              <div class="row">
                <div class="col-md-12">
                  <div class="table-bg">
                    <table class="table mb-0 text-nowrap">
                      <thead class="thead-bg">
                      <tr>
                        <th class="text-left pl-34">Name</th>
                        <th class="text-center">Price</th>
                        <th class="text-center">Stock</th>
                        <th class="text-center">Quantity</th>
                        <th class="text-right"></th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                          v-for="product in productsInCart"
                          :key="product.id"
                          class="t-rows">
                        <td class="text-left pl-34">
                          <img
                              v-bind:src="getFullImagePath(product.images[0])"
                              class="rounded-circle mr-2"
                              width="34"
                              height="34"
                              alt=""
                          />
                          {{ product.name }}
                        </td>
                        <td class="text-center">
                          {{ toDisplayUnit(product.price) }} {{ currency }}
                        </td>
                        <td class="text-center">
                          {{ product.stock }}
                        </td>
                        <td class="text-center">
                          <span @click="decreaseProductQuantity(product.id)" class="fa fa-minus text-primary"></span>
                          &nbsp;
                          {{ product.quantity }}
                          &nbsp;
                          <span @click="increaseProductQuantity(product.id, product.stock)"
                                class="fa fa-plus text-primary"></span>
                        </td>
                        <td class="text-right pr-34">
                          <button
                              class="btn btn-option"
                              data-toggle="dropdown">
                            <i class="fas fa-ellipsis-h"></i>
                          </button>
                          <div
                              class="
                                dropdown-menu dropdown-menu-right
                                p-0
                                dropdown-bg
                              ">
                            <div
                                @click="removeProductFromCart(product.id)"
                                class="list d-flex justify-content-between">
                              <span>Delete</span>
                              <i class="far fa-trash-alt"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <!--    Table ends   -->
            </div>
            <!--    Products list end  -->
          </div>
          <!--    Left side end     -->

          <!--    Right side start     -->
          <div class="col-lg-4">
            <div class="row text-nowrap">
              <div class="col text-center">
                <button v-on:click="placeOrder"
                        class="btn text-capitalize btn-publish w-100">
                  Place Order
                </button>
              </div>
            </div>

            <div class="bg-white">
              <NewOrderSummary
                  :grand-total="getGrandTotal"
                  :subtotal="subtotal"
                  :payment-processing-fee="paymentProcessingFee"
                  :shipping-charge="shippingCharge"
                  :discount="discount"
                  :customer="customer"
              />
            </div>

            <div class="form-container" style="margin-top: 5px">
              <div class="form-group">
                <span>Select Shipping Method</span>

                <ValidationProvider v-slot="{ errors }">
                  <select class="form-control"
                          @change="onShippingMethodChanged"
                          v-model="selectedShippingMethod"
                          size="large">
                    <option
                        v-for="v in shippingMethodList"
                        :value="v.id"
                        :key="v.id">{{ v.displayName }}
                    </option>
                  </select>
                  <label class="label text-danger">{{ errors[0] }}</label>
                </ValidationProvider>
              </div>

              <div class="form-group" style="margin-top: -26px">
                <span class="title ml-1">Select Payment Method</span>

                <ValidationProvider rules="required" v-slot="{ errors }">
                  <select class="form-control"
                          v-model="selectedPaymentMethod"
                          @change="onPaymentMethodChanged"
                          size="large">
                    <option
                        v-for="v in paymentMethodList"
                        :value="v.id"
                        :key="v.id">
                      {{ v.displayName }} ({{ v.currencyName }})
                    </option>
                  </select>
                  <label class="label text-danger">{{ errors[0] }}</label>
                </ValidationProvider>
              </div>

              <div class="form-group" style="margin-top: -26px">
                <input v-model="couponCode" class="form-control" placeholder="Coupon code"/>
                <button v-on:click="applyCoupon" class="btn btn-info mt-1">Apply</button>
              </div>
              <div class="form-group" style="margin-top: -15px">
                <span class="title ml-1">Shipping & Billing Address</span>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <input v-model="street" class="form-control" placeholder="Street"/>
                  <label class="label text-danger">{{ errors[0] }}</label>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }">
                  <input v-model="streetOptional" class="form-control"
                         placeholder="Street optional"/>
                  <label class="label text-danger">{{ errors[0] }}</label>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <input v-model="city" class="form-control" placeholder="City"/>
                  <label class="label text-danger">{{ errors[0] }}</label>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }">
                  <input v-model="state" class="form-control" placeholder="State"/>
                  <label class="label text-danger">{{ errors[0] }}</label>
                </ValidationProvider>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <input v-model="postcode" class="form-control" placeholder="Postcode"/>
                  <label class="label text-danger">{{ errors[0] }}</label>
                </ValidationProvider>

                <span class="title ml-1">Location</span>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <select class="form-control"
                          v-model="locationId"
                          size="large">
                    <option
                        v-for="v in locationList"
                        :value="v.id"
                        :key="v.id">
                      {{ v.name }}
                    </option>
                  </select>
                  <label class="label text-danger">{{ errors[0] }}</label>
                </ValidationProvider>
              </div>
            </div>
          </div>
          <!--    Right side end     -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SideBarLiveMart from "../SideBarLiveMart";
import Header from "../Header";
import ErrorComponent from "../ErrorComponent";
import PleaseWait from "../PleaseWait";
import {extend} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import NewOrderSummary from "./NewOrderSummary";
import axios from "axios";
import Settings from "../../common/settings";
import SelectOrderCustomer from "./SelectOrderCustomer";

export default {
  name: "OrdersNew",
  components: {
    SideBarLiveMart,
    NewOrderSummary,
    Header,
    ErrorComponent,
    PleaseWait,
  },
  data() {
    return {
      // Flags
      isLoading: false,
      errors: "",

      searchQuery: "",
      selectedProducts: [],
      newItemsToAdd: [],

      // Data
      productsInCartList: [],
      productList: [],

      currency: '',

      subtotal: 0,
      grandTotal: 0,
      paymentProcessingFee: 0,
      shippingCharge: 0,
      discount: 0,

      shippingMethodList: [],
      selectedShippingMethod: null,
      paymentMethodList: [],
      selectedPaymentMethod: null,

      selectedCustomer: '',

      cartId: null,

      removedItems: [],

      couponCode: null,

      customer: null,

      isReadyToOrder: false,

      isShippingMethodRequired: false,

      street: null,
      streetOptional: null,
      city: null,
      state: null,
      postcode: null,
      locationId: null,
      locationList: []
    };
  },
  computed: {
    productsInCart: function () {
      return this.productsInCartList;
    },
    getGrandTotal: function () {
      return this.grandTotal + this.paymentProcessingFee + this.shippingCharge;
    }
  },
  mounted() {
    this.currency = Settings.GetStoreCurrency();
    this.createCart();
    this.getProductList();
    this.getStorePaymentMethodList();
    this.getShippingMethodListAsAdmin();
    this.listLocations();
  },
  methods: {
    isReadyToPlaceOrder: function () {
      let ready = this.cartId !== null && this.productsInCartList !== null
          && this.productsInCartList.length > 0 && this.customer !== null && this.selectedPaymentMethod !== null;

      console.log('IsReady: ', ready);

      if (this.isShippingMethodRequired) {
        ready = ready && this.selectedShippingMethod !== null;
        ready = ready && this.street !== null
            && this.city !== null && this.locationId !== null;

        console.log('IsReady: ', ready);
      }

      console.log('IsReady: ', ready);

      this.isReadyToOrder = ready;
    },
    onSelectCustomer: function () {
      this.$dlg.modal(SelectOrderCustomer, {
        width: 350,
        height: 300,
        title: 'Choose Customer',
        params: {},
        maxButton: false,
        callback: data => {
          this.customer = data.customer;
        }
      });
    },
    applyCoupon: function () {
      if (this.couponCode === null || this.couponCode === '') {
        return
      }

      this.isLoading = true;

      let shippingMethodQuery = ``;
      if (this.selectedShippingMethod !== null) {
        shippingMethodQuery += ` shippingMethodId: "${this.selectedShippingMethod}"`
      }
      if (this.customer !== null) {
        shippingMethodQuery += ` customerId: "${this.customer.id}"`
      }

      let pld = {
        query: `query { checkDiscountByAdmin(cartId: "${this.cartId}" couponCode: "${this.couponCode}" ${shippingMethodQuery}) }`
      }

      axios.post(Settings.GetApiUrl(), pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        this.isLoading = false;

        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
            return;
          }

          this.discount = rs.checkDiscountByAdmin;
        }
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.errors[0].message
      });
    },
    postUpdateCart: function () {
      if (this.selectedPaymentMethod !== null) {
        this.onPaymentMethodChanged();
      }
      if (this.selectedShippingMethod !== null) {
        this.onShippingMethodChanged();
      }
      this.applyCoupon();
      this.isReadyToPlaceOrder();
    },
    createCart: function () {
      this.isLoading = true;

      let pld = {
        query: `mutation { newCart(params: { cartItems: [] }) { id isShippingRequired } }`
      }

      axios.post(Settings.GetApiUrl(), pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        this.isLoading = false;
        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
            return;
          }

          this.cartId = rs.newCart.id;
          this.isShippingMethodRequired = rs.newCart.isShippingRequired;

          console.log(this.cartId);
        }
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.errors[0].message;
      });
    },
    updateCart: function () {
      this.isLoading = true;

      let cartItemsQuery = ``
      this.productsInCartList.forEach(p => {
        cartItemsQuery += `{ productId: "${p.id}" quantity: ${p.quantity} }`;
      });
      this.removedItems.forEach(id => {
        cartItemsQuery += `{ productId: "${id}" quantity: 0 }`;
      });

      let pld = {
        query: `mutation { updateCart( id: "${this.cartId}" params: { cartItems: [${cartItemsQuery}] }) { id isShippingRequired } }`
      }

      axios.post(Settings.GetApiUrl(), pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        this.isLoading = false;
        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
          }

          this.isShippingMethodRequired = rs.updateCart.isShippingRequired;

          this.removedItems = null;
          this.removedItems = [];

          this.postUpdateCart();
        }
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.errors[0].message;
      });
    },
    onPaymentMethodChanged: function () {
      this.isLoading = true;

      let shippingMethodQuery = ``;
      if (this.selectedShippingMethod !== null) {
        shippingMethodQuery = ` shippingMethodId: "${this.selectedShippingMethod}"`
      }

      let pld = {
        query: `query { checkPaymentProcessingFee(cartId: "${this.cartId}" paymentMethodId: "${this.selectedPaymentMethod}" ${shippingMethodQuery}) }`
      }

      axios.post(Settings.GetApiUrl(), pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        this.isLoading = false;

        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
            return;
          }

          this.paymentProcessingFee = rs.checkPaymentProcessingFee;
        }
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.errors[0].message
      });
    },
    onShippingMethodChanged: function () {
      this.isLoading = true;

      let pld = {
        query: `query { checkShippingCharge(cartId: "${this.cartId}", shippingMethodId: "${this.selectedShippingMethod}") }`
      }

      axios.post(Settings.GetApiUrl(), pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        this.isLoading = false;

        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
            return;
          }

          this.shippingCharge = rs.checkShippingCharge;
        }
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.errors[0].message
      });
    },
    getShippingMethodListAsAdmin: function () {
      this.isLoading = true;

      let payload = {
        query: `{
            shippingMethodsByAdmin(sort: { by: CreatedAt direction: Desc }, pagination: {perPage: 100 page: 1}){
              id
              displayName
              deliveryCharge
              deliveryTimeInDays
              WeightUnit
              isFlat
              isActive
            }
          }`,
      };

      axios.post(Settings.GetApiUrl(), payload, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        this.isLoading = false;
        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
            return;
          }
          this.shippingMethodList = rs.shippingMethodsByAdmin;
        }
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.errors[0].message;
      });
    },
    getStorePaymentMethodList: function () {
      this.isLoading = true;

      let payload = {
        query: `
                    query paymentMethods{
                        paymentMethods{
                            id
                            displayName
                            currencyName
                            currencySymbol
                            isDigitalPayment
                        }
                    }`,
      };

      axios
          .post(Settings.GetApiUrl(), payload, {
            headers: {
              "store-key": Settings.GetLiveMartKey(),
              "store-secret": Settings.GetLiveMartSecret(),
            },
          })
          .then((resp) => {
            this.isLoading = false;
            if (resp.status === 200) {
              let rs = resp.data.data;
              if (rs === null) {
                this.errors = resp.data.errors[0].message;
                return;
              }
              this.paymentMethodList = rs.paymentMethods;
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.errors = err.errors[0].message
          });
    },
    increaseProductQuantity: function (id, stock) {
      let index = this.productsInCartList.findIndex(p => p.id === id);
      let product = this.productsInCartList[index];
      if (!(product.quantity < stock)) {
        return
      }

      product.quantity++;
      this.grandTotal += product.price;
      this.subtotal += product.price;
      this.productsInCartList[index] = product;
      this.productsInCartList.push({});
      this.productsInCartList.pop();

      this.updateCart();
    },
    decreaseProductQuantity: function (id) {
      let index = this.productsInCartList.findIndex(p => p.id === id);
      if (this.productsInCartList[index].quantity > 1) {
        let product = this.productsInCartList[index];
        product.quantity--;
        this.grandTotal -= product.price;
        this.subtotal -= product.price;
        this.productsInCartList[index] = product;
        this.productsInCartList.push({});
        this.productsInCartList.pop();

        this.updateCart();
      }
    },
    removeProductFromCart: function (id) {
      let index = this.productsInCartList.findIndex(p => p.id === id);
      this.grandTotal -= this.productsInCartList[index].quantity * this.productsInCartList[index].price;
      this.subtotal -= this.productsInCartList[index].quantity * this.productsInCartList[index].price;
      this.productsInCartList.splice(index, 1);

      let i = this.selectedProducts.findIndex(v => v === id);
      this.selectedProducts.splice(i, 1);
      this.removedItems.push(id);

      this.updateCart();
    },
    updateProductList: function () {
      this.selectedProducts.forEach(id => {
        this.productList.forEach(p => {
          if (p.id === id) {
            let isFound = false;
            this.productsInCartList.forEach(pp => {
              if (p.id === pp.id) {
                isFound = true;
              }
            })

            if (!isFound) {
              p.quantity = 1;
              this.productsInCartList.push(p);
              this.grandTotal += p.price;
              this.subtotal += p.price;
            }
          }
        })
      });

      this.updateCart();
    },
    getFullImagePath(subPath) {
      return Settings.GetMediaUrl() + subPath;
    },
    getProductList: function () {
      let payload = {
        query: `
        { productsByAdmin(sort: { by: CreatedAt direction: Desc }, pagination: { perPage: 10 page: 1 }){
            id
            name
            slug
            description
            isPublished
            images
            isDigitalProduct
            stock
            price
            createdAt
            updatedAt
        }}`,
      };

      axios.post(Settings.GetApiUrl(), payload, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
            return;
          }
          this.productList = rs.productsByAdmin;
        }
      }).catch((err) => {
        this.errors = err.errors[0].message
      });
    },
    onProductSearch: function () {
      let payload = {
        query: `{ productsByAdmin(search: { query: "${this.searchQuery}", filters: [] }, sort: { by: CreatedAt direction: Desc }, pagination: { perPage: 10 page: 1 }){
            id
            name
            slug
            description
            isPublished
            images
            stock
            price
            isDigitalProduct
        }}`,
      };

      axios.post(Settings.GetApiUrl(), payload, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        this.isLoading = false;
        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
            return;
          }
          this.productList = rs.productsByAdmin;
        }
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.errors[0].message
      });
    },
    toDisplayUnit(v) {
      return (v / 100).toFixed(2);
    },
    placeOrder: function () {
      this.isReadyToPlaceOrder();

      if (!this.isReadyToOrder) {
        alert('Please fill the required fields');
        return;
      }

      this.isLoading = true;

      let couponCodeQuery = ``;
      if (this.couponCode !== null && this.couponCode !== '') {
        couponCodeQuery = `couponCode: "${this.couponCode}"`
      }

      let payload = {
        query: `mutation { orderCheckoutByAdmin(params: { cartId: "${this.cartId}" billingAddress: { street: "${this.street}" streetTwo: "${this.streetOptional}" city: "${this.city}" state: "${this.state}" postcode: "${this.postcode}" locationId: "${this.locationId}" } shippingAddress: { street: "${this.street}" streetTwo: "${this.streetOptional}" city: "${this.city}" state: "${this.state}" postcode: "${this.postcode}" locationId: "${this.locationId}" } paymentMethodId: "${this.selectedPaymentMethod}" shippingMethodId: "${this.selectedShippingMethod}" ${couponCodeQuery} customerId: "${this.customer.id}" }) { id } }`,
      };

      axios.post(Settings.GetApiUrl(), payload, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
            return;
          }

          let orderId = rs.orderCheckoutByAdmin.id;

          this.$router.push(`/store/order/${orderId}`);
        }
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.errors[0].message
      });
    },
    listLocations: function () {
      let payload = {
        query: `
        query {
          locations {
              id
              name
              shortCode
            }
          }`,
      };

      axios.post(Settings.GetApiUrl(), payload, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
            return;
          }
          this.locationList = rs.locations;
        }
      }).catch((err) => {
        this.errors = err.errors[0].message
      });
    }
  }
}

/***    Validators   ***/
extend("required", {
  ...required,
  message: "This field is required",
});
extend("minlen", {
  validate(value, {length}) {
    return value.length >= length;
  },
  params: ["length"],
  message: "The field must have at least {length} characters",
});
</script>

<style scoped>
@import "~simplemde/dist/simplemde.min.css";
@import "~github-markdown-css";

.content-wrapper {
  padding: 0 30px;
}

.head {
  height: 43px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-left: 15px;
}

.bg-white {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.img-nav:hover {
  cursor: pointer;
}

.form-bg {
  height: auto;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  padding: 24px;
  margin-top: 20px;
}

.p-24 {
  padding: 24px;
}

.form-container {
  width: 100%;
  margin-top: 24px;
}

.label {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
}

.input-field {
  border-radius: 3px;
  border: solid 1px rgba(90, 99, 124, 0.16);
}

.input-field:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aaafbc;
  opacity: 1; /* Firefox */
}

.dropzone {
  margin-top: 20px;
  border-radius: 3px;
  border: dashed 2px rgba(90, 99, 124, 0.16);
}

.dropzone-custom-title {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #b8c5cc;
}

.b-link {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #132eaa;
}

.btn-sv-draft {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5a637c;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-sv-draft:hover {
  border: solid 2px #c2d2ff;
}

.btn-sv-draft:focus {
  outline: none;
  border: solid 2px #c2d2ff;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-publish {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-publish:focus,
.btn-close:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.table-bg {
  position: relative;
  width: 100%;
  border-radius: 0;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  background-color: #ffffff;
  margin-top: 20px;
}

.table-bg {
  margin-top: 24px;
  overflow-y: scroll;
}

.thead-bg {
  background-color: #f2f7fa;
}

.pl-34 {
  padding-left: 34px;
}

.pr-34 {
  padding-right: 34px;
}

.thead-bg th {
  border: 0;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
}

.t-rows {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373b3d;
}

tbody tr td {
  vertical-align: middle;
}

.t-rows td img {
  border: solid 1px #ced9f5;
  padding: 1px;
}

.btn-published {
  cursor: default;
  border-radius: 14px;
  background-color: #caece7;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #287166;
  padding: 6px 17px;
  border: 0;
  outline: none;
}

.btn-published:focus {
  outline: none;
  border: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-reject {
  background-color: #e0e0e0;
  color: #333333;
}

.assign-product {
  border: solid 1px transparent;
  background-color: #ecf1fe;
  padding: 9px 15px 10px;
  border-radius: 30px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #132eaa;
}

.assign-product:hover {
  border: solid 1px #c2d2ff;
}

.assign-product:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.file-added {
  border: solid 1px #dee2ed;
  background-color: #f6f8fc;
  color: #5c6366;
  padding: 10px 20px;
  border-radius: 30px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
}

.file-added:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.file-added img {
  margin: 0 4px;
}

.btn-option {
  border-radius: 14px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b8c5cc;
  padding: 3px 10px;
  border: 0;
  outline: none;
}

.mx-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.modal-bg {
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  padding-top: 24px;
}

.modal-bg .modal-title {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  margin-bottom: 24px;
}

.modal-bg i:hover {
  cursor: pointer;
}

.body-modal {
  max-height: 560px;
  overflow: scroll;
  border-radius: 3px;
  border: solid 1px rgba(90, 99, 124, 0.16);
  background-color: #ffffff;
  border-bottom: 0;
  margin-left: 24px;
  margin-right: 24px;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.helper img {
  display: block;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

input[type="text"] {
  width: 80%;
  height: 34px;
  border: 0;
  padding: 0 10px;
  outline: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: #f6f8fc;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaafbc;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

.container-search {
  width: 100%;
  background-color: #f6f8fc;
  border-bottom: solid 1px #dee2ed;
}

.list-main {
  padding: 9px 14px;
  border-bottom: solid 1px #dee2ed;
}

.list-texts {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #5a637c;
}

.list-texts .title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #363b4a;
}

.modal-footer {
  padding: 18px 24px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border-top: solid 1px #e9eceb;
  background-color: #ffffff;
}

.btn-assign {
  padding-right: 36px;
  padding-left: 36px;
  border: 0;
}

.dropdown-bg {
  min-width: 132px;
  border-bottom: 0;
}

.dropdown-bg .list {
  padding: 10px 12px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
  border-bottom: solid 1px #dbe2e6;
}

.dropdown-bg .list:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }
}
</style>