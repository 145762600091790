<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper">
        <ErrorComponent v-if="errors!==''" v-on:getErrorFlag="(ev) => {this.errors = ev}"
                        :err-msg="errors"></ErrorComponent>
        <div class="row">
          <div class="col-lg-8 col-md-12 col-sm-12">
            <!--    Head start     -->
            <div class="row">
              <div class="col-lg-12 d-lg-flex">
                <h5 class="title-header">Dashboard</h5>

                <div id="tabReport" class="ml-auto mt-3 mt-md-3 mt-lg-0 d-flex flex-column flex-sm-row">
                                    <span @click="fetchReport('t')"
                                          class="badge badge-secondary tabs active">Today</span>
                  <span @click="fetchReport('w')" class="badge badge-secondary tabs">Weekly</span>
                  <span @click="fetchReport('m')" class="badge badge-secondary tabs">Monthly</span>
                  <span @click="fetchReport('y')" class="badge badge-secondary tabs">Yearly</span>
                  <span @click="fetchReport('')" class="badge badge-secondary tabs">All</span>
                </div>
              </div>
            </div>
            <!--    Head end     -->
            <!--    Boxes start     -->
            <div class="row mt-32">
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="bg-white summary">
                  <div class="container">
                    <div class="row d-flex align-items-baseline">
                      <div class="mr-2">
                        <div class="text-center">
                          <img :src="'./assets/icons/dashboard/order.svg'" alt="">
                        </div>
                      </div>
                      <div class="">
                        <p class="title-summary">Order</p>
                        <p class="price-summary">{{ totalOrders }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="bg-white summary">
                  <div class="container">
                    <div class="row d-flex align-items-baseline">
                      <div class="mr-2">
                        <div class="text-center">
                          <img :src="'./assets/icons/dashboard/earnings.svg'" alt="">
                        </div>
                      </div>
                      <div>
                        <p class="title-summary">Earnings</p>
                        <p class="price-summary">{{ toDisplayUnit(totalEarnings) }} {{ currency }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="bg-white summary">
                  <div class="container">
                    <div class="row d-flex align-items-baseline">
                      <div class="mr-2">
                        <div class="text-center">
                          <img :src="'./assets/icons/dashboard/expenses.svg'" alt="">
                        </div>
                      </div>
                      <div>
                        <p class="title-summary">Expenses</p>
                        <p class="price-summary">{{ toDisplayUnit(totalExpenses) }} {{ currency }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="bg-white summary">
                  <div class="container">
                    <div class="row d-flex align-items-baseline">
                      <div class="mr-2">
                        <div class="text-center">
                          <img :src="'./assets/icons/dashboard/discounts.svg'" alt="">
                        </div>
                      </div>
                      <div>
                        <p class="title-summary">Discounts</p>
                        <p class="price-summary">{{ toDisplayUnit(totalDiscounts) }} {{ currency }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="bg-white summary">
                  <div class="container">
                    <div class="row d-flex align-items-baseline">
                      <div class="mr-2">
                        <div class="text-center">
                          <img :src="'./assets/icons/dashboard/customers.svg'" alt="">
                        </div>
                      </div>
                      <div>
                        <p class="title-summary">Customers</p>
                        <p class="price-summary">{{ totalCustomers }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="bg-white summary">
                  <div class="container">
                    <div class="row d-flex align-items-baseline">
                      <div class="mr-2">
                        <div class="text-center">
                          <img :src="'./assets/icons/dashboard/profits.svg'" alt="">
                        </div>
                      </div>
                      <div>
                        <p class="title-summary">Profits</p>
                        <p class="price-summary">{{ toDisplayUnit(totalProfits) }} {{ currency }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="bg-white summary">
                  <div class="container">
                    <div class="row d-flex align-items-baseline">
                      <div class="mr-2">
                        <div class="text-center">
                          <img :src="'./assets/icons/dashboard/profits.svg'" alt="">
                        </div>
                      </div>
                      <div>
                        <p class="title-summary">Due</p>
                        <p class="price-summary">{{ toDisplayUnit(totalDue) }} {{ currency }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--    Boxes end     -->

            <!--            <div class="row mb-5">&lt;!&ndash;v-if="this.summarySeries.data"&ndash;&gt;-->
            <!--              <div class="col-12">-->
            <!--                <div class="card">-->
            <!--                  <h5 class="card-header">Growth Summary</h5>-->
            <!--                  <div class="card-body border-top">-->
            <!--                    <div class="row">-->
            <!--                      <apexchart class="w-100" height="250" type="line" :options="summaryOptions"-->
            <!--                                 :series="summarySeries"></apexchart>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            <div class="row mb-5"> &lt;!&ndash;v-if="this.ordersSeries.data"&ndash;&gt;-->
            <!--              <div class="col-12">-->
            <!--                <div class="card">-->
            <!--                  <h5 class="card-header">Orders Growth</h5>-->
            <!--                  <div class="card-body border-top">-->
            <!--                    <div class="row">-->
            <!--                      <apexchart class="w-100" height="250" type="line" :options="ordersOptions"-->
            <!--                                 :series="ordersSeries"></apexchart>-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

            <!--            <div class="row mb-5">&lt;!&ndash;v-if="this.earningsSeries.data"&ndash;&gt;-->
            <!--              <div class="col-12">-->
            <!--                <div class="card">-->
            <!--                  <h5 class="card-header">Earnings Growth</h5>-->
            <!--                  <div class="card-body border-top">-->
            <!--                    <apexchart class="w-100" height="250" type="line" :options="earningsOptions"-->
            <!--                               :series="earningsSeries"></apexchart>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
          <!--    Activity start     -->
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="bg-white activity-container">
              <p class="activity-title">Activity</p>
              <ul class="list-unstyled">
                 <span v-for="o in orderList" :key="o.id">
                   <div class="log-title d-flex">
                      <p class="pr-5">
                        <span class="product-name">
                          Order placed by {{ o.customer.firstName }} {{ o.customer.lastName }}
                        </span>
                        <span> of {{ toDisplayUnit(o.grandTotal) }} {{ currency }}</span>
                        <span> with hash
                          <router-link :to='`/store/order/${o.id}`'>{{ o.hash }}</router-link>
                        </span>
                      </p>
                      <p class="text-nowrap ml-auto">{{ parseDateTime(o.createdAt) }}</p>
                   </div>
                 </span>
              </ul>
            </div>
          </div>
          <!--    Activity end     -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import $ from 'jquery';

import Header from "../components/Header";
import Settings from "../common/settings";
import PleaseWait from "./PleaseWait";
import ErrorComponent from "./ErrorComponent";
import SideBarLiveMart from "./SideBarLiveMart";

export default {
  name: "Dashboard",
  components: {SideBarLiveMart, ErrorComponent, PleaseWait, Header},
  data() {
    return {
      // Flags
      isLoading: false,
      errors: '',
      addClass: false,
      removeClass: !this.addClass,
      isGetAllStat: false,

      // Variables
      totalOrders: 0,
      totalEarnings: 0,
      totalExpenses: 0,
      totalDiscounts: 0,
      totalCustomers: 0,
      totalProfits: 0,
      totalDue: 0,

      // Data
      orderList: [],

      // Stats
      summaryOptions: {
        chart: {
          id: 'growth-summary'
        },
        xaxis: {
          categories: [],
          type: 'category'
        }
      },
      summarySeries: [{
        name: 'Growth Summary',
        data: []
      }],
      ordersOptions: {
        chart: {
          id: 'orders-growth'
        },
        xaxis: {
          categories: [],
          type: 'category'
        }
      },
      ordersSeries: [{
        name: 'Orders Growth',
        data: []
      }],
      earningsOptions: {
        chart: {
          id: 'earnings-growth'
        },
        xaxis: {
          categories: [],
          type: 'category'
        }
      },
      earningsSeries: [{
        name: 'Earnings Growth',
        data: []
      }],

      currency: ''
    }
  },
  mounted() {
    this.currency = Settings.GetStoreCurrency();
    this.fetchReport('t');

    $('#tabReport span').on('click', function () {
      $('#tabReport').find('span.active').removeClass('active');
      $(this).addClass('active');
    });
  },
  methods: {
    fetchReport: function (q) {
      let params = `params: {}`;

      if (q === 't') {
        let e = moment().utc().add(1, 'days').format('DD-MM-YYYY');
        let s = moment().utc().add(0, 'days').format('DD-MM-YYYY');
        params = `params: { startDate: "` + s + `" endDate: "` + e + `" }`;
      } else if (q === 'w') {
        let e = moment().utc().add(1, 'days').format('DD-MM-YYYY');
        let s = moment().utc().add(-6, 'days').format('DD-MM-YYYY');
        params = `params: { startDate: "` + s + `" endDate: "` + e + `" }`;
      } else if (q === 'm') {
        let e = moment().utc().add(1, 'days').format('DD-MM-YYYY');
        let s = moment().utc().add(-29, 'days').format('DD-MM-YYYY');
        params = `params: { startDate: "` + s + `" endDate: "` + e + `" }`;
      } else if (q === 'y') {
        let e = moment().utc().add(1, 'days').format('DD-MM-YYYY');
        let s = moment().utc().add(-364, 'days').format('DD-MM-YYYY');
        params = `params: { startDate: "` + s + `" endDate: "` + e + `" }`;
      }

      let payload = {
        query: `query { report(` + params + `) { totalSale totalSalePending totalCost totalOrders totalOrdersPending totalCustomers totalDue } }`,
      };

      axios
          .post(Settings.GetApiUrl(), payload, {
            headers: {
              "store-key": Settings.GetLiveMartKey(),
              "store-secret": Settings.GetLiveMartSecret(),
            },
          })
          .then((resp) => {
            if (resp.status === 200) {
              let rs = resp.data.data;
              if (rs === null) {
                this.errors = resp.data.errors[0].message;
                return;
              }
              let data = rs.report;
              this.totalEarnings = data.totalSale;
              this.totalExpenses = data.totalCost;
              this.totalOrders = data.totalOrders;
              this.totalProfits = data.totalSale - data.totalCost;
              this.totalCustomers = data.totalCustomers;
              this.totalDue = data.totalDue;
            }
          })
          .catch((err) => {
            this.errors = err.errors[0].message
          });

      this.getOrdersByAdmin();
    },
    getOrdersByAdmin: function () {
      let payload = {
        query: `query { ordersByAdmin(sort: { by: CreatedAt direction: Desc }, pagination: { perPage: 9 page: 1 }) { id hash grandTotal createdAt customer { firstName lastName } } }`,
      };

      axios.post(Settings.GetApiUrl(), payload, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        this.isLoading = false;
        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
            return;
          }
          this.orderList = rs.ordersByAdmin;
        }
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.errors[0].message;
      });
    },
    parseDateTime: function (date) {
      return moment(date).fromNow()
    },
    toDisplayUnit(v) {
      return (v / 100).toFixed(2)
    },
    idToUrl: function (id) {
      return "/#/store/order/" + id;
    }
  }
}
</script>

<style scoped>
.mt-32 {
  margin-top: 32px;
}

.content-wrapper {
  padding: 0 30px;
}

.bg-white,
.summary {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-bottom: 24px;
}

.summary {
  padding: 24px 18px;
}

.summary img {
  object-fit: contain;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(107, 118, 148, 0.5);
}

.title-summary {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5a637c;
  margin-bottom: 10px;
}

.price-summary {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #132eaa;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
}

.tabs {
  border-radius: 3px;
  background-color: #ecf1fe;
  border: solid 1px transparent;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #132eaa;
  margin-left: 12px;
  margin-bottom: 3px;
}

.tabs:first-child {
  margin-left: 0;
}

.tabs:hover, .tabs.active {
  border: solid 1px #132eaa;
  background-color: #ecf1fe;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .tabs {
    margin-left: 0;
  }
}

.activity-container {
  height: auto;
  padding: 24px 24px 28px 24px;
}

.activity-title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5a637c;
  margin-bottom: 24px;
}

.activity-container > p {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5a637c;
}

.activity-container ul {
  max-height: 765px;
  overflow: scroll;
}

.activity-container ul li {
  border-top: 1px solid #e9eceb;
  padding: 14px 0;
  height: auto;
}

.log-title {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
  margin-bottom: 12px;
}

.product-name {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #132eaa;
}

.price {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }
}
</style>
