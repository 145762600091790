<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <!--    Left side start     -->
          <div class="col-lg-8 mb-5">
            <!--    Head start     -->
            <div class="row head">
              <div class="col-md-12 d-flex align-items-center">
                <i
                    @click="$router.go(-1)"
                    class="fas fa-chevron-left img-nav"
                ></i>
                <h5 class="title-header">Add Coupon</h5>
              </div>
            </div>
            <!--    Head end     -->

            <ValidationObserver ref="form">
              <div class="form-bg">
                <h5 class="title-header pl-0">General Information</h5>
                <!--    Product Name    -->
                <div class="form-container">
                  <div class="form-group">
                    <label class="label" for="pname">Discount code</label>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <input
                          type="text"
                          v-model.trim="coupon"
                          class="form-control input-field"
                          id="pname"
                          placeholder="Coupon"
                      />
                      <span class="label text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <!--    Radio starts    -->
              <div class="form-bg">
                <h5 class="title-header pl-0">Discount Type</h5>

                <div class="form-container">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-check-inline mr-5">
                      <label class="label">
                        <input
                            type="radio"
                            v-model="discountType"
                            :value="'product_discount'"
                            class="form-check-input"
                            name="optradio"
                        />Product Discount
                      </label>
                    </div>
                    <div class="form-check-inline mr-5">
                      <label class="label">
                        <input
                            type="radio"
                            v-model="discountType"
                            :value="'total_discount'"
                            class="form-check-input"
                            name="optradio"
                        />Total Discount
                      </label>
                    </div>
                    <div class="form-check-inline disabled">
                      <label class="label">
                        <input
                            type="radio"
                            v-model="discountType"
                            :value="'shipping_discount'"
                            class="form-check-input"
                            name="optradio"
                        />Shipping Discount
                      </label>
                    </div>
                    <p class="label text-danger">{{ errors[0] }}</p>
                  </ValidationProvider>
                </div>
              </div>
              <!--    Radio ends    -->

              <div class="form-bg">
                <h5 class="title-header pl-0">Discount Amount</h5>
                <!--    Product Name    -->
                <div class="form-container">
                  <div class="form-group">
                    <div class="custom-control custom-switch float-right mb-1">
                      <input
                          v-model="isFlatDiscount"
                          type="checkbox"
                          class="custom-control-input"
                          id="is_fixed"
                      />
                      <label class="custom-control-label" for="is_fixed">{{
                          isFlatDiscount
                              ? "Flat Discount"
                              : "Discount in Percentage(%)"
                        }}</label>
                    </div>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <input
                          type="number"
                          oninput="validity.valid||(value='');"
                          :min="0"
                          step="0.01"
                          v-model.number="discountAmount"
                          class="form-control input-field"
                          placeholder="0"
                      />
                      <span class="label text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="form-bg">
                <h5 class="title-header pl-0">Discount Value</h5>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-container">
                      <div class="form-group">
                        <label class="label" for="maxdis">Minimum Order Value</label>

                        <ValidationProvider
                            rules="required"
                            v-slot="{ errors }">
                          <input
                              type="number"
                              oninput="validity.valid||(value='');"
                              :min="0"
                              step="0.01"
                              v-model.number="minOrderValue"
                              class="form-control input-field"
                              id="maxdis"
                          />
                          <span class="label text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-container">
                      <div class="form-group">
                        <label class="label" for="mindis">Maximum Discount</label>

                        <ValidationProvider
                            rules="required"
                            v-slot="{ errors }">
                          <input
                              type="number"
                              oninput="validity.valid||(value='');"
                              :min="0"
                              step="0.01"
                              v-model.number="maxDiscount"
                              class="form-control input-field"
                              id="mindis"
                          />
                          <span class="label text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-bg">
                <h5 class="title-header pl-0">Limit of Usage</h5>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-container">
                      <div class="form-group">
                        <label class="label" for="limitUse">Maximum Usage</label>
                        <div class="custom-control custom-switch float-right">
                          <input
                              v-model="unlimitedUsage"
                              @change="setUsageLimit"
                              type="checkbox"
                              class="custom-control-input"
                              id="switchmaxuse"
                          />
                          <label class="custom-control-label" for="switchmaxuse">Unlimited</label>
                        </div>

                        <ValidationProvider
                            v-if="!unlimitedUsage"
                            rules="required"
                            v-slot="{ errors }">
                          <input
                              type="number"
                              :disabled="unlimitedUsage"
                              oninput="validity.valid||(value='');"
                              :min="1"
                              v-model.number="maxUsage"
                              class="form-control input-field"
                              id="limitUse"
                          />
                          <span class="label text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-container">
                      <div class="form-group">
                        <label class="label" for="limitcustomer">Per customer</label>
                        <div class="custom-control custom-switch float-right">
                          <input
                              v-model="unlimitedForCustomer"
                              @change="setCustomerLimit"
                              type="checkbox"
                              class="custom-control-input"
                              id="switchminuse"
                          />
                          <label class="custom-control-label" for="switchminuse">Unlimited</label>
                        </div>

                        <ValidationProvider
                            v-if="!unlimitedForCustomer"
                            rules="required"
                            v-slot="{ errors }">
                          <input
                              type="number"
                              :disabled="unlimitedForCustomer"
                              oninput="validity.valid||(value='');"
                              :min="1"
                              v-model.number="usagePerCustomer"
                              class="form-control input-field"
                              id="limitcustomer"
                          />
                          <span class="label text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-bg">
                <h5 class="title-header pl-0">Active Dates</h5>

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-container">
                      <div class="form-group">
                        <label class="label">Start At</label>

                        <ValidationProvider
                            rules="required"
                            v-slot="{ errors }">
                          <DatePicker
                              class="d-block"
                              type="datetime"
                              @on-change="handleStart"
                              v-model.trim="startAt"
                              format="MM-dd-yyyy HH:mm"
                          ></DatePicker>
                          <span class="label text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-container">
                      <div class="form-group">
                        <label class="label">End At</label>

                        <ValidationProvider
                            rules="required"
                            v-slot="{ errors }">
                          <DatePicker
                              class="d-block"
                              type="datetime"
                              @on-change="handleEnd"
                              v-model.trim="endAt"
                              format="MM-dd-yyyy HH:mm"
                          ></DatePicker>
                          <span class="label text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="box-bg">
                <h5 class="title-header pl-0">Is Customer Specific?</h5>
                <div class="form-container mt-2">
                  <div class="form-group mb-0">
                    <div class="custom-control custom-switch float-right">
                      <input
                          v-model="isUserSpecific"
                          v-on:change="onChangeSwitch"
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitch1"
                      />
                      <label class="custom-control-label" for="customSwitch1">{{
                          isUserSpecific ? "Yes" : "No"
                        }}</label>
                    </div>

                    <ValidationProvider
                        :rules="`requiredUserMailField:${isUserSpecific},${selectedUsers}`"
                        v-slot="{ errors }">
                      <Select
                          v-model="selectedUsers"
                          :disabled="!isUserSpecific"
                          @on-query-change="getCustomerList"
                          filterable
                          multiple>
                        <Option
                            v-for="user in customerList"
                            :value="user.id"
                            :key="user.id"
                        >{{ user.email }}
                        </Option
                        >
                      </Select>

                      <span class="label text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>
          <!--    Left side end     -->

          <!--    Right side start     -->
          <div class="col-lg-4">
            <div class="row text-nowrap">
              <div class="col-md-6 col-lg-6 text-center">
                <button
                    @click="createNewCoupon(false)"
                    class="btn text-capitalize btn-sv-draft w-100">
                  Save Draft
                </button>
              </div>
              <div class="col-md-6 col-lg-6 text-center">
                <button
                    @click="createNewCoupon(true)"
                    class="btn text-capitalize btn-publish w-100 mt-2 mt-md-0">
                  Activate
                </button>
              </div>
            </div>
            <div class="bg-white">
              <div class="summary-bg">Summary</div>

              <div class="summaryBody">
                <ul class="list-unstyled">
                  <li v-if="coupon">
                    <p>Discount code</p>
                    <p class="values">{{ coupon }}</p>
                  </li>
                  <li v-if="discountAmount">
                    <p>Value</p>
                    <p class="values">
                      {{
                        isFlatDiscount
                            ? `${discountAmount} ${currency}`
                            : `${discountAmount}%`
                      }}
                    </p>
                  </li>
                  <li v-if="minOrderValue">
                    <p>Min. Order Value</p>
                    <p class="values">{{ minOrderValue }} {{ currency }}</p>
                  </li>
                  <li
                      v-if="startAt && endAt"
                      class="d-flex justify-content-between"
                  >
                    <span>
                      <p>Start Date</p>
                      <p class="values">{{ parseDateTime(startAt) }}</p>
                    </span>
                    <span>
                      <p>End Date</p>
                      <p class="values">{{ parseDateTime(endAt) }}</p>
                    </span>
                  </li>
                  <li v-if="maxUsage">
                    <p>Usage Limit</p>
                    <p class="values">
                      {{ maxUsage === -1 ? "Unlimited" : maxUsage }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--    Right side end     -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import {extend} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import moment from "moment";
import "moment/locale/en-gb";

import PleaseWait from "../PleaseWait";
import ErrorComponent from "../ErrorComponent";
import Header from "../Header";
import SessionStore from "../../common/session_store";
import Settings from "../../common/settings";
import SideBarLiveMart from "../SideBarLiveMart";

export default {
  name: "CouponAddView",
  components: {SideBarLiveMart, Header, ErrorComponent, PleaseWait},
  data() {
    return {
      // Flags
      isLoading: false,
      errors: "",

      // Field Variables
      coupon: "",
      discountType: "",
      discountAmount: "",
      minOrderValue: "",
      maxDiscount: "",
      maxUsage: 1,
      unlimitedUsage: false,
      usagePerCustomer: 1,
      unlimitedForCustomer: false,
      startAt: "",
      endAt: "",
      isUserSpecific: false,
      isFlatDiscount: true,
      customerList: [],
      selectedUsers: [],
      currency: ''
    };
  },
  mounted() {
    this.currency = Settings.GetStoreCurrency();
  },
  methods: {
    createNewCoupon: function (isActive) {
      let discountType = "Overall"
      if (this.discountType === "shipping_discount") {
        discountType = "Shipping"
      } else if (this.discountType === "product_discount") {
        discountType = "Product"
      }

      let payload = {
        query: `mutation { newCouponCode( params: { code: "${this.coupon}" amount: ${this.isFlatDiscount ? parseInt(this.toInputUnit(this.discountAmount)) : this.discountAmount} minOrderAmount: ${parseInt(this.toInputUnit(this.minOrderValue))} maxDiscountAmount: ${this.maxDiscount === 0 ? null : parseInt(this.toInputUnit(this.maxDiscount))} isActive: ${isActive} isFlatDiscount: ${this.isFlatDiscount} isForNewUsersOnly: false isUserSpecific: ${this.isUserSpecific} maxUsage: ${this.maxUsage} maxUsagePerUser: ${this.usagePerCustomer} discountType: ${discountType} description: "" image: "" startAt: "${this.parseToInputFormat(this.parseDateTime(this.startAt))}" endAt: "${this.parseToInputFormat(this.parseDateTime(this.endAt))}" customers: [${this.selectedUsers}] } ) { id }}`,
      };

      axios
          .post(Settings.GetApiUrl(), payload, {
            headers: {
              "store-key": Settings.GetLiveMartKey(),
              "store-secret": Settings.GetLiveMartSecret(),
            },
          })
          .then((resp) => {
            if (resp.status === 200) {
              let rs = resp.data.data;
              if (rs === null) {
                this.errors = resp.data.errors[0].message;
                return;
              }
              this.$router.push("/store/coupons");
            }
          })
          .catch((err) => {
            this.errors = err.errors[0].message;
          });
    },
    onChangeSwitch: function () {
      if (!this.isUserSpecific) {
        this.selectedUsers = [];
      }
    },
    getCustomerList: function (searchQuery) {
      axios
          .get(`${Settings.GetApiUrl()}/customers?query=${searchQuery}`, {
            headers: {
              Authorization: "Bearer " + SessionStore.GetAccessToken(),
            },
          })
          .then((resp) => {
            this.customerList = resp.data.data;
          })
          .catch((err) => {
            this.errors = err.response.data.title;
          });
    },
    handleStart: function (date) {
      this.startAt = date;
    },
    handleEnd: function (date) {
      this.endAt = date;
    },
    parseToInputFormat: function (date) {
      return moment(date).format("DD-MM-YYYYThh:mm");
    },
    parseDateTime: function (date) {
      return moment(date).format("MM-DD-YYYY hh:mm");
    },
    toDisplayUnit(v) {
      return (v / 100).toFixed(2);
    },
    toInputUnit(v) {
      return (v * 100).toFixed(2);
    },
    setUsageLimit: function () {
      if (this.unlimitedUsage) {
        this.maxUsage = null;
      } else if (!this.unlimitedUsage) {
        this.maxUsage = 1;
      }
    },
    setCustomerLimit: function () {
      if (this.unlimitedForCustomer) {
        this.usagePerCustomer = null;
      } else if (!this.unlimitedForCustomer) {
        this.usagePerCustomer = 1;
      }
    },
  },
};

/***    Validators   ***/
extend("required", {
  ...required,
  message: "This field is required",
});

extend("requiredUserMailField", {
  validate(value, {isSpecific, userList}) {
    return !(isSpecific && userList.length === 0);
  },
  params: ["isSpecific", "userList"],
  message: "Insert at least one user into list",
});

extend("min", {
  validate(value, {length}) {
    return value.length >= length;
  },
  params: ["length"],
  message: "The field must have at least {length} characters",
});
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.content-wrapper {
  padding: 0 30px;
}

.head {
  height: 43px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-left: 15px;
}

.bg-white {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.img-nav:hover {
  cursor: pointer;
}

.form-bg {
  height: auto;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  padding: 24px;
  margin-top: 20px;
}

.form-container {
  width: 100%;
  margin-top: 24px;
}

.label {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
}

.input-field {
  border-radius: 3px;
  border: solid 1px rgba(90, 99, 124, 0.16);
}

.input-field:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aaafbc;
  opacity: 1; /* Firefox */
}

.box-bg {
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 24px;
  padding: 24px;
}

.btn-sv-draft {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5a637c;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-sv-draft:hover {
  border: solid 2px #c2d2ff;
}

.btn-sv-draft:focus {
  outline: none;
  border: solid 2px #c2d2ff;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-publish {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-publish:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.summary-bg {
  padding: 24px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #363b4a;
  box-shadow: -2px -3px 8px 0 rgba(255, 255, 255, 0.5);
  background-color: #ecf1fe;
}

.summaryBody {
  padding: 24px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5a637c;
}

.summaryBody ul li {
  margin-bottom: 24px;
}

.values {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  margin-top: 8px;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }
}
</style>
