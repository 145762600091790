<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"
    ></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <!--    Left side start     -->
          <div class="col-lg-8 mb-5">
            <!--    Head start     -->
            <div class="row head">
              <div class="col-md-12 d-flex align-items-center">
                <i
                    @click="$router.go(-1)"
                    class="fas fa-chevron-left img-nav"
                ></i>
                <h5 class="title-header">Add Collection</h5>
              </div>
            </div>
            <!--    Head end     -->

            <div class="form-bg">
              <h5 class="title-header pl-0">General Information</h5>
              <!--    Product Name    -->
              <ValidationObserver ref="form">
                <div class="form-container">
                  <div class="form-group">
                    <label class="label" for="pname">Name</label>

                    <ValidationProvider
                        rules="required|minlen:4"
                        :bails="false"
                        v-slot="{ errors }">
                      <input
                          v-model.trim="name"
                          type="text"
                          class="form-control input-field w-100"
                          id="pname"
                          placeholder="Collection name"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <!--    Product description    -->
                <div class="form-container mt-3">
                  <div class="form-group">
                    <label class="label">Collection description</label>

                    <ValidationProvider
                        rules="required|minlen:4"
                        :bails="false"
                        v-slot="{ errors }">
                      <vue-simplemde
                          v-model.trim="description"
                          preview-class="markdown-body"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="form-container">
                  <div class="form-group">
                    <label class="label" for="pname">Position</label>
                    <ValidationProvider
                        :bails="false"
                        v-slot="{ errors }">
                      <input
                          v-model="position"
                          type="number"
                          class="form-control input-field"
                          id="pname"
                          placeholder="Category Position"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>
              </ValidationObserver>
            </div>

            <!--    Cover image upload  -->
            <div class="form-bg">
              <div class="d-block d-md-flex">
                <h5 class="title-header pl-0">Cover image (Optional)</h5>

                <div v-if="coverImage" class="ml-auto text-nowrap mt-2 mt-md-0">
                  <button
                      class="btn text-capitalize file-added"
                      @click="$refs.file.click()">
                    <img :src="'./assets/icons/page/cloud-upload.svg'" alt=""/>
                    Upload Again
                  </button>
                </div>
              </div>
              <input
                  @change="onChangePreview"
                  type="file"
                  ref="file"
                  style="display: none"
              />
              <div class="overlay text-center dropzone">
                <PreviewImageComponent
                    v-if="coverImage"
                    :image-src="coverImage"
                />
                <div
                    @click="$refs.file.click()"
                    v-if="!coverImage"
                    class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">
                    Click here to
                    <span class="b-link">Browse on your computer</span>
                  </h3>
                  <div class="dropzone-custom-title">
                    Recommended image size is 1240x930px.
                  </div>
                </div>
              </div>
            </div>
            <!--    Cover image upload end  -->
          </div>
          <!--    Left side end     -->

          <!--    Right side start     -->
          <div class="col-lg-4">
            <div class="row">
              <div class="col text-center">
                <button
                    @click="onCreateNewCollection(false)"
                    class="btn text-capitalize btn-sv-draft w-100"
                >
                  Save Draft
                </button>
              </div>

              <div class="col text-center">
                <button
                    @click="onCreateNewCollection(true)"
                    class="btn text-capitalize btn-publish w-100"
                >
                  Publish
                </button>
              </div>
            </div>

            <div class="bg-white">
              <CardComponent
                  :title="name"
                  :cover-image="coverImage"
                  :is-collections="true"
              />
            </div>
          </div>
          <!--    Right side end     -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import VueSimplemde from "vue-simplemde";
import {extend} from "vee-validate";
import {required} from "vee-validate/dist/rules";

import Header from "../../../components/Header";
import CardComponent from "../../../components/catalog/products/CardComponent";
import PleaseWait from "../../PleaseWait";
import ErrorComponent from "../../ErrorComponent";
import Settings from "../../../common/settings";
import MediaService from "../../../common/media_service";
import PreviewImageComponent from "../products/PreviewImageComponent";
import SideBarLiveMart from "../../SideBarLiveMart";

export default {
  name: "CollectionsAddView",
  components: {
    SideBarLiveMart,
    PreviewImageComponent,
    ErrorComponent,
    PleaseWait,
    CardComponent,
    Header,
    VueSimplemde,
  },
  data() {
    return {
      // Flags
      isLoading: false,
      errors: "",

      // Fields
      collectionID: "",
      name: "",
      description: "",
      coverImage: "",
      searchQuery: "",
      selectedProducts: [],
      position: 0,

      // Data
      productsInCollectionList: [],
      productList: [],
    };
  },
  mounted() {

  },
  methods: {
    onCreateNewCollection: function (isPublished) {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        this.isLoading = true;

        if (!this.position) {
          this.position = 0
        }

        let pld = {
          query: `mutation { newCollection(params: { name: "` + this.name + `" isPublished: ` + isPublished + ` description: "` + encodeURIComponent(this.description) + `" image: "` + this.coverImage + `" position: ${this.position} }) { id } }`
        };

        axios.post(
            `${Settings.GetApiUrl()}`, pld,
            {
              headers: {
                "store-key": Settings.GetLiveMartKey(),
                "store-secret": Settings.GetLiveMartSecret()
              },
            }
        ).then((resp) => {
          this.isLoading = false;
          if (resp.data.data !== null) {
            this.$router.push(`/store/collections`);
            return
          }
          alert(resp.data.errors[0].message);
        }).catch((err) => {
          this.isLoading = false;
          if (err.response.status === 422) {
            this.errors = err.response.data.errors.message;
          } else {
            this.errors = err.response.data.title;
          }
        });
      });
    },
    onChangePreview: function (ev) {
      let files = ev.target.files || ev.dataTransfer.files;

      if (files.length > 0) {
        this.coverImage = "";
        this.isLoading = true;

        MediaService.uploadContent(files[0], "categories").then(resp => {
          this.isLoading = false;

          if (resp.status === 200) {
            let rs = resp.data.data;
            if (rs === null) {
              this.errors = resp.data.errors[0].message;
              return
            }

            this.coverImage = rs.newMedia;
          }
        }).catch(err => {
          console.log(err);
        });
      }
    },
    getFullImagePath(subPath) {
      return Settings.GetMediaUrl() + subPath;
    }
  },
};

/***    Validators   ***/
extend("required", {
  ...required,
  message: "This field is required",
});
extend("minlen", {
  validate(value, {length}) {
    return value.length >= length;
  },
  params: ["length"],
  message: "The field must have at least {length} characters",
});
</script>

<style scoped>
@import "~simplemde/dist/simplemde.min.css";
@import "~github-markdown-css";

.content-wrapper {
  padding: 0 30px;
}

.head {
  height: 43px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-left: 15px;
}

.bg-white {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.img-nav:hover {
  cursor: pointer;
}

.form-bg {
  height: auto;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  padding: 24px;
  margin-top: 20px;
}

.p-24 {
  padding: 24px;
}

.form-container {
  width: 100%;
  margin-top: 24px;
}

.label {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
}

.input-field {
  border-radius: 3px;
  border: solid 1px rgba(90, 99, 124, 0.16);
}

.input-field:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aaafbc;
  opacity: 1; /* Firefox */
}

.dropzone {
  margin-top: 20px;
  border-radius: 3px;
  border: dashed 2px rgba(90, 99, 124, 0.16);
}

.dropzone-custom-content:hover {
  cursor: pointer;
}

.dropzone-custom-title {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #b8c5cc;
}

.b-link {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #132eaa;
}

.btn-sv-draft {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5a637c;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-sv-draft:hover {
  border: solid 2px #c2d2ff;
}

.btn-sv-draft:focus {
  outline: none;
  border: solid 2px #c2d2ff;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-publish {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-publish:focus,
.btn-close:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.table-bg {
  position: relative;
  width: 100%;
  border-radius: 0;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  background-color: #ffffff;
  margin-top: 20px;
}

.table-bg {
  margin-top: 24px;
}

.thead-bg {
  background-color: #f2f7fa;
}

.pl-34 {
  padding-left: 34px;
}

.pr-34 {
  padding-right: 34px;
}

.thead-bg th {
  border: 0;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
}

.t-rows {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373b3d;
}

tbody tr td {
  vertical-align: middle;
}

.t-rows td img {
  border: solid 1px #ced9f5;
  padding: 1px;
}

.btn-published {
  cursor: default;
  border-radius: 14px;
  background-color: #caece7;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #287166;
  padding: 6px 17px;
  border: 0;
  outline: none;
}

.btn-published:focus {
  outline: none;
  border: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-reject {
  background-color: #e0e0e0;
  color: #333333;
}

.assign-product {
  border: solid 1px transparent;
  background-color: #ecf1fe;
  padding: 9px 15px 10px;
  border-radius: 30px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #132eaa;
}

.assign-product:hover {
  border: solid 1px #c2d2ff;
}

.assign-product:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.file-added {
  border: solid 1px #dee2ed;
  background-color: #f6f8fc;
  color: #5c6366;
  padding: 10px 20px;
  border-radius: 30px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
}

.file-added:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.file-added img {
  margin: 0 4px;
}

.btn-option {
  border-radius: 14px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b8c5cc;
  padding: 3px 10px;
  border: 0;
  outline: none;
}

.mx-24 {
  margin-left: 24px;
  margin-right: 24px;
}

.modal-bg {
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  padding-top: 24px;
}

.modal-bg .modal-title {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  margin-bottom: 24px;
}

.modal-bg i:hover {
  cursor: pointer;
}

.body-modal {
  max-height: 560px;
  overflow: scroll;
  border-radius: 3px;
  border: solid 1px rgba(90, 99, 124, 0.16);
  background-color: #ffffff;
  border-bottom: 0;
  margin-left: 24px;
  margin-right: 24px;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.helper img {
  display: block;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

input[type="text"] {
  width: 80%;
  height: 34px;
  border: 0;
  padding: 0 10px;
  outline: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  background-color: #f6f8fc;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaafbc;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

.container-search {
  width: 100%;
  background-color: #f6f8fc;
  border-bottom: solid 1px #dee2ed;
}

.list-main {
  padding: 9px 14px;
  border-bottom: solid 1px #dee2ed;
}

.list-texts {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #5a637c;
}

.list-texts .title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #363b4a;
}

.modal-footer {
  padding: 18px 24px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border-top: solid 1px #e9eceb;
  background-color: #ffffff;
}

.btn-assign {
  padding-right: 36px;
  padding-left: 36px;
  border: 0;
}

.dropdown-bg {
  min-width: 132px;
  border-bottom: 0;
}

.dropdown-bg .list {
  padding: 10px 12px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
  border-bottom: solid 1px #dbe2e6;
}

.dropdown-bg .list:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }
}
</style>
