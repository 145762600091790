var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PleaseWait',{attrs:{"isLoading":_vm.isLoading}}),(_vm.errors !== '')?_c('ErrorComponent',{attrs:{"err-msg":_vm.errors},on:{"getErrorFlag":function (ev) {
        this$1.errors = ev;
      }}}):_vm._e(),_c('SideBarLiveMart'),_c('main',[_c('Header'),_c('div',{staticClass:"content-wrapper mt-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-nowrap"},[_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-content-between"},[_c('h5',{staticClass:"title-header"},[_vm._v("Orders")]),_c('button',{staticClass:"btn text-capitalize btn-add-product mt-2 mt-sm-0",on:{"click":_vm.newOrder}},[_vm._m(0),_vm._v("\n              New Order\n            ")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"bg-white text-nowrap d-flex flex-column flex-sm-row"},[_c('button',{staticClass:"btn btn-filter"},[_vm._v("Add Filter")]),_c('div',{staticClass:"pl-4 w-100 d-flex align-items-center"},[_c('img',{attrs:{"src":'./assets/icons/page/search.svg'}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"w-100 field",attrs:{"type":"text","placeholder":"Search by order Id or Customer Email"},domProps:{"value":(_vm.searchQuery)},on:{"keyup":_vm.getOrdersByAdmin,"input":function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value}}})])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"table-bg"},[_c('table',{staticClass:"table mb-0 text-nowrap"},[_vm._m(1),_c('tbody',_vm._l((_vm.orderList),function(order){return _c('tr',{key:order.id,staticClass:"t-rows"},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(order.hash))]),_c('td',{staticClass:"text-center"},[_vm._v("\n                  "+_vm._s(_vm.formatDate(order.createdAt))+"\n                ")]),_c('td',{staticClass:"text-center"},[_vm._v("\n                  "+_vm._s(_vm.toDisplayUnit(order.grandTotal))+" "+_vm._s(_vm.currency)+"\n                ")]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn text-capitalize",class:{
                        'btn-confirmed':
                          order.paymentStatus === 'Paid',
                        'btn-reject':
                          order.paymentStatus === 'Failed',
                        'btn-reverted':
                          order.paymentStatus === 'Refunded',
                        'btn-pending':
                          order.paymentStatus === 'Pending',
                      }},[_vm._v("\n                    "+_vm._s(order.paymentStatus)+"\n                  ")])]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"btn text-capitalize",class:{
                        'btn-confirmed': order.status === 'Completed',
                        'btn-reject': order.status === 'Cancelled',
                        'btn-deliver': order.status === 'Delivered',
                        'btn-pending': order.status === 'Pending',
                        'btn-processing': order.status === 'Processing',
                        'btn-shipping': order.status === 'Shipping',
                      }},[_vm._v(_vm._s(order.status)+"\n                    ")])]),_c('td',{staticClass:"text-center"},[_vm._v("\n                  "+_vm._s(order.salesChannel)+"\n                ")]),_c('td',{staticClass:"text-center"},[_c('button',{staticClass:"btn btn-view-details",on:{"click":function($event){return _vm.viewOrder(order.id)}}},[_vm._v("View Details")])])])}),0)])])])]),_c('div',{staticClass:"my-5"},[_c('BPagination',{attrs:{"align":"center","limit":10,"hide-goto-end-buttons":true,"total-rows":_vm.rows,"per-page":_vm.perPage},on:{"input":_vm.getOrdersByAdmin},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"pr-3"},[_c('i',{staticClass:"fas fa-plus"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',{staticClass:"thead-bg"},[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Order Id")]),_c('th',{staticClass:"text-center"},[_vm._v("Date")]),_c('th',{staticClass:"text-center"},[_vm._v("Total")]),_c('th',{staticClass:"text-center"},[_vm._v("Payment Status")]),_c('th',{staticClass:"text-center"},[_vm._v("Status")]),_c('th',{staticClass:"text-center"},[_vm._v("Sales Channel")]),_c('th',{staticClass:"text-center"},[_vm._v("Action")])])])}]

export { render, staticRenderFns }