<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent v-if="errors!==''" v-on:getErrorFlag="(ev) => {this.errors = ev}"
                    :err-msg="errors"></ErrorComponent>
    <SideBarLiveMart/>

    <main>
      <Header/>

      <div class="container mb-5 pb-5">
        <div class="row justify-content-center">
          <div class="col-lg-8 mt-2">
            <div class="row">
              <div class="col-md-12">
                <h5 class="title-header pl-0">Store Settings</h5>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-body">
                  <div class="cover-photo">
                    <img class="img-cover"
                         :src="getFullImagePath(coverPicture)"
                         alt="Cover Photo">
                    <input @change="uploadCoverPhotoHandler" type="file" ref="coverPhoto">
                    <button @click="$refs.coverPhoto.click()" class="btn btn-uploadCover">
                      <img class="mr-1" :src="'./assets/icons/page/cloud-upload.svg'">Upload Cover
                    </button>

                    <div class="logo-image">
                      <img :src="getFullImagePath(logoImage)"
                           class="img-logo">

                      <input @change="uploadLogoHandler" type="file" ref="logoImage">
                      <div @click="$refs.logoImage.click()"
                           class="overlay text-center">
                        <i class="fa fas fa-plus icon-plus"></i>
                      </div>
                    </div>
                  </div>

                  <div class="main-form">
                    <div class="form-group">
                      <label class="label" for="name">Store name</label>
                      <input v-model.trim="storeName" type="text" class="form-control input-field" id="name">
                    </div>

                    <div class="form-group">
                      <label class="label" for="name">Store title</label>
                      <input v-model.trim="storeTitle" type="text" class="form-control input-field" id="title">
                    </div>

                    <div class="form-group">
                      <label class="label">Store description</label>
                      <vue-simplemde v-model="description" preview-class="markdown-body"/>
                    </div>

                    <!--                    <div class="form-group">-->
                    <!--                      <label class="label" for="meta_name">Meta Title</label>-->
                    <!--                      <input v-model.trim="metaName" type="text" class="form-control input-field" id="meta_name">-->
                    <!--                    </div>-->

                    <!--                    <div class="form-group">-->
                    <!--                      <label class="label">Meta description</label>-->
                    <!--                      <vue-simplemde v-model="metaDescription" preview-class="markdown-body"/>-->
                    <!--                    </div>-->

                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="label" for="email">Email</label>
                          <input v-model.trim="email" type="email" class="form-control input-field" id="email">
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="label" for="phone">Phone</label>
                          <input v-model.trim="phone" type="tel" class="form-control input-field" id="phone"
                                 placeholder="+880XXXXXXXXX">
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="label" for="country">Country</label>

                          <Select id="country" v-model="locationId" size="large">
                            <Option v-for="location in locationList" :value="location.id" :key="location.id">
                              {{ location.name }}
                            </Option>
                          </Select>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="label" for="city">City</label>
                          <input v-model.trim="city" type="text" class="form-control input-field" id="city">
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="label" for="street">Street</label>
                          <input v-model.trim="street" type="text" class="form-control input-field" id="street">
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="label" for="street_optional">Street Optional</label>
                          <input v-model.trim="streetOptional" type="text" class="form-control input-field"
                                 id="street_optional">
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="label" for="zip">Postcode</label>
                          <input v-model.trim="postCode" type="text" class="form-control input-field" id="zip">
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="label" for="zip">State</label>
                          <input v-model.trim="state" type="text" class="form-control input-field" id="state">
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="label" for="country">Currency</label>

                          <Select id="currency" v-model="currency" size="large">
                            <Option v-for="c in currencyList" :value="c" :key="c">
                              {{ c }}
                            </Option>
                          </Select>
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="label">Store footer scripts</label>
                          <vue-simplemde v-model="storeFooterScripts" preview-class="markdown-body"/>
                        </div>
                      </div>

                    </div>

                    <div class="row mt-28">
                      <div class="col-lg-12">
                        <h5 class="title-header pt-0">Settings</h5>

                        <div class="form-group mt-3 mb-0">
                          <label class="label">Store Open</label>
                          <div class="custom-control custom-switch float-right">
                            <input type="checkbox" v-model="isOpen" class="custom-control-input"
                                   id="productCreation">
                            <label class="custom-control-label" for="productCreation"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <button @click="updateStoreConfig" class="btn btn-apply ml-auto">Update</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import VueSimplemde from 'vue-simplemde';
import axios from 'axios';

import PleaseWait from "../PleaseWait";
import ErrorComponent from "../ErrorComponent";
import Header from "../Header";
import Settings from "../../common/settings";
import MediaService from "../../common/media_service";
import SideBarLiveMart from "../SideBarLiveMart";

export default {
  name: "StoreConfig",
  components: {SideBarLiveMart, Header, ErrorComponent, PleaseWait, VueSimplemde},
  data() {
    return {
      // Flags
      isLoading: false,
      errors: '',

      // Variables & Data
      coverPicture: '',
      logoImage: '',
      favicon: '',
      storeName: '',
      storeTitle: '',
      description: '',
      tags: [],
      metaName: '',
      metaDescription: '',
      metaTags: [],
      phone: '',
      email: '',
      locationId: '',
      locationList: [],
      city: '',
      street: '',
      streetOptional: '',
      state: '',
      postCode: '',
      isOpen: false,
      currency: '',
      website: '',
      currencyList: ['BDT', 'USD', 'EUR'],
      storeFooterScripts: ''
    }
  },
  mounted() {
    this.getLocationsList();
  },
  methods: {
    getStoreInfo: function () {
      this.isLoading = true;

      let pld = {
        query: `query { storeBySecret { name title description tags metaName metaDescription metaTags logo favicon bannerImage isOpen currency website supportEmail createdAt updatedAt supportPhone street streetOptional city state postcode location { id name } storeFooterScripts } }`
      }

      axios.post(`${Settings.GetApiUrl()}`, pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        }
      }).then(resp => {
        this.isLoading = false;
        let store = resp.data.data;
        if (store === null) {
          return
        }

        store = store.storeBySecret;

        this.storeName = store.name;
        this.storeTitle = store.title ? store.title : '';
        this.description = decodeURIComponent(store.description);
        this.coverPicture = store.bannerImage ? store.bannerImage : '';
        this.logoImage = store.logo ? store.logo : '';
        this.favicon = store.favicon ? store.favicon : '';
        this.isOpen = store.isOpen;
        this.street = store.street ? store.street : '';
        this.streetOptional = store.streetOptional ? store.streetOptional : '';
        this.city = store.city ? store.city : '';
        this.state = store.state ? store.state : '';
        this.postCode = store.postcode ? store.postcode : '';
        this.email = store.supportEmail ? store.supportEmail : '';
        this.phone = store.supportPhone ? store.supportPhone : '';
        this.currency = store.currency;
        this.metaName = store.metaName ? store.metaName : '';
        this.metaDescription = store.metaDescription ? store.metaDescription : '';
        this.metaTags = store.metaTags;
        this.storeFooterScripts = decodeURIComponent(store.storeFooterScripts);

        if (store.location !== null) {
          this.locationId = store.location.id;
        }
      }).catch(err => {
        this.isLoading = false;
        this.errors = err.response.data.title;
      })
    },
    updateStoreConfig: function () {
      this.isLoading = true;

      let pld = {
        query: `mutation { updateStoreBySecret(params: { name: "` + this.storeName + `" title: "` + this.storeTitle + `" description: "` + encodeURIComponent(this.description) + `" tags: [] metaName: "` + this.metaName + `" metaDescription: "` + this.metaDescription + `" metaTags: []` + (this.isImageUpdated(this.logoImage) ? ` logo: "${this.logoImage}"` : ``) + `` + (this.isImageUpdated(this.favicon) ? ` favicon: "${this.favicon}"` : ``) + `` + (this.isImageUpdated(this.coverPicture) ? `bannerImage: "${this.coverPicture}"` : ``) + ` isOpen: ` + this.isOpen + ` isCustomerLoyaltyEnabled: false currency: ` + this.currency + ` storeFooterScripts: "` + encodeURIComponent(this.storeFooterScripts) + `" website: "` + this.website + `" supportEmail: "` + this.email + `" supportPhone: "` + this.phone + `" street: "` + this.street + `" streetOptional: "` + this.streetOptional + `" city: "` + this.city + `" state: "` + this.state + `" postcode: "` + this.postCode + `" locationID: "` + this.locationId + `" }) }`
      }

      axios.post(`${Settings.GetApiUrl()}`, pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        }
      }).then(() => {
        this.isLoading = false;
        window.location.reload();
      }).catch(err => {
        this.isLoading = false;
        this.errors = err.response.data.title;
      })
    },
    getLocationsList: function () {
      this.isLoading = true;

      let pld = {
        query: `query { locations { id name shortCode } }`
      }

      axios.post(`${Settings.GetApiUrl()}`, pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        }
      }).then(resp => {
        this.locationList = resp.data.data.locations;
        this.getStoreInfo();

        this.isLoading = false;
      }).catch(err => {
        this.isLoading = false;
        this.errors = err.response.data.title;
      })
    },
    uploadCoverPhotoHandler: function (ev) {
      this.isLoading = true;

      let files = ev.target.files || ev.dataTransfer.files;

      if (files.length > 0) {
        this.isLoading = true;

        MediaService.uploadContent(files[0], "shops").then(resp => {
          this.isLoading = false;

          if (resp.status === 200) {
            let rs = resp.data.data;
            if (rs === null) {
              this.errors = resp.data.errors[0].message;
              return
            }

            this.coverPicture = rs.newMedia;
          }
        }).catch(err => {
          console.log(err);
        });
      }
    },
    uploadLogoHandler: function (ev) {
      this.isLoading = true;

      let files = ev.target.files || ev.dataTransfer.files;

      if (files.length > 0) {
        this.isLoading = true;

        MediaService.uploadContent(files[0], "shops").then(resp => {
          this.isLoading = false;

          if (resp.status === 200) {
            let rs = resp.data.data;
            if (rs === null) {
              this.errors = resp.data.errors[0].message;
              return
            }

            this.logoImage = rs.newMedia;
            this.favicon = rs.newMedia;
          }
        }).catch(err => {
          console.log(err);
        });
      }
    },
    getFullImagePath: function (subPath) {
      console.log(subPath);
      if (subPath.startsWith('http')) {
        return subPath;
      }
      return Settings.GetMediaUrl() + subPath;
    },
    isImageUpdated: function (path) {
      return !path.startsWith('http');
    }
  }
}
</script>

<style scoped>
@import '~simplemde/dist/simplemde.min.css';
@import '~github-markdown-css';


input[type=file] {
  display: none;
}

.mt-28 {
  margin-top: 28px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-top: 5px;
}

.form-body {
  width: 100%;
  height: auto;
  margin-top: 34px;
  margin-bottom: 34px;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
}

.main-form {
  padding: 50px 24px 24px;
}

.cover-photo {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  background-color: #e0e0e0;
}

.cover-photo img.img-cover {
  width: 100%;
  object-fit: cover;
  height: 200px;
  background-repeat: no-repeat;
  background-size: auto;
}

.btn-uploadCover {
  position: absolute;
  top: 70%;
  right: 4%;
  /*transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);*/
  border: solid 1px #dee2ed;
  background-color: #f6f8fc;
  border-radius: 17.5px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #5c6366;
  box-shadow: 4px 6px 3px rgba(3, 13, 20, 0.4);
  transition: .4s;
}

.btn-uploadCover:hover {
  box-shadow: 4px 6px 3px rgba(3, 13, 20, 0);
}

.logo-image {
  /*position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);*/
  position: absolute;
  top: 65%;
  left: 0;
  width: 108px;
  height: 108px;
  margin-left: 24px;
  border-radius: 50%;
}

.logo-image img.img-logo {
  width: 108px;
  height: 108px;
  object-fit: cover;
  border-radius: 50%;
  overflow: hidden;
  border: solid 4px #ffffff;
  background-color: #d8d8d8;
}

.overlay {
  position: absolute;
  width: 24px;
  height: 24px;
  bottom: 10%;
  right: 3%;
  padding-top: 1px;
  background-color: #3f71f4;
  border-radius: 15px;
}

.icon-plus {
  position: relative;
  color: white;
}

@media screen and (max-width: 768px) {
  .overlay {
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: 10%;
    right: 3%;
    padding-top: 1px;
    background-color: #3f71f4;
    border-radius: 15px;
  }
}

@media screen and (max-width: 375px) {
  .overlay {
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: 10%;
    right: 3%;
    padding-top: 1px;
    background-color: #3f71f4;
    border-radius: 15px;
  }
}

@media screen and (max-width: 320px) {
  .overlay {
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: 10%;
    right: 3%;
    padding-top: 1px;
    background-color: #3f71f4;
    border-radius: 15px;
  }
}

.label {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
}

.input-field {
  border-radius: 3px;
  border: solid 1px rgba(90, 99, 124, 0.16);
}

.input-field:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aaafbc;
  opacity: 1; /* Firefox */
}

.btn-apply {
  border-radius: 3px;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 11px 68px;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }
}

@media only screen and (max-width: 320px) {
  .btn-uploadCover {
    top: 20%;
    left: 4%;
  }
}
</style>
