<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <!--    Left side start     -->
          <div class="col-lg-8 mb-5">
            <!--    Head start     -->
            <div class="row head">
              <div class="col-md-12 d-flex align-items-center">
                <i
                    @click="$router.go(-1)"
                    class="fas fa-chevron-left img-nav"></i>
                <h5 class="title-header text-nowrap">Update Digital Content</h5>
              </div>
            </div>
            <!--    Head end     -->

            <ValidationObserver ref="form">
              <div class="form-bg">
                <!--    Product Name    -->
                <div class="form-container">
                  <div class="form-group">
                    <label class="label" for="pname">Title</label>
                    <ValidationProvider
                        rules="required|minlen:1"
                        :bails="false"
                        v-slot="{ errors }">
                      <input
                          type="text"
                          v-model.trim="title"
                          class="form-control input-field"
                          id="pname"
                          placeholder="Title of content"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="form-container">
                  <div class="form-group">
                    <label class="label" for="pname">Position</label>
                    <ValidationProvider
                        :bails="false"
                        v-slot="{ errors }">
                      <input
                          type="number"
                          v-model="position"
                          class="form-control input-field"
                          id="pname"
                          placeholder="Content Position"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <!--    Product category    -->
                <div class="form-container mt-1">
                  <div class="form-group">
                    <label class="label" for="category">Content Type</label>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                          id="category"
                          v-model="contentType"
                          size="large">
                        <Option
                            v-for="t in contentTypes"
                            :value="t"
                            :key="t">{{ t }}
                        </Option>
                      </Select>
                      <label class="label text-danger">{{ errors[0] }}</label>
                    </ValidationProvider>
                  </div>
                </div>

                <!--    Product description    -->
                <div v-if="contentType==='Text'" class="form-container mt-1">
                  <div class="form-group">
                    <label class="label">Content Text</label>
                    <ValidationProvider
                        rules="required|minlen:4"
                        :bails="false"
                        v-slot="{ errors }">
                      <vue-simplemde
                          v-model.trim="contentText"
                          preview-class="markdown-body"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <!--    Product image upload  -->
                <div v-if="contentType!=='Text'" class="">
                  <input
                      @change="onChangePreview"
                      type="file"
                      ref="thumbnail"
                      style="display: none"
                  />
                  <div class="overlay text-center dropzone">
                    <div
                        class="dropzone-custom-content"
                        @click="$refs.thumbnail.click()"
                        :class="{ 'd-none': '' }">
                      <h3 class="dropzone-custom-title">
                        Click here to
                        <span class="b-link">Browse on your computer (max size: 250MB)</span>
                      </h3>
                    </div>
                  </div>
                  <h5 class="mt-1 mb-1">
                    <a class="text-bold text-danger text-center" :href="previousContentUrl" target="_blank">>> View
                      uploaded
                      content</a>
                  </h5>
                </div>
                <!--    Product image upload end  -->

                <div class="form-container mt-1">
                  <div class="form-group">
                    <label class="label" for="isTrial">Allowed for trial?</label>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                          id="isTrial"
                          v-model="isAllowedForTrial"
                          size="large">
                        <Option
                            v-for="t in trialOptions"
                            :value="t"
                            :key="t">{{ t }}
                        </Option>
                      </Select>
                      <label class="label text-danger">{{ errors[0] }}</label>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>
          <!--    Left side end     -->

          <!--    Right side start     -->
          <div class="col-lg-4">
            <div class="row text-nowrap">
              <div class="col-md-12 col-lg-12 text-center">
                <button
                    type="submit"
                    @click="onUpdateDigitalContent()"
                    class="btn text-capitalize btn-publish w-100">
                  Update
                </button>
              </div>
            </div>
          </div>
          <!--    Right side end     -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SideBarLiveMart from "../../../SideBarLiveMart";
import ErrorComponent from "../../../ErrorComponent";
import PleaseWait from "../../../PleaseWait";
import Header from "../../../Header";
import VueSimplemde from "vue-simplemde";
import MediaService from "../../../../common/media_service";
import axios from "axios";
import Settings from "../../../../common/settings";

export default {
  name: "DigitalContentUpdate",
  components: {
    SideBarLiveMart,
    ErrorComponent,
    PleaseWait,
    Header,
    VueSimplemde,
  },
  data() {
    return {
      // Flags
      display: false,
      isLoading: false,
      errors: "",

      // Fields
      title: "",
      contentType: 'Text',
      isTrialAllowed: false,
      contentText: '',
      contentFile: null,
      contentTypes: ['Text', 'Video', 'File'],
      isAllowedForTrial: "No",
      trialOptions: ['Yes', 'No'],
      previousContentUrl: '',
      position: 0
    };
  },
  mounted() {
    this.getDigitalContent();
  },
  methods: {
    getDigitalContent: function () {
      this.isLoading = true;

      let pld = {
        query: `query { productDigitalContentByAdmin(productId: "${this.$route.params.id}", itemId: "${this.$route.params.did}", contentId: "${this.$route.params.cid}") { id title contentType isTrialAllowed contentText contentText contentUrl updatedAt position } }`
      };

      axios.post(`${Settings.GetApiUrl()}`, pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        let data = resp.data.data;
        if (data === null) {
          alert(resp.data.errors[0].message);
          return
        }

        this.isLoading = false;

        data = data.productDigitalContentByAdmin;

        this.title = data.title;
        this.contentType = data.contentType;
        this.position = data.position;

        if (data.contentText !== null) {
          this.contentText = decodeURIComponent(data.contentText);
        }
        this.previousContentUrl = data.contentUrl;
        this.isAllowedForTrial = data.isTrialAllowed ? 'Yes' : 'No';
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.response.data.title;
      });
    },
    onUpdateDigitalContent: function () {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.isLoading = true;

        if (!this.position) {
          this.position = 0
        }

        this.isTrialAllowed = this.isAllowedForTrial === 'Yes';

        MediaService.updateUploadedDigitalContent(this.$route.params.id, this.$route.params.did, this.$route.params.cid, this.title, this.contentType, this.isTrialAllowed, encodeURIComponent(this.contentText), this.contentFile, this.position).then(res => {
          this.isLoading = false;
          let data = res.data.data;
          if (data !== null) {
            this.$router.push(`/store/products/digital-items/${this.$route.params.id}/digital-contents/${this.$route.params.did}/view`);
            return
          }

          alert(res.data.errors[0].message);
        }).catch((err) => {
          this.isLoading = false;
          if (err.response.status === 422) {
            this.errors = err.response.data.errors.message;
          } else {
            this.errors = err.response.data.title;
          }
        });
      });
    },
    onChangePreview: function (ev) {
      let files = ev.target.files || ev.dataTransfer.files;

      if (files.length > 0) {
        this.contentFile = files[0];
      }
    }
  },
}
</script>

<style scoped>
@import "~simplemde/dist/simplemde.min.css";
@import "~github-markdown-css";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.content-wrapper {
  padding: 0 30px;
}

.head {
  height: 43px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-left: 15px;
}

.bg-white {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.img-nav:hover {
  cursor: pointer;
}

.form-bg {
  height: auto;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  padding: 24px;
  margin-top: 20px;
}

.form-container {
  width: 100%;
  margin-top: 24px;
}

.label {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
}

.custom-drop {
  border: solid 1px rgba(90, 99, 124, 0.16);
}

.list-container {
  display: none;
  max-height: 300px;
  overflow-y: scroll;
  border: solid 1px #e9eceb;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
}

.list-main {
  padding: 9px 14px;
  border-bottom: solid 1px #dee2ed;
}

.file-added {
  border: solid 1px #dee2ed;
  background-color: #f6f8fc;
  color: #5c6366;
  padding: 10px 20px;
  border-radius: 30px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
}

.file-added:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.file-added img {
  margin: 0 4px;
}

.input-field {
  border-radius: 3px;
  border: solid 1px rgba(90, 99, 124, 0.16);
}

.input-field:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aaafbc;
  opacity: 1; /* Firefox */
}

.box-bg {
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 24px;
  padding: 24px;
}

.title-radio {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
}

.sub-content {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #6b7694;
  margin-top: 14px;
  padding-right: 35px;
}

.dropzone {
  overflow-y: scroll;
  margin-top: 20px;
  border-radius: 3px;
  border: dashed 2px rgba(90, 99, 124, 0.16);
}

.dropzone-custom-title {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #b8c5cc;
}

.b-link {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #132eaa;
}

.radio-bg {
  border-bottom: 2px solid #132eaa;
}

.btn-sv-draft {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5a637c;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-sv-draft:hover {
  border: solid 2px #c2d2ff;
}

.btn-sv-draft:focus {
  outline: none;
  border: solid 2px #c2d2ff;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-publish {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-publish:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }
}
</style>
