import axios from "axios";
import SessionStore from "./session_store";
import Settings from "./settings";

class MediaService {

    static uploadFile(fileToUpload, bucket) {
        let formData = new FormData();
        formData.append("file", fileToUpload);

        let token = SessionStore.GetAccessToken();

        let queryUrl = Settings.GetMediaUrl() + bucket;

        return axios.post(queryUrl, formData, {
            headers: {
                "Authorization": "Bearer " + token,
                "Content-Type": "multipart/form-data",
            },
        }).then(resp => resp.data).catch(err => console.log(err));
    }

    static uploadContent(file, bucket) {
        let payload = `{
          "query": "mutation($file: Upload!, $bucket: String!) { newMedia(bucket: $bucket contentFile: $file) }",
          "variables": { "file": null, "bucket": "` + bucket + `" }
        }`;

        let form = new FormData()
        form.append(`map`, `{ "0": ["variables.file"] }`);
        form.append(`0`, file);
        form.append(`operations`, payload);

        return axios.post(Settings.GetApiUrl(), form, {
            headers: {
                "store-key": Settings.GetLiveMartKey(),
                "store-secret": Settings.GetLiveMartSecret()
            }
        })
    }

    static uploadDigitalContent(productId, itemId, title, contentType, isTrialAllowed, contentText, contentFile, position) {
        let form;
        if (contentFile === null) {
            let payload = {
                "query": "mutation($productId: String!, $itemId: String!, $params: AddDigitalContentParams!) { productAddDigitalContent(productId: $productId itemId: $itemId params: $params) { id } }",
                "variables": {
                    "productId": productId,
                    "itemId": itemId,
                    "params": {
                        "title": title,
                        "contentType": contentType,
                        "isTrialAllowed": isTrialAllowed,
                        "contentText": contentText,
                        "position": Number(position)
                    }
                }
            };

            form = new FormData()
            form.append("map", `{  }`);
            form.append("operations", JSON.stringify(payload));
        } else {
            let payload = {
                "query": "mutation($productId: String!, $itemId: String!, $params: AddDigitalContentParams!) { productAddDigitalContent(productId: $productId itemId: $itemId params: $params) { id } }",
                "variables": {
                    "productId": productId,
                    "itemId": itemId,
                    "params": {
                        "title": title,
                        "contentType": contentType,
                        "isTrialAllowed": isTrialAllowed,
                        "contentText": contentText,
                        "contentFile": null,
                        "position": Number(position)
                    }
                }
            };

            form = new FormData()
            form.append("map", `{ "0": ["variables.params.contentFile"] }`);
            form.append("0", contentFile);
            form.append("operations", JSON.stringify(payload));
        }

        return axios.post(Settings.GetApiUrl(), form, {
            headers: {
                "store-key": Settings.GetLiveMartKey(),
                "store-secret": Settings.GetLiveMartSecret()
            }
        })
    }

    static updateUploadedDigitalContent(productId, itemId, contentId, title, contentType, isTrialAllowed, contentText, contentFile, position) {
        let form;
        if (contentFile === null) {
            let payload = {
                "query": "mutation($productId: String!, $itemId: String!, $contentId: String!, $params: UpdateDigitalContentParams!) { productUpdateDigitalContent(productId: $productId itemId: $itemId contentId: $contentId params: $params) { id } }",
                "variables": {
                    "productId": productId,
                    "itemId": itemId,
                    "contentId": contentId,
                    "params": {
                        "title": title,
                        "contentType": contentType,
                        "isTrialAllowed": isTrialAllowed,
                        "contentText": contentText,
                        "position": Number(position)
                    }
                }
            };

            form = new FormData()
            form.append("map", `{  }`);
            form.append("operations", JSON.stringify(payload));
        } else {
            let payload = {
                "query": "mutation($productId: String!, $itemId: String!, $contentId: String!, $params: UpdateDigitalContentParams!) { productUpdateDigitalContent(productId: $productId itemId: $itemId contentId: $contentId params: $params) { id } }",
                "variables": {
                    "productId": productId,
                    "itemId": itemId,
                    "contentId": contentId,
                    "params": {
                        "title": title,
                        "contentType": contentType,
                        "isTrialAllowed": isTrialAllowed,
                        "contentText": contentText,
                        "contentFile": null,
                        "position": Number(position)
                    }
                }
            };

            form = new FormData()
            form.append("map", `{ "0": ["variables.params.contentFile"] }`);
            form.append("0", contentFile);
            form.append("operations", JSON.stringify(payload));
        }

        return axios.post(Settings.GetApiUrl(), form, {
            headers: {
                "store-key": Settings.GetLiveMartKey(),
                "store-secret": Settings.GetLiveMartSecret()
            }
        })
    }
}

export default MediaService;
