import moment from "moment";

class Helpers {
    static IsEmail(email) {
        const emailRegexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        return emailRegexp.test(email)
    }

    static ParseDate(date) {
        let m = new moment(date);
        return m.format('DD MMM YYYY h:mm A')
    }
}

export default Helpers;
