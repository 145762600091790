<template>
  <div>
    <div class="row mr-2 ml-2">
      <input class="form-control" v-model="firstName" placeholder="First Name"/>
    </div>
    <div class="row mr-2 ml-2 mt-2">
      <input class="form-control" v-model="lastName" placeholder="Last Name"/>
    </div>
    <div class="row mr-2 ml-2 mt-2">
      <input class="form-control" v-model="email" placeholder="Email"/>
    </div>
    <div class="mt-2 ml-2">
      <button class="btn btn-primary" v-on:click="onUpdate">Add</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Settings from "../../common/settings";

export default {
  name: "AddCustomer",
  data() {
    return {
      firstName: '',
      lastName: '',
      email: ''
    }
  },
  methods: {
    onUpdate: function () {
      if (this.firstName === '' || this.lastName === '' || this.email === '') {
        return
      }

      let payload = {
        query: `mutation { customerRegister(params: { firstName: "${this.firstName}" lastName: "${this.lastName}" email: "${this.email}" password: "123" }) { customer { email } } }`,
      };

      axios.post(`${Settings.GetApiUrl()}`, payload, {
        headers: {
          'store-key': Settings.GetLiveMartKey(),
          'store-secret': Settings.GetLiveMartSecret()
        },
        // eslint-disable-next-line no-unused-vars
      }).then((resp) => {
        this.$emit('close', {isSuccess: true});
        // eslint-disable-next-line no-unused-vars
      }).catch((err) => {
        this.$emit('close', {isSuccess: false});
        this.$dlg.toast('Failed to add customer');
      });
    }
  }
}
</script>
