<template>
  <div>
    <input v-model="searchQuery" class="form-control" placeholder="Customer email"/>
    <div v-if="customer" class="mt-2">
      <span class="helper"></span>
      <img
          :src="getFullImagePath(customer.profilePicture)"
          class="rounded-circle mr-2"
          width="53"
          height="53"
          alt=""
      />
      <span class="text-bold">{{ customer.firstName }} {{ customer.lastName }}</span>
    </div>

    <div class="mt-2">
      <button class="btn btn-info" v-on:click="getCustomerList">Search</button>&nbsp;
      <button v-if="customer" class="btn btn-primary" v-on:click="onUpdate">Select</button>
    </div>
    <div v-if="err" class="mt-2 text-danger">{{ err }}</div>
  </div>
</template>

<script>

import axios from "axios";
import Settings from "../../common/settings";

export default {
  name: "SelectOrderCustomer",
  data() {
    return {
      customer: null,
      searchQuery: null,
      err: null
    }
  },
  methods: {
    onUpdate: function () {
      this.$emit('close', {customer: this.customer});
    },
    getCustomerList: function () {
      this.customer = null;
      this.err = null;

      if (this.searchQuery === null) {
        return
      }

      let emailQuery = `{ key: email value: "${this.searchQuery}" }`;

      let pld = {
        query: `query { customersByAdmin(params: { query: "" filters: [ ${emailQuery} ] }, sort: { by: CreatedAt direction: Desc }, pagination: { perPage: 25 page: 1 }) { id email phone firstName lastName profilePicture } }`
      };

      axios.post(`${Settings.GetApiUrl()}`, pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret()
        }
      }).then(resp => {
        this.isLoading = false;

        if (resp.data.data === null) {
          return
        }

        let customerList = resp.data.data.customersByAdmin;
        if (customerList !== null && customerList.length > 0) {
          this.customer = customerList[0];
        } else {
          this.err = "Customer not found";
        }
      }).catch(err => {
        this.isLoading = false;
        this.errors = err.response.data.title;
      })
    },
    getFullImagePath(subPath) {
      return Settings.GetMediaUrl() + subPath;
    },
  }
}
</script>
