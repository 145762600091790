<template>
  <nav class="navbar">
    <div class="logo">
      <router-link :to='`/store`' @click.native="$router.go()">
        <!--        <img :src="'shopemaa/shopemaa_fav.png'" alt="" style="width: 50px; background-color: white">-->
        <!--        <img class="short-logo" :src="'shopemaa/shopemaa_fav.png'" alt="" style="width: 50px;">-->
        <span class="text-primary text-lg-center" style="font-size: 24px;">Shopemaa</span>
      </router-link>
    </div>
    <ul class="navbar-nav">
      <li :class="{ 'active': $route.path === `/store` }" class="nav-item">
        <router-link :to='`/store`' @click.native="$router.go()" class="nav-link">
          <img :src="'./assets/icons/sidebar/overview.svg'" alt="">
          <span class="link-text">Overview</span>
        </router-link>
      </li>
      <li :class="{ 'active': $route.path === `/store/products`
                ||$route.path === `/store/collections`||$route.path === `/store/categories` }"
          class="nav-item">
        <a class="nav-link" data-toggle="collapse" data-target="#submenu1" aria-expanded="false"
           aria-controls="submenu1">
          <img :src="'./assets/icons/sidebar/catalog.svg'" alt="">
          <span class="link-text">Catalog</span>
        </a>
        <ul class="list-unstyled flex-column submenu collapse" id="submenu1">
          <li class="sub-item">
            <router-link :to='`/store/products`' @click.native="$router.go()" class="sub-link">
              <span class="link-text">Products</span>
            </router-link>
          </li>
          <li class="sub-item">
            <router-link :to='`/store/collections`' @click.native="$router.go()" class="sub-link">
              <span class="link-text">Collections</span>
            </router-link>
          </li>
          <li class="sub-item">
            <router-link :to='`/store/categories`' @click.native="$router.go()" class="sub-link">
              <span class="link-text">Categories</span>
            </router-link>
          </li>
        </ul>
      </li>
      <li :class="{ 'active': $route.path === `/store/orders` }" class="nav-item">
        <router-link :to='`/store/orders`' @click.native="$router.go()" class="nav-link">
          <img :src="'./assets/icons/sidebar/orders.svg'" alt="">
          <span class="link-text">Orders</span>
        </router-link>
      </li>
      <li :class="{ 'active': $route.path === `/store/customers` }" class="nav-item">
        <router-link :to='`/store/customers`' @click.native="$router.go()" class="nav-link">
          <img :src="'./assets/icons/sidebar/customers.svg'" alt="">
          <span class="link-text">Customers</span>
        </router-link>
      </li>
      <li :class="{ 'active': $route.path === `/store/blog-posts` }" class="nav-item">
        <router-link :to='`/store/blog-posts`' @click.native="$router.go()" class="nav-link">
          <img :src="'./assets/icons/sidebar/catalog.svg'" alt="">
          <span class="link-text">Blog Posts</span>
        </router-link>
      </li>
      <li :class="{ 'active': $route.path === `/store/pages` }" class="nav-item">
        <router-link :to='`/store/pages`' @click.native="$router.go()" class="nav-link">
          <img :src="'./assets/icons/sidebar/catalog.svg'" alt="">
          <span class="link-text">Pages</span>
        </router-link>
      </li>
      <li :class="{ 'active': $route.path === `/store/menus` }" class="nav-item">
        <router-link :to='`/store/menus`' @click.native="$router.go()" class="nav-link">
          <img :src="'./assets/icons/sidebar/catalog.svg'" alt="">
          <span class="link-text">Menus</span>
        </router-link>
      </li>
      <li :class="{ 'active': $route.path === `/store/banners` }" class="nav-item">
        <router-link :to='`/store/banners`' @click.native="$router.go()" class="nav-link">
          <img :src="'./assets/icons/sidebar/catalog.svg'" alt="">
          <span class="link-text">Banners</span>
        </router-link>
      </li>
      <li :class="{ 'active': $route.path === `/store/coupons`
            ||$route.path.includes(`/store/coupon/add`)
            ||$route.path.includes(`/store/coupon/update/`) }"
          class="nav-item">
        <router-link :to='`/store/coupons`' @click.native="$router.go()" class="nav-link">
          <img :src="'./assets/icons/sidebar/discount.svg'" alt="">
          <span class="link-text">Coupons</span>
        </router-link>
      </li>
      <li :class="{ 'active': $route.path === `/store/media` }"
          class="nav-item">
        <router-link :to='`/store/media`' @click.native="$router.go()" class="nav-link">
          <img :src="'./assets/icons/page/cloud-upload.svg'" alt="">
          <span class="link-text">Media/Files</span>
        </router-link>
      </li>
      <li :class="{ 'active': $route.path === `/store/store-config`
            ||$route.path === `/store/staffs`
            ||$route.path === `/store/apply-for-store`||$route.path === `/store/shipping-methods`
            ||$route.path === `/store/payment-methods` }"
          class="nav-item">
        <a class="nav-link" data-toggle="collapse" data-target="#submenu2" aria-expanded="false"
           aria-controls="submenu1">
          <img :src="'./assets/icons/sidebar/settings.svg'" alt="">
          <span class="link-text">Store Settings</span>
        </a>
        <ul class="list-unstyled flex-column submenu collapse" id="submenu2">
          <li class="sub-item">
            <router-link :to='`/store/store-config`' @click.native="$router.go()" class="sub-link">
              <span class="link-text">Store Info</span>
            </router-link>
          </li>
          <li class="sub-item">
            <router-link :to='`/store/shipping-methods`' @click.native="$router.go()" class="sub-link">
              <span class="link-text">shipping methods</span>
            </router-link>
          </li>
          <li class="sub-item">
            <router-link :to='`/store/payment-methods`' @click.native="$router.go()" class="sub-link">
              <span class="link-text">payment methods</span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import $ from "jquery";

export default {
  name: "SideBarLiveMart",
  data() {
    return {}
  },
  mounted() {
    $(document).ready(function () {
      $('ul.navbar-nav li').on('click', function () {
        $('ul.navbar-nav').find('li.nav-item.active').removeClass('active');
        $(this).addClass('active');
      });
    });
  },
}
</script>

<style scoped>
nav {
  padding: 0;
  margin: 0;
}

ul, a {
  padding: 0;
}

.navbar {
  position: fixed;
  height: 100vh;
  width: 240px;
  background-color: var(--bg-secondary);
  color: white;
  overflow-y: scroll;
  transition: width 600ms ease;
  z-index: 100;
}

.logo {
  width: 100%;
  padding: 20px 0 20px 0;
  background-color: #152c94;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  width: 180px;
  transition: 600ms;
}

.logo img.short-logo {
  display: none;
  transition: 600ms;
}

.navbar-nav {
  width: 100%;
  height: 100vh;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item {
  width: 100%;
  margin-top: 6px;
  padding: 12px 0 12px 0;
  text-transform: uppercase;
  font-family: InterUI, sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #ffffff;
  border-left: 4px solid transparent;
}

.nav-item:hover {
  background-color: #0d1f73;
}

.nav-item.active {
  background-color: #0d1f73;
  border-left: 4px solid #32c358;
}

.nav-link {
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
}

.nav-link img {
  min-width: 24px;
  margin-left: 16px;
  margin-right: 16px;
}

.nav-link:hover {
  cursor: pointer;
}

.link-text {
  margin-left: 2px;
  white-space: nowrap;
}

.submenu {
  margin-left: 40px;
  margin-right: 18px;
  padding-top: 11px;
}

.sub-item {
  border: 0;
  /*margin-top: 22px;*/
  padding: 11px 0 11px 12px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: #ffffff;
}

.sub-item:hover {
  background-color: var(--bg-secondary);
  border-radius: 3px;
  transition: .4s ease;
}

.sub-link {
  padding: 0;
  display: flex;
  align-items: center;
  height: auto;
  color: white;
  text-decoration: none;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  .navbar {
    top: 0;
    width: 60px;
  }

  .navbar:hover {
    width: 224px;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .navbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE and Edge */
  .navbar {
    -ms-overflow-style: none;
  }

  .logo img {
    display: none;
  }

  .logo img.short-logo {
    display: block;
    width: 24px;
  }

  .navbar:hover .logo img {
    display: block;
  }

  .navbar:hover .logo img.short-logo {
    display: none;
  }
}
</style>
