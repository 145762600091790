<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"
    ></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <!--    Left side start     -->
          <div class="col-lg-8 mb-5">
            <!--    Head start     -->
            <div class="row head">
              <div class="col-md-12 d-flex align-items-center">
                <i
                    @click="$router.go(-1)"
                    class="fas fa-chevron-left img-nav"
                ></i>
                <h5 class="title-header text-nowrap">Update Product</h5>
              </div>
            </div>
            <!--    Head end     -->

            <ValidationObserver ref="form">
              <div class="form-bg">
                <h5 class="title-header text-nowrap pl-0">Product details</h5>
                <!--    Product Name    -->
                <div class="form-container">
                  <div class="form-group">
                    <label class="label" for="pname">Product Name</label>
                    <ValidationProvider
                        rules="required|minlen:4"
                        :bails="false"
                        v-slot="{ errors }">
                      <input
                          type="text"
                          v-model.trim="productName"
                          class="form-control input-field"
                          id="pname"
                          placeholder="Name of the product"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <!--    Product description    -->
                <div class="form-container mt-1">
                  <div class="form-group">
                    <label class="label">Product description</label>
                    <ValidationProvider
                        rules="required|minlen:4"
                        :bails="false"
                        v-slot="{ errors }">
                      <vue-simplemde
                          v-model.trim="productDescription"
                          preview-class="markdown-body"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <!--    Product category    -->
                <div class="form-container mt-1">
                  <div class="form-group">
                    <label class="label" for="category">Select product category</label>

                    <ValidationProvider v-slot="{ errors }">
                      <Select
                          id="category"
                          v-model="productCategory"
                          size="large">
                        <Option
                            value="select"
                            key="select">Choose
                        </Option>
                        <Option
                            v-for="category in categoryList"
                            :value="category.id"
                            :key="category.id">{{ category.name }}
                        </Option>
                      </Select>
                      <label class="label text-danger">{{ errors[0] }}</label>
                    </ValidationProvider>
                  </div>
                </div>

                <div v-if="!productDigital" class="form-container mt-1">
                  <div class="form-group">
                    <label class="label" for="productUnit">Select product unit</label>

                    <ValidationProvider v-slot="{ errors }">
                      <Select
                          id="productUnit"
                          v-model="selectedProductUnit"
                          size="large">
                        <Option
                            v-for="v in productUnits"
                            :value="v"
                            :key="v">{{ v }}
                        </Option>
                      </Select>
                      <label class="label text-danger">{{ errors[0] }}</label>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <!--    Radio starts    -->
              <div id="isDigital" class="row">
                <div class="col">
                  <label class="box-bg">
                    <div class="d-flex">
                      <img
                          :src="'./assets/icons/page/prdigital.svg'"
                          class="mr-2"
                          alt="Digital product"
                      />
                      <h5 class="title-radio pr-3">Digital product</h5>

                      <div class="ml-auto">
                        <input
                            v-model="productDigital"
                            v-bind:value="true"
                            type="radio"
                            class="form-check-input"
                            name="optradio"
                        />
                      </div>
                    </div>
                    <p class="sub-content">
                      Digital product Upload eBooks, music, videos, software etc.
                    </p>
                  </label>
                </div>

                <div class="col">
                  <label class="box-bg">
                    <div class="d-flex">
                      <img
                          :src="'./assets/icons/page/prdphysical.svg'"
                          class="mr-2"
                          alt="Physical product"
                      />
                      <h5 class="title-radio">Physical product</h5>

                      <div class="ml-auto">
                        <input
                            v-model="productDigital"
                            v-bind:value="false"
                            type="radio"
                            class="form-check-input"
                            name="optradio"
                        />
                      </div>
                    </div>
                    <p class="sub-content">
                      Add t-shirt, books, merchandise or any other product
                      that requires shipping
                    </p>
                  </label>
                </div>
              </div>
              <!--    Radio ends    -->

              <!--    Product image upload  -->
              <div class="box-bg">
                <div class="d-block d-md-flex">
                  <h5 class="title-header pl-0">Product preview</h5>

                  <div v-if="productThumbnail" class="ml-auto mt-2 mt-md-0">
                    <button
                        class="
                        btn
                        text-capitalize
                        assign-product
                        file-added
                        text-nowrap
                      "
                        @click="$refs.thumbnail.click()">
                      <img
                          :src="'./assets/icons/page/cloud-upload.svg'"
                          alt=""
                      />
                      Upload Again
                    </button>
                  </div>
                </div>
                <input
                    @change="onChangePreview"
                    type="file"
                    ref="thumbnail"
                    style="display: none"
                />
                <div class="overlay text-center dropzone">
                  <PreviewImageComponent
                      v-if="productThumbnail"
                      :image-src="productThumbnail"
                  />
                  <div
                      class="dropzone-custom-content"
                      @click="$refs.thumbnail.click()"
                      :class="{ 'd-none': productThumbnail }">
                    <h3 class="dropzone-custom-title">
                      Click here to
                      <span class="b-link">Browse on your computer</span>
                    </h3>
                    <div class="dropzone-custom-title">
                      Recommended image size is 1240x930px.
                    </div>
                  </div>
                </div>
              </div>
              <!--    Product image upload end  -->

              <!--    Multiple Product image upload  -->
              <div class="box-bg">
                <div class="d-flex">
                  <h5 class="title-header pl-0">Additional Images</h5>

                  <div
                      v-if="additionalProductImages.length > 0"
                      class="ml-auto">
                    <button
                        @click="$refs.multiImage.click()"
                        class="btn text-capitalize assign-product file-added">
                      <img
                          :src="'./assets/icons/page/cloud-upload.svg'"
                          alt=""
                      />
                      Add image
                    </button>
                  </div>
                </div>
                <input
                    @change="onChangeMultipleImage"
                    type="file"
                    ref="multiImage"
                    style="display: none"
                />
                <div
                    v-if="additionalProductImages.length === 0"
                    class="overlay text-center dropzone">
                  <div
                      @click="$refs.multiImage.click()"
                      class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      Click here to
                      <span class="b-link">Browse on your computer</span>
                    </h3>
                    <div class="dropzone-custom-title">
                      Recommended image size is 1240x930px.
                    </div>
                  </div>
                </div>

                <div
                    v-if="additionalProductImages.length > 0"
                    class="mt-3 d-flex flex-row dropzone">
                  <PreviewImageComponent
                      class="mr-3"
                      v-for="image in additionalProductImages"
                      :key="image.id"
                      @onDeleteAdditionalImage="onClickDelete"
                      :is-times-visible="true"
                      :image-src="image"
                  />
                </div>
              </div>
              <!--    Multiple Product image upload end  -->

              <div class="box-bg">
                <h5 class="title-header pl-0">Price</h5>
                <div class="form-container mt-2">
                  <div class="form-group mb-0">
                    <label class="label" for="price">Product Price</label>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" id="inputGroupPrepend">{{ currency }}</span>
                        </div>
                        <input
                            v-model.number="productPrice"
                            type="number"
                            oninput="validity.valid||(value='');"
                            :min="0"
                            step="0.01"
                            class="form-control input-field"
                            id="price"
                        />
                      </div>
                      <label class="label text-danger">{{ errors[0] }}</label>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="box-bg">
                <h5 class="title-header pl-0">Cost</h5>
                <div class="form-container mt-2">
                  <div class="form-group mb-0">
                    <label class="label" for="price">Product Cost</label>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span
                              class="input-group-text"
                              id="inputGroupPrependcost">{{ currency }}</span>
                        </div>
                        <input
                            v-model.number="productCost"
                            type="number"
                            oninput="validity.valid||(value='');"
                            :min="0"
                            step="0.01"
                            class="form-control input-field"
                            id="cost"
                        />
                      </div>
                      <label class="label text-danger">{{ errors[0] }}</label>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="box-bg">
                <h5 class="title-header pl-0">Product Specific Discount</h5>
                <div class="form-container mt-2">
                  <div class="form-group mb-0">
                    <label class="label" for="product_specific_discount">Discount Percentage(%)</label>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="input-group">
                        <input
                            v-model.number="productSpecificDiscount"
                            type="number"
                            oninput="validity.valid||(value='');"
                            :min="0"
                            step="1"
                            class="form-control input-field"
                            id="product_specific_discount"
                        />
                      </div>
                      <label class="label text-danger">{{ errors[0] }}</label>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div v-if="!productDigital" class="box-bg">
                <h5 class="title-header pl-0">Stock</h5>
                <div class="form-container mt-2">
                  <div class="form-group mb-0">
                    <label class="label" for="stock">Limit product sales</label>

                    <div
                        v-if="productDigital"
                        class="custom-control custom-switch float-right">
                      <input
                          v-model="isUnlimited"
                          @change="setLimit"
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitch1"
                      />
                      <label class="custom-control-label" for="customSwitch1">Unlimited</label>
                    </div>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <input
                          v-if="!isUnlimited"
                          v-model.number="productStock"
                          type="number"
                          oninput="validity.valid||(value='');"
                          :disabled="isUnlimited"
                          :min="0"
                          step="1"
                          class="form-control input-field"
                          id="stock"
                      />
                      <label class="label text-danger">{{ errors[0] }}</label>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="form-container mt-2">
                  <div class="form-group mb-0">
                    <label class="label" for="sku">SKU</label>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <input
                          v-model.trim="productSku"
                          type="text"
                          class="form-control input-field"
                          id="sku"
                      />
                      <label class="label text-danger">{{ errors[0] }}</label>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div v-if="!productDigital" class="box-bg">
                <h5 class="title-header pl-0">Weight</h5>
                <div class="form-container mt-2">
                  <div class="form-group mb-0">
                    <label class="label" for="price">Product Weight (in gram)</label>

                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <div class="input-group">
                        <input
                            v-model.number="productWeight"
                            type="number"
                            oninput="validity.valid||(value='');"
                            :min="0"
                            step="0.01"
                            class="form-control input-field"
                            id="weight"
                        />
                      </div>
                      <label class="label text-danger">{{ errors[0] }}</label>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </ValidationObserver>
          </div>
          <!--    Left side end     -->

          <!--    Right side start     -->
          <div class="col-lg-4">
            <div class="row text-nowrap">
              <div class="col-md-6 col-lg-6 text-center">
                <button
                    type="submit"
                    @click="onProductUpdate(false)"
                    class="btn text-capitalize btn-sv-draft w-100">
                  Save Draft
                </button>
              </div>

              <div class="col-md-6 col-lg-6 text-center mt-2 mt-md-0">
                <button
                    type="submit"
                    @click="onProductUpdate(true)"
                    class="btn text-capitalize btn-publish w-100">
                  Publish
                </button>
              </div>
            </div>

            <div class="bg-white">
              <CardComponent
                  class="text-nowrap"
                  :title="productName"
                  :cover-image="productThumbnail"
                  :price="productPrice"
                  :productUnit="selectedProductUnit"
              />
            </div>
          </div>
          <!--    Right side end     -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import VueSimplemde from "vue-simplemde";
import {extend} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import Header from "../../../components/Header";
import CardComponent from "../../../components/catalog/products/CardComponent";
import PleaseWait from "../../PleaseWait";
import ErrorComponent from "../../ErrorComponent";
import Settings from "../../../common/settings";
import MediaService from "../../../common/media_service";
import PreviewImageComponent from "./PreviewImageComponent";
import SideBarLiveMart from "../../SideBarLiveMart";

export default {
  name: "ProductUpdateView",
  components: {
    SideBarLiveMart,
    PreviewImageComponent,
    ErrorComponent,
    PleaseWait,
    CardComponent,
    Header,
    VueSimplemde,
  },
  data() {
    return {
      // Flags
      display: false,
      isLoading: false,
      errors: "",

      // Fields
      productID: "",
      productName: "",
      productDescription: "",
      productCategory: "",
      productCollection: "",
      selectedCollections: [],
      productDigital: "",
      isPublished: "",
      isShippable: "",
      productThumbnail: "",
      productFiles: "",
      downloadableFile: "",
      productPrice: "",
      productStock: null,
      isUnlimited: Boolean,
      productSku: null,
      productUnit: "",
      productCost: "",
      additionalProductImages: [],

      productSpecificDiscount: 0,

      productWeight: null,

      productUnits: ['None', 'Piece', 'Liter', 'KG'],
      selectedProductUnit: null,

      // Variables
      categoryList: [],

      currency: ''
    };
  },
  mounted() {
    this.currency = Settings.GetStoreCurrency();
    this.getCategoryList();

    $(document).ready(function () {
      $("#isDigital label.box-bg").on("click", function () {
        $("#isDigital").find("label.box-bg.radio-bg").removeClass("radio-bg");
        $(this).addClass("radio-bg");
      });
    });

    this.getProductDetails(this.$route.params.id);
  },
  methods: {
    getProductDetails: function (id) {
      this.isLoading = true;

      let pld = {
        query: `query { productByAdmin(productId: "` + id + `") { id name slug description isPublished sku stock productUnit maxItemPerOrder price productSpecificDiscount cost images isDigitalProduct views createdAt weight weightUnit updatedAt category { id name } } }`
      };

      axios.post(`${Settings.GetApiUrl()}`, pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        let data = resp.data.data;
        if (data === null) {
          return
        }

        data = data.productByAdmin;
        this.isUnlimited = data.stock === null;
        this.productID = data.id;
        this.productName = data.name;
        this.productDescription = decodeURIComponent(data.description);
        this.isPublished = data.isPublished;
        this.isShippable = !data.isDigitalProduct;
        this.productDigital = data.isDigitalProduct;
        this.productPrice = parseFloat(this.toDisplayUnit(data.price));
        this.productCost = parseFloat(this.toDisplayUnit(data.cost));
        this.productSku = data.sku;
        this.productStock = data.stock;
        this.productThumbnail = data.images[0];
        // eslint-disable-next-line no-unused-vars
        this.additionalProductImages = data.images.filter((v, i) => i !== 0);
        this.productSpecificDiscount = data.productSpecificDiscount;

        if (data.category !== null) {
          this.productCategory = data.category.id;
        } else {
          this.productCategory = null;
        }

        this.productWeight = data.weight;

        this.selectedProductUnit = data.productUnit;

        this.isLoading = false;
      }).catch((err) => {
        console.log(err);
        this.isLoading = false;
        this.errors = err.response.data.title;
      });
    },
    getCategoryList: function () {
      this.isLoading = true;
      let payload = {
        query: `{
            categoriesByAdmin(search: { query: "", filters: [] }, sort: { by: CreatedAt direction: Desc }, pagination: {perPage: 10000 page: 1}){
                id
                name
                slug
                isPublished
                description
                image
                createdAt
                updatedAt
                productCount
            }
        }`,
      };

      axios
          .post(Settings.GetApiUrl(), payload, {
            headers: {
              "store-key": Settings.GetLiveMartKey(),
              "store-secret": Settings.GetLiveMartSecret(),
            },
          })
          .then((resp) => {
            this.isLoading = false;
            if (resp.status === 200) {
              let rs = resp.data.data;
              if (rs === null) {
                this.errors = resp.data.errors[0].message;
                return;
              }
              this.categoryList = rs.categoriesByAdmin;
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.errors = err.errors[0].message
          });
    },
    onProductUpdate: function (isPublished) {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        let images = ``;
        images += `"` + this.productThumbnail + `"`

        this.additionalProductImages.forEach(it => {
          images += `"` + it + `"`
        });


        let categoryQuery = ``;
        if (this.productCategory !== null && this.productCategory !== 'select') {
          categoryQuery = `categoryId: "` + this.productCategory + `"`;
        }

        let productUnitQuery = ``;
        if (this.selectedProductUnit === 'None') {
          productUnitQuery = `productUnit: null`
        } else if (this.selectedProductUnit != null) {
          productUnitQuery = `productUnit: ${this.selectedProductUnit}`
        }

        this.isLoading = true;

        let pld = {
          'query': `mutation { updateProduct(productId: "` + this.productID + `" params: { name: "` + this.productName + `" description: "` + encodeURIComponent(this.productDescription) + `" isPublished: ` + isPublished + ` sku: ` + (this.productSku === null ? null : `"` + this.productSku + `"`) + ` stock: ` + (this.productStock === null ? null : `` + this.productStock + ``) + ` maxItemPerOrder: 1 price: ` + parseInt(this.toInputUnit(this.productPrice)) + ` cost: ` + parseInt(this.toInputUnit(this.productCost)) + ` weight: ` + this.productWeight + ` weightUnit: Gram ${productUnitQuery} productSpecificDiscount: ${this.productSpecificDiscount} images: [` + images + `] isDigitalProduct: ` + this.productDigital + ` ${categoryQuery} }) { id } }`
        };

        axios.post(
            `${Settings.GetApiUrl()}`,
            pld,
            {
              headers: {
                "store-key": Settings.GetLiveMartKey(),
                "store-secret": Settings.GetLiveMartSecret()
              },
            }
        ).then(res => {
          this.isLoading = false;
          let data = res.data.data;
          if (data !== null) {
            window.location.reload();
            return
          }

          alert(res.data.errors[0].message);
        }).catch((err) => {
          this.isLoading = false;
          if (err.response.status === 422) {
            this.errors = err.response.data.errors.message;
          } else {
            this.errors = err.response.data.title;
          }
        });
      });
    },
    onChangePreview: function (ev) {
      let files = ev.target.files || ev.dataTransfer.files;
      if (files.length > 0) {
        this.productThumbnail = "";
        this.isLoading = true;

        MediaService.uploadContent(files[0], "products")
            .then((resp) => {
              this.isLoading = false;

              if (resp.status === 200) {
                let rs = resp.data.data;
                if (rs === null) {
                  this.errors = resp.data.errors[0].message;
                  return
                }

                this.productThumbnail = rs.newMedia;
              }
            })
            .catch((err) => {
              this.isLoading = false;
              this.errors = err;
            });
      }
      console.log(ev.target.files || ev.dataTransfer.files);
    },
    onChangeMultipleImage: function (ev) {
      let files = ev.target.files || ev.dataTransfer.files;
      if (files.length > 0) {
        this.isLoading = true;

        MediaService.uploadContent(files[0], "products")
            .then((resp) => {
              this.isLoading = false;

              if (resp.status === 200) {
                let rs = resp.data.data;
                if (rs === null) {
                  this.errors = resp.data.errors[0].message;
                  return
                }

                this.additionalProductImages.push(rs.newMedia);
              }
            })
            .catch((err) => {
              this.isLoading = false;
              this.errors = err;
            });
      }
    },
    handleFiles: function (ev) {
      if (ev.target.files[0]) {
        this.productFiles = ev.target.files[0];
      }
    },
    setLimit: function () {
      if (this.isUnlimited) {
        this.productStock = null;
      }
    },
    onClickDelete: function (value) {
      this.additionalProductImages.splice(
          this.additionalProductImages.indexOf(value),
          1
      );
    },
    toDisplayUnit(v) {
      return (v / 100).toFixed(2);
    },
    toInputUnit(v) {
      return (v * 100).toFixed(2);
    }
  },
};

/***    Validators   ***/
extend("required", {
  ...required,
  message: "This field is required",
});
extend("minlen", {
  validate(value, {length}) {
    return value.length >= length;
  },
  params: ["length"],
  message: "The field must have at least {length} characters",
});
</script>

<style scoped>
@import "~simplemde/dist/simplemde.min.css";
@import "~github-markdown-css";

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.content-wrapper {
  padding: 0 30px;
}

.head {
  height: 43px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-left: 15px;
}

.bg-white {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.img-nav:hover {
  cursor: pointer;
}

.form-bg {
  height: auto;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  padding: 24px;
  margin-top: 20px;
}

.form-container {
  width: 100%;
  margin-top: 24px;
}

.label {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
}

.custom-drop {
  border: solid 1px rgba(90, 99, 124, 0.16);
}

.list-container {
  display: none;
  max-height: 300px;
  overflow-y: scroll;
  border: solid 1px #e9eceb;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
}

.list-main {
  padding: 9px 14px;
  border-bottom: solid 1px #dee2ed;
}

.file-added {
  border: solid 1px #dee2ed;
  background-color: #f6f8fc;
  color: #5c6366;
  padding: 10px 20px;
  border-radius: 30px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
}

.file-added:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.file-added img {
  margin: 0 4px;
}

.input-field {
  border-radius: 3px;
  border: solid 1px rgba(90, 99, 124, 0.16);
}

.input-field:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aaafbc;
  opacity: 1; /* Firefox */
}

.box-bg {
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 24px;
  padding: 24px;
}

.title-radio {
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
}

.sub-content {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #6b7694;
  margin-top: 14px;
  padding-right: 15px;
}

.dropzone {
  overflow-y: scroll;
  margin-top: 20px;
  border-radius: 3px;
  border: dashed 2px rgba(90, 99, 124, 0.16);
}

.dropzone-custom-title {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #b8c5cc;
}

.b-link {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #132eaa;
}

.radio-bg {
  border-bottom: 2px solid #132eaa;
}

.btn-sv-draft {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5a637c;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-sv-draft:hover {
  border: solid 2px #c2d2ff;
}

.btn-sv-draft:focus {
  outline: none;
  border: solid 2px #c2d2ff;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-publish {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-publish:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }
}
</style>
