import SessionStore from "@/common/session_store";

class Settings {

    static GetApiUrl() {
        const url = "https://api.shopemaa.com";
        return `${url}/query`;
    }

    static GetCoreApiUrl() {
        const url = "https://shopemaa.com";
        return `${url}/v1`;
    }

    static GetLiveMartKey() {
        return SessionStore.GetStoreKey();
    }

    static GetLiveMartSecret() {
        return SessionStore.GetStoreSecret();
    }

    static GetMediaUrl() {
        return "https://cdn.shopemaa.cloud/";
    }

    static GetStoreCurrency() {
        return SessionStore.GetStoreCurrency();
    }

    static GetStoreCurrencySign() {
        if (SessionStore.GetStoreCurrency() === 'BDT') {
            return '৳'
        } else if (SessionStore.GetStoreCurrency() === 'EUR') {
            return '€'
        }
        return '$';
    }
}

export default Settings;
