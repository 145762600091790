<template>
  <div class="bg-header">
    <ErrorComponent v-if="errors!==''" v-on:getErrorFlag="(ev) => {this.errors = ev}"
                    :err-msg="errors"></ErrorComponent>

    <div class="d-flex align-items-center justify-content-between">
      <div class="ml-auto">
        <div class="avatar-bg d-flex align-items-center justify-content-center" data-toggle="dropdown">
          <img :src="'./assets/icons/sidebar/settings.svg'" alt="A">
        </div>
        <div class="dropdown-menu dropdown-menu-right p-0 dropdown-bg">
          <div @click="onExit" class="list d-flex justify-content-between">Exit</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorComponent from "./ErrorComponent";
import SessionStore from "@/common/session_store";

export default {
  name: "Header",
  components: {ErrorComponent},
  data() {
    return {
      errors: ''
    }
  },
  methods: {
    onExit: function () {
      SessionStore.CleanSession();
      window.location.href = 'https://shopemaa.com/shops'
    }
  }
}
</script>

<style scoped>
.bg-header {
  height: 77px;
  padding: 15px 30px 0 30px;
}

.avatar-bg {
  width: 46px;
  height: 46px;
  box-shadow: -2px -3px 8px 0 rgba(255, 255, 255, 0.5);
  border: solid 1px #e9eceb;
  background-color: var(--bg-secondary);
  border-radius: 25px;
  text-align: center;
  vertical-align: middle;
}

.avatar-bg:hover {
  cursor: pointer;
}

.dropdown-bg {
  border-bottom: 0;
  -webkit-box-shadow: 0px 5px 16px -5px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 5px 16px -5px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 5px 16px -5px rgba(0, 0, 0, 0.75);
}

.dropdown-bg .list {
  padding: 10px 12px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
  border-bottom: solid 1px #dbe2e6;
}

.dropdown-bg .list:hover {
  cursor: pointer;
  background-color: #fbfbfb;
  color: #4e5558;
}

.tabs {
  border-radius: 3px;
  background-color: #132eaa;
  border: solid 1px #ecf1fe;
  padding: 6px 16px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-left: 12px;
  margin-bottom: 3px;
}
</style>
