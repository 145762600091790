<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"
    ></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <div class="col-md-12 text-nowrap">
            <div class="d-flex flex-column flex-sm-row justify-content-between">
              <h5 class="title-header">Shipping Methods</h5>

              <button
                  @click="onCreateShippingMethod"
                  class="btn text-capitalize btn-add-product mt-2 mt-sm-0">
                <span class="pr-3"><i class="fas fa-plus"></i></span>
                Add Shipping Method
              </button>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="bg-white text-nowrap d-flex flex-column flex-sm-row">
              <button class="btn btn-filter"></button>

              <div class="pl-4 w-100 d-flex align-items-center">
                <img :src="'./assets/icons/page/search.svg'"/>

                <input
                    v-model="searchQuery"
                    @keyup="getShippingMethodListAsAdmin"
                    type="text"
                    class="w-100 field"
                    placeholder="Search Shipping Methods"
                />
              </div>
            </div>
          </div>
        </div>

        <!--    Table starts   -->
        <div class="row">
          <div class="col-md-12">
            <div class="table-bg">
              <table class="table mb-0 text-nowrap">
                <thead class="thead-bg">
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Weight Unit</th>
                  <th class="text-center">Delivery Charge</th>
                  <th class="text-center">Delivery Time</th>
                  <th class="text-center">Charge Type</th>
                  <th class="text-center">Status</th>
                  <th class="text-right"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="method in shippingMethodList"
                    :key="method.id"
                    class="t-rows">
                  <td class="text-left">
                    {{ method.displayName }}
                  </td>
                  <td class="text-left">{{ method.WeightUnit }}</td>
                  <td class="text-center">
                    {{
                      method.isFlat
                          ? `${toDisplayUnit(method.deliveryCharge)} ${currency}`
                          : `${method.deliveryCharge}%`
                    }}
                  </td>
                  <td class="text-center">
                    {{ method.deliveryTimeInDays }}
                    {{ method.deliveryTimeInDays > 1 ? "days" : "day" }}
                  </td>
                  <td class="text-center">
                    {{ method.isFlat ? "Flat" : "Percentage" }}
                  </td>
                  <td class="text-center">
                      <span v-if="method.isActive" class="btn btn-published"
                      >Active</span
                      >
                    <span v-else class="btn btn-published btn-reject"
                    >Inactive</span
                    >
                  </td>
                  <td class="text-right pr-34">
                    <button class="btn btn-option" data-toggle="dropdown">
                      <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right p-0 dropdown-bg">
                      <div
                          @click="onEditShippingMethod(method.id)"
                          class="list d-flex justify-content-between">
                        <span>Edit</span>
                        <i class="fas fa-pencil-alt"></i>
                      </div>
                      <div
                          @click="onDeleteShippingMethod(method.id)"
                          class="list d-flex justify-content-between">
                        <span>Delete</span>
                        <i class="far fa-trash-alt"></i>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!--    Table ends   -->
        <div class="my-5">
          <BPagination
              align="center"
              :limit="3"
              :hide-goto-end-buttons="true"
              @input="getShippingMethodListAsAdmin"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
          >
          </BPagination>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";

import PleaseWait from "../PleaseWait";
import ErrorComponent from "../ErrorComponent";
import Header from "../Header";
import Settings from "../../common/settings";
import SideBarLiveMart from "../SideBarLiveMart";

export default {
  name: "ShippingMethodListView",
  components: {SideBarLiveMart, Header, ErrorComponent, PleaseWait},
  data() {
    return {
      // Flags
      isLoading: false,
      errors: "",

      // Variables
      rows: 100,
      currentPage: 1,
      perPage: 10,
      searchQuery: "",
      filterKeyCollection: "",
      filterKeyCategory: "",

      countryID: "",

      // Data
      shippingMethodList: [],
      locationList: [],

      currency: ''
    };
  },
  mounted() {
    this.currency = Settings.GetStoreCurrency();
    this.getShippingMethodListAsAdmin();
  },
  methods: {
    getShippingMethodListAsAdmin: function () {
      this.isLoading = true;

      let payload = {
        query: `{
            shippingMethodsByAdmin(sort: { by: CreatedAt direction: Desc }, pagination: {perPage: ${this.perPage} page: ${this.currentPage}}){
              id
              displayName
              deliveryCharge
              deliveryTimeInDays
              WeightUnit
              isFlat
              isActive
            }
          }`,
      };

      axios
          .post(Settings.GetApiUrl(), payload, {
            headers: {
              "store-key": Settings.GetLiveMartKey(),
              "store-secret": Settings.GetLiveMartSecret(),
            },
          })
          .then((resp) => {
            this.isLoading = false;
            if (resp.status === 200) {
              let rs = resp.data.data;
              if (rs === null) {
                this.errors = resp.data.errors[0].message;
                return;
              }
              this.shippingMethodList = rs.shippingMethodsByAdmin;
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.errors = err.errors[0].message;
          });
    },
    onDeleteShippingMethod: function (id) {
      this.isLoading = true;

      let pld = {
        'query': `mutation { deleteShippingMethod(id: "` + id + `") }`
      };

      axios
          .post(`${Settings.GetApiUrl()}`, pld, {
            headers: {
              "store-key": Settings.GetLiveMartKey(),
              "store-secret": Settings.GetLiveMartSecret(),
            },
          })
          .then(() => {
            this.getShippingMethodListAsAdmin();
          })
          // eslint-disable-next-line no-unused-vars
          .catch((err) => {
            this.isLoading = false;
            this.$dlg.toast('Unable to delete shipping method');
          });
    },
    onEditShippingMethod: function (id) {
      this.$router.push(`/store/shipping-methods/update/${id}`);
    },
    onCreateShippingMethod: function () {
      this.$router.push(`/store/shipping-methods/add`);
    },
    toDisplayUnit(v) {
      return (v / 100).toFixed(2);
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  padding: 0 30px;
}

.bg-white,
.table-bg {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-top: 5px;
}

.btn-add-product {
  border-radius: 3px;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 11px 19px;
  border: 0;
  outline: none;
}

.btn-filter {
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #132eaa;
  padding: 15px 38px;
  border-right: 1px solid #e4eaec;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.helper img {
  display: block;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

input[type="text"] {
  width: 80%;
  border: 0;
  padding: 0 10px;
  outline: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaafbc;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

.field {
}

.table-bg {
  margin-top: 24px;
  overflow-y: visible;
}

.thead-bg {
  background-color: #ecf1fe;
}

.pl-34 {
  padding-left: 34px;
}

.pr-34 {
  padding-right: 34px;
}

.thead-bg th {
  border: 0;
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
}

.t-rows {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373b3d;
}

tbody tr td {
  vertical-align: middle;
}

.btn-published {
  border-radius: 14px;
  background-color: #caece7;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #287166;
  padding: 6px 17px;
  border: 0;
  outline: none;
}

.btn-reject {
  background-color: #e0e0e0;
  color: #333333;
}

.btn-published:focus,
.btn-add-product:focus {
  outline: none;
  border: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-option {
  border-radius: 14px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b8c5cc;
  padding: 3px 10px;
  border: 0;
  outline: none;
}

.dropdown-bg {
  min-width: 132px;
  border-bottom: 0;
}

.dropdown-bg .list {
  padding: 10px 12px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
  border-bottom: solid 1px #dbe2e6;
}

.dropdown-bg .list:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }
}
</style>
