<template>
  <div>
    <Select
        id="collections"
        v-model="selectedOption"
        size="large">
      <Option v-for="o in orderStatusList" :value="o.id" :key="o.id">{{ o.name }}</Option>
    </Select>
    <div class="mt-2">
      <button class="btn btn-primary" v-on:click="onUpdate">Update</button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Settings from "@/common/settings";

export default {
  name: "ChooseOrderStatus",
  props: {
    orderId: String
  },
  data() {
    return {
      selectedOption: undefined,
      orderStatusList: [
        {id: 1, name: 'Pending'},
        {id: 2, name: 'Processing'},
        {id: 3, name: 'Shipping'},
        {id: 4, name: 'Delivered'},
        {id: 5, name: 'Completed'},
        {id: 6, name: 'Cancelled'},
        {id: 7, name: 'Returned'}
      ]
    }
  },
  methods: {
    onUpdate: function () {
      if (this.selectedOption === undefined) {
        return
      }

      let option = '';
      this.orderStatusList.forEach(it => {
        if (it.id === this.selectedOption) {
          option = it.name;
        }
      });

      let payload = {
        query: `mutation { orderUpdateByAdmin(id: "` + this.orderId + `", params: { status: ` + option + ` }) { id } }`,
      };

      axios.post(`${Settings.GetApiUrl()}`, payload, {
        headers: {
          'store-key': Settings.GetLiveMartKey(),
          'store-secret': Settings.GetLiveMartSecret()
        },
        // eslint-disable-next-line no-unused-vars
      }).then((resp) => {
        this.$emit('close', {isSuccess: true});
        // eslint-disable-next-line no-unused-vars
      }).catch((err) => {
        this.$emit('close', {isSuccess: false});
        this.$dlg.toast('Failed to update status');
      });
    }
  }
}
</script>
