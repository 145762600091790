class SessionStore {
    static SetSession(session) {
        localStorage.setItem('access_token', session.access_token);
        localStorage.setItem('refresh_token', session.refresh_token);
        localStorage.setItem('expire_on', session.expire_on);
        localStorage.setItem('permission', session.permission);
        localStorage.setItem('has_store', session.has_store);
        localStorage.setItem('store_name', session.store_name);
    }

    static GetAccessToken() {
        return localStorage.getItem('access_token');
    }

    static IsAdmin() {
        return localStorage.getItem("permission") === "admin"
    }

    static IsManager() {
        return localStorage.getItem("permission") === "manager"
    }

    static IsUser() {
        return localStorage.getItem("permission") === "user"
    }

    static SetStorePermission(param) {
        return localStorage.setItem("store_permission", param);
    }

    static IsStoreAdmin() {
        return localStorage.getItem("store_permission") === "admin"
    }

    static HasStore() {
        let hasStore = localStorage.getItem('has_store');

        if (hasStore === 'true') {
            return true
        } else if (hasStore === 'false') {
            return false
        }
    }

    static IsLoggedIn() {
        let token = localStorage.getItem('access_token');
        let expireOn = localStorage.getItem('expire_on');
        let now = new Date().getTime() / 1000;

        return token !== null && now < expireOn;
    }

    static isUnauthorized(err) {
        let resp = err.response;
        return resp.status === 401
    }

    static isStoreNotFound(err) {
        let resp = err.response;
        return resp.status === 404
    }

    static SetStoreKey(key) {
        localStorage.setItem(this.withPrefix('store-key'), key)
    }

    static SetStoreSecret(secret) {
        localStorage.setItem(this.withPrefix('store-secret'), secret)
    }

    static SetStore(pld) {
        localStorage.setItem(this.withPrefix('store'), JSON.stringify(pld))
    }

    static GetStoreKey() {
        return localStorage.getItem(this.withPrefix('store-key'))
    }

    static GetStoreSecret() {
        return localStorage.getItem(this.withPrefix('store-secret'))
    }

    static GetStore() {
        console.log(JSON.parse(localStorage.getItem(this.withPrefix('store'))))
        return JSON.parse(localStorage.getItem(this.withPrefix('store')))
    }

    static GetStoreCurrency() {
        return JSON.parse(localStorage.getItem(this.withPrefix('store'))).currency;
    }

    static CleanSession() {
        localStorage.removeItem(this.withPrefix('store-key'));
        localStorage.removeItem(this.withPrefix('store-secret'));
        localStorage.removeItem(this.withPrefix('store'));
    }

    static setCurrentPage(key, page) {
        localStorage.setItem(this.withPrefix(`${key}_current_page`), page);
    }

    static getCurrentPage(key) {
        return localStorage.getItem(this.withPrefix(`${key}_current_page`)) === null ? 1 : Number(localStorage.getItem(this.withPrefix(`${key}_current_page`)));
    }

    static setQuery(key, q) {
        localStorage.setItem(this.withPrefix(`${key}_current_page_query`), q);
    }

    static getQuery(key) {
        return localStorage.getItem(this.withPrefix(`${key}_current_page_query`));
    }

    static withPrefix(key) {
        return 'livemart-cockpit_' + key;
    }
}

export default SessionStore;
