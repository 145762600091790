<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"
    ></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <!--    Left side start     -->
          <div class="col-md-8 mb-5">
            <!--    Head start     -->
            <div class="row head">
              <div class="col-md-12 d-flex align-items-center">
                <i
                    @click="$router.go(-1)"
                    class="fas fa-chevron-left img-nav"
                ></i>
                <h5 class="title-header">Update Shipping Method</h5>
              </div>
            </div>
            <!--    Head end     -->

            <ValidationObserver ref="form">
              <div class="form-bg">
                <h5 class="title-header pl-0">General Information</h5>
                <!--    Product Name    -->
                <div class="form-container">
                  <div class="form-group">
                    <label class="label" for="name">Method Name</label>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <input
                          type="text"
                          v-model.trim="name"
                          class="form-control input-field"
                          id="name"
                          placeholder="Name"
                      />
                      <span class="label text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <!--    Radio starts    -->
              <div class="form-bg">
                <h5 class="title-header pl-0">Others</h5>

                <div class="row">
                  <div class="col-lg-4">
                    <div class="form-container">
                      <div class="form-group">
                        <label class="label" for="delivery_charge"
                        >Delivery Charge</label
                        >

                        <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                        >
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span
                                  class="input-group-text"
                                  id="inputGroupPrepend">{{ currency }}</span>
                            </div>
                            <input
                                v-model.number="deliveryCharge"
                                type="number"
                                oninput="validity.valid||(value='');"
                                placeholder="7"
                                :min="0"
                                step="0.01"
                                class="form-control input-field"
                                id="delivery_charge"
                            />
                          </div>
                          <span class="label text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-container">
                      <div class="form-group">
                        <label class="label" for="delivery_time"
                        >Approximate Delivery Time</label
                        >

                        <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                        >
                          <input
                              type="number"
                              oninput="validity.valid||(value='');"
                              :min="0"
                              v-model.number="deliveryTime"
                              placeholder="3 days"
                              class="form-control input-field"
                              id="delivery_time"
                          />
                          <span class="label text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="form-container">
                      <div class="form-group">
                        <label class="label" for="weight_unit"
                        >Weight Unit</label
                        >

                        <ValidationProvider
                            rules="required"
                            v-slot="{ errors }"
                        >
                          <input
                              type="text"
                              v-model.trim="weightUnit"
                              placeholder="kg"
                              class="form-control input-field"
                              id="weight_unit"
                          />
                          <span class="label text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-container mt-1">
                  <label class="label">Flat Charge?</label>
                  <div class="custom-control custom-switch float-right">
                    <input
                        v-model="type"
                        type="checkbox"
                        class="custom-control-input"
                        id="switchmaxuse"
                    />
                    <label class="custom-control-label" for="switchmaxuse">{{
                        type ? "Yes" : "No"
                      }}</label>
                  </div>
                </div>
              </div>
              <!--    Radio ends    -->
            </ValidationObserver>
          </div>
          <!--    Left side end     -->

          <!--    Right side start     -->
          <div class="col-md-4">
            <div class="row text-nowrap">
              <div class="col-md-12 col-lg-6 text-center">
                <button
                    @click="onUpdateShippingMethod(false)"
                    class="btn text-capitalize btn-sv-draft w-100"
                >
                  Save Draft
                </button>
              </div>
              <div class="col-md-12 col-lg-6 text-center mt-2 mt-lg-0">
                <button
                    @click="onUpdateShippingMethod(true)"
                    class="btn text-capitalize btn-publish w-100"
                >
                  Activate
                </button>
              </div>
            </div>
            <div class="bg-white">
              <div class="summary-bg">Summary</div>

              <div class="summaryBody">
                <ul class="list-unstyled">
                  <li v-if="name">
                    <p>Name</p>
                    <p class="values">{{ name }}</p>
                  </li>
                  <li v-if="deliveryCharge">
                    <p>Delivery Charge</p>
                    <p class="values">
                      {{ type ? `${deliveryCharge} ${currency}` : `${deliveryCharge}%` }}
                    </p>
                  </li>
                  <li v-if="deliveryTime">
                    <p>Approximate Delivery Time</p>
                    <p class="values">
                      {{ deliveryTime }} {{ deliveryTime > 1 ? "days" : "day" }}
                    </p>
                  </li>
                  <li v-if="weightUnit">
                    <p>Weight Unit</p>
                    <p class="values">{{ weightUnit }}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--    Right side end     -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";

import PleaseWait from "../PleaseWait";
import ErrorComponent from "../ErrorComponent";
import Header from "../Header";
import Settings from "../../common/settings";
import SideBarLiveMart from "../SideBarLiveMart";

export default {
  name: "ShippingMethodUpdateView",
  components: {SideBarLiveMart, Header, ErrorComponent, PleaseWait},
  data() {
    return {
      // Flags
      isLoading: false,
      errors: "",

      // Variables
      rows: 100,
      currentPage: 1,
      perPage: 10,
      name: "",
      deliveryCharge: "",
      deliveryTime: "",
      weightUnit: "",
      type: Boolean,

      // Data
      shippingMethodList: [],

      currency: ''
    };
  },
  mounted() {
    this.currency = Settings.GetStoreCurrency();
    this.getShippingMethodByAdmin();
  },
  methods: {
    getShippingMethodByAdmin: function () {
      this.isLoading = true;
      let payload = {
        query: `query {
          shippingMethodByAdmin(id: "${this.$route.params.id}"){
            id
            displayName
            deliveryCharge
            deliveryTimeInDays
            WeightUnit
            isFlat
            isActive
          }
        }`,
      };

      axios
          .post(Settings.GetApiUrl(), payload, {
            headers: {
              "store-key": Settings.GetLiveMartKey(),
              "store-secret": Settings.GetLiveMartSecret(),
            },
          })
          .then((resp) => {
            this.isLoading = false;
            if (resp.status === 200) {
              let rs = resp.data.data;
              if (rs === null) {
                this.errors = resp.data.errors[0].message;
                return;
              }
              const data = rs.shippingMethodByAdmin;
              this.name = data.displayName;
              if (data.isFlat) {
                this.deliveryCharge = parseFloat(
                    this.toDisplayUnit(data.deliveryCharge)
                );
              } else {
                this.deliveryCharge = data.deliveryCharge;
              }
              this.deliveryTime = data.deliveryTimeInDays;
              this.weightUnit = data.WeightUnit;
              this.type = data.isFlat;
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.errors = err.errors[0].message;
          });
    },
    onUpdateShippingMethod: function (isActive) {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        this.isLoading = true;
        let payload = {
          query: `mutation { updateShippingMethod(id: "${this.$route.params.id}", params: {displayName: "${this.name}" deliveryCharge: ${this.type ? parseInt(this.toInputUnit(this.deliveryCharge)) : this.deliveryCharge} deliveryTimeInDays: ${this.deliveryTime} WeightUnit: ${this.weightUnit} isFlat: ${this.type} isActive: ${isActive} }){ id displayName deliveryCharge deliveryTimeInDays WeightUnit isFlat isActive }}`
        };

        axios
            .post(Settings.GetApiUrl(), payload, {
              headers: {
                "store-key": Settings.GetLiveMartKey(),
                "store-secret": Settings.GetLiveMartSecret(),
              },
            })
            .then((resp) => {
              this.isLoading = false;
              if (resp.status === 200) {
                let rs = resp.data.data;
                if (rs === null) {
                  this.errors = resp.data.errors[0].message;
                  return;
                }
                this.shippingMethodList = rs.shippingMethodsByAdmin;
                this.$router.push(`/store/shipping-methods`);
              }
            })
            .catch((err) => {
              this.isLoading = false;
              this.errors = err.errors[0].message;
            });
      });
    },
    toDisplayUnit(v) {
      return (v / 100).toFixed(2);
    },
    toInputUnit(v) {
      return (v * 100).toFixed(2);
    },
  },
};
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.content-wrapper {
  padding: 0 30px;
}

.head {
  height: 43px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-left: 15px;
}

.bg-white {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.img-nav:hover {
  cursor: pointer;
}

.form-bg {
  height: auto;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  padding: 24px;
  margin-top: 20px;
}

.form-container {
  width: 100%;
  margin-top: 24px;
}

.label {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
}

.input-field {
  border-radius: 3px;
  border: solid 1px rgba(90, 99, 124, 0.16);
}

.input-field:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aaafbc;
  opacity: 1; /* Firefox */
}

.btn-sv-draft {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5a637c;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-sv-draft:hover {
  border: solid 2px #c2d2ff;
}

.btn-sv-draft:focus {
  outline: none;
  border: solid 2px #c2d2ff;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-publish {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-publish:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.summary-bg {
  padding: 24px;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #363b4a;
  box-shadow: -2px -3px 8px 0 rgba(255, 255, 255, 0.5);
  background-color: #ecf1fe;
}

.summaryBody {
  padding: 24px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5a637c;
}

.summaryBody ul li {
  margin-bottom: 24px;
}

.values {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  margin-top: 8px;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }

  .head {
    height: auto;
  }
}
</style>
