<template>
  <div class="img-container">
    <div class="img-wrapper">
      <img class="image" :src.prop="getFullImagePath(imageSrc)"/>
      <span
          v-if="isTimesVisible"
          @click="onClickDelete(imageSrc)"
          class="close-btn"
      ><img :src="'./assets/icons/page/close.svg'" alt=""
      /></span>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Settings from "../../../common/settings";

export default {
  name: "PreviewImageComponent",
  props: {
    imageSrc: "",
    isTimesVisible: {
      default: false,
    },
  },
  methods: {
    getFullImagePath: function (subPath) {
      if (subPath.startsWith('http')) {
        return subPath;
      }
      return Settings.GetMediaUrl() + subPath;
    },
    onClickDelete: function (param) {
      this.$emit("onDeleteAdditionalImage", param);
    },
  },
};
</script>

<style scoped>
.img-container {
  border-radius: 3px;
}

.img-wrapper {
  position: relative;
  width: 152px;
  height: 136px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: transparent;
}

img.image {
  position: relative;
  width: 152px;
  height: 136px;
  transition: 0.5s ease;
  backface-visibility: hidden;
  border-radius: 3px;
  /*border: 2px solid #dee2ed;*/
}

.img-wrapper .close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  /*transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);*/
  padding: 4px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.63);
}

.close-btn:hover {
  cursor: pointer;
}

.img-wrapper .close-btn img {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
