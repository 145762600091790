import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        storeInstance: '',
    },
    plugins: [createPersistedState()],
    mutations: {
        setStoreInstance: (state, payload) => {
            state.storeInstance = payload;
        },
    },
    actions: {
        setStoreInstanceAction: (context, payload) => {
            context.commit('setStoreInstance', payload);
        },
    },
    getters: {
        getStoreInstance: (state) => {
            return state.storeInstance;
        },
    },
});