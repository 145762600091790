<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"
    ></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column flex-sm-row justify-content-between">
              <h5 class="title-header"><a v-on:click="onGotoProduct">Product history of {{ productName }}</a></h5>
            </div>
          </div>
        </div>

        <!--    Table starts   -->
        <div class="row">
          <div class="col-md-12">
            <div class="table-bg">
              <table class="table mb-0 text-nowrap">
                <thead class="thead-bg">
                <tr>
                  <th class="text-left">Message</th>
                  <th class="text-center">Old Stock</th>
                  <th class="text-center">New Stock</th>
                  <th class="text-center">Change</th>
                  <th class="text-center">Date</th>
                  <th class="text-center">VariationID</th>
                  <th class="text-center">OrderID</th>
                  <th class="text-right"></th>
                </tr>
                </thead>

                <tbody>
                <tr
                    v-for="(h,index) in history"
                    :key="index"
                    class="t-rows">
                  <td class="text-center">
                    {{ h.message }}
                  </td>
                  <td class="text-center">
                    {{ h.oldStock }}
                  </td>
                  <td class="text-center">
                    {{ h.newStock }}
                  </td>
                  <td class="text-center">
                    {{ h.newStock - h.oldStock }}
                  </td>
                  <td class="text-center">
                    {{ formatDate(h.createdAt) }}
                  </td>
                  <td class="text-center">
                    {{ h.variationID ? h.variationID : 'N/A' }}
                  </td>
                  <td class="text-center">
                    {{ h.orderHash ? h.orderHash : 'N/A' }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--    Table ends   -->
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import {extend} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import Header from "../../../components/Header";
import PleaseWait from "../../PleaseWait";
import ErrorComponent from "../../ErrorComponent";
import Settings from "../../../common/settings";
import SideBarLiveMart from "../../SideBarLiveMart";
import Helpers from "../../../helpers/helpers";

export default {
  name: "ProductHistory",
  components: {
    SideBarLiveMart,
    ErrorComponent,
    PleaseWait,
    Header,
  },
  data() {
    return {
      history: [],
      errors: '',
      isLoading: false,
      productName: ''
    };
  },
  mounted() {
    this.getProductDetails(this.$route.params.id);
  },
  methods: {
    formatDate: function (v) {
      return Helpers.ParseDate(v)
    },
    onGotoProduct: function () {
      this.$router.push(`/store/products/update/${this.$route.params.id}`)
    },
    getProductDetails: function (id) {
      this.isLoading = true;

      let pld = {
        query: `query { productByAdmin(productId: "` + id + `") { id name histories { message newStock oldStock variationID orderHash createdAt } }}`
      };

      axios.post(`${Settings.GetApiUrl()}`, pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        let data = resp.data.data;
        if (data === null) {
          return
        }

        this.history = data.productByAdmin.histories;
        this.productName = data.productByAdmin.name;

        this.isLoading = false;
      }).catch((err) => {
        console.log(err);
        this.isLoading = false;
        this.errors = err.response.data.title;
      });
    },
  },
};

/***    Validators   ***/
extend("required", {
  ...required,
  message: "This field is required",
});
extend("minlen", {
  validate(value, {length}) {
    return value.length >= length;
  },
  params: ["length"],
  message: "The field must have at least {length} characters",
});
</script>

<style scoped>
.content-wrapper {
  padding: 0 30px;
}

.bg-white,
.table-bg {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-top: 5px;
}

.btn-add-product {
  border-radius: 3px;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 11px 19px;
  border: 0;
  outline: none;
}

.btn-filter {
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #132eaa;
  padding: 15px 38px;
  border-right: 1px solid #e4eaec;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.helper img {
  display: block;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

input[type="text"] {
  height: 49px;
  font-size: 14px;
  border: 0;
  padding: 0 10px;
  outline: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaafbc;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

.field {
}

.table-bg {
  margin-top: 24px;
  overflow-y: visible;
}

.thead-bg {
  background-color: #ecf1fe;
}

.pl-34 {
  padding-left: 34px;
}

.pr-34 {
  padding-right: 34px;
}

.thead-bg th {
  border: 0;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
}

.t-rows {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373b3d;
}

tbody tr td {
  vertical-align: middle;
}

.t-rows td img {
  border: solid 1px #ced9f5;
  padding: 1px;
}

.dropdown-bg {
  min-width: 132px;
  border-bottom: 0;
}

.dropdown-bg .list {
  padding: 10px 12px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
  border-bottom: solid 1px #dbe2e6;
}

.dropdown-bg .list:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

.btn-published {
  cursor: default;
  border-radius: 14px;
  background-color: #caece7;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #287166;
  padding: 6px 17px;
  border: 0;
  outline: none;
}

.btn-reject {
  background-color: #e0e0e0;
  color: #333333;
}

.btn-digital {
  background-color: #d4d3d3;
  color: #087dcb;
}

.btn-physical {
  background-color: #d4d3d3;
  color: #06796e;
}

.btn-published:focus,
.btn-add-product:focus {
  outline: none;
  border: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-option {
  border-radius: 14px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b8c5cc;
  padding: 3px 10px;
  border: 0;
  outline: none;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }

  .btn-filter {
    border-right: 0;
    border-bottom: 1px solid #e4eaec;
  }
}
</style>
