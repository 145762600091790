<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"
    ></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2 mb-5">
        <div class="row">
          <!--    Left side start     -->
          <div class="col-lg-8 mb-5">
            <!--    Head start     -->
            <div class="row head">
              <div class="col-md-12 d-flex align-items-center">
                <i
                    @click="$router.go(-1)"
                    class="fas fa-chevron-left img-nav"
                ></i>
                <h5 class="title-header">Digital Items</h5>
              </div>
            </div>

            <!--    Head end     -->
            <div class="form-bg">
              <h5 class="title-header pl-0">Update Digital Item</h5>

              <ValidationObserver ref="form">
                <!--    Product Name    -->
                <div class="form-container">
                  <div class="form-group">
                    <label class="label" for="pname">Title</label>
                    <ValidationProvider
                        rules="required|minlen:4"
                        :bails="false"
                        v-slot="{ errors }">
                      <input
                          v-model="title"
                          type="text"
                          class="form-control input-field"
                          id="pname"
                          placeholder="Digital Item title"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <!--    Product description    -->
                <div class="form-container mt-3">
                  <div class="form-group">
                    <label class="label">Description</label>
                    <ValidationProvider
                        rules="required|minlen:4"
                        :bails="false"
                        v-slot="{ errors }">
                      <vue-simplemde
                          v-model="description"
                          preview-class="markdown-body"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="form-container">
                  <div class="form-group">
                    <label class="label" for="pname">Position</label>
                    <ValidationProvider
                        :bails="false"
                        v-slot="{ errors }">
                      <input
                          v-model="position"
                          type="text"
                          class="form-control input-field"
                          id="pname"
                          placeholder="Digital Item Position"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>
              </ValidationObserver>
            </div>
          </div>
          <!--    Left side end     -->

          <!--    Right side start     -->
          <div class="col-lg-4">
            <div class="row text-nowrap">
              <div class="col-md-12 col-lg-12 text-center mt-2 mt-md-0">
                <button
                    @click="onUpdateDigitalItem()"
                    class="btn text-capitalize btn-publish w-100">
                  Update
                </button>
              </div>
            </div>
          </div>
          <!--    Right side end     -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import SideBarLiveMart from "../../../SideBarLiveMart";
import PleaseWait from "../../../PleaseWait";
import ErrorComponent from "../../../ErrorComponent";
import Header from "../../../Header";
import VueSimplemde from "vue-simplemde";
import axios from "axios";
import Settings from "../../../../common/settings";

export default {
  name: "DigitalItemUpdate",
  components: {
    SideBarLiveMart,
    PleaseWait,
    ErrorComponent,
    Header,
    VueSimplemde,
  },
  data() {
    return {
      // Flags
      isLoading: false,
      errors: "",

      // Fields
      title: "",
      description: "",
      position: 0
    };
  },
  mounted() {
    this.title = decodeURIComponent(this.$route.query.title ? this.$route.query.title : '');
    this.description = decodeURIComponent(this.$route.query.description ? this.$route.query.description : '');
    this.position = this.$route.query.position;
  },
  methods: {
    onUpdateDigitalItem: function () {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        this.isLoading = true;

        if (!this.position) {
          this.position = 0
        }

        let pld = {
          'query': `mutation { productUpdateDigitalItem(productId: "` + this.$route.params.id + `", itemId: "` + this.$route.params.did + `" params: { title: "` + this.title + `" description: "` + encodeURIComponent(this.description) + `" position: ${this.position} }) { id title description } }`
        };

        axios.post(
            `${Settings.GetApiUrl()}`,
            pld,
            {
              headers: {
                "store-key": Settings.GetLiveMartKey(),
                "store-secret": Settings.GetLiveMartSecret()
              },
            }
        ).then(res => {
          this.isLoading = false;
          let data = res.data.data;
          if (data !== null) {
            this.$router.push(`/store/products/digital-items/${this.$route.params.id}/view`);
            return
          }

          alert(res.data.errors[0].message);
        }).catch((err) => {
          this.isLoading = false;
          if (err.response.status === 422) {
            this.errors = err.response.data.errors.message;
          } else {
            this.errors = err.response.data.title;
          }
        });
      });
    },
  },
}
</script>

<style scoped>
@import "~simplemde/dist/simplemde.min.css";
@import "~github-markdown-css";

.content-wrapper {
  padding: 0 30px;
}

.head {
  height: 43px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-left: 15px;
}

.bg-white {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.img-nav:hover {
  cursor: pointer;
}

.form-bg {
  height: auto;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  padding: 24px;
  margin-top: 20px;
}

.form-container {
  width: 100%;
  margin-top: 24px;
}

.label {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
}

.file-added {
  border: solid 1px #dee2ed;
  background-color: #f6f8fc;
  color: #5c6366;
  padding: 10px 20px;
  border-radius: 30px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
}

.file-added:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.file-added img {
  margin: 0 4px;
}

.input-field {
  border-radius: 3px;
  border: solid 1px rgba(90, 99, 124, 0.16);
}

.input-field:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aaafbc;
  opacity: 1; /* Firefox */
}

.dropzone {
  margin-top: 20px;
  border-radius: 3px;
  border: dashed 2px rgba(90, 99, 124, 0.16);
}

.dropzone-custom-content:hover {
  cursor: pointer;
}

.dropzone-custom-title {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #b8c5cc;
}

.b-link {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #132eaa;
}

.btn-sv-draft {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5a637c;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-sv-draft:hover {
  border: solid 2px #c2d2ff;
}

.btn-sv-draft:focus {
  outline: none;
  border: solid 2px #c2d2ff;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-publish {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-publish:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }
}
</style>