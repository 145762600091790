<template>
    <div class="modal">
        <!-- Modal content -->
        <div class="modal-content">
            <div class="alert mb-0 px-2">
                <span @click="onClose" class="close ml-3" ><i class="fa fas fa-times"></i></span>
                <p class="mb-0 message">{{ errMsg }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ErrorComponent",
        props:{
            errMsg: {
                default: ''
            },
        },
        methods:{
            onClose: function () {
                this.$emit('getErrorFlag', '')
            }
        }
    }
</script>

<style scoped>
    /* The Modal (background) */
    .modal {
        display: block;
        position: fixed; /* Stay in place */
        z-index: 1099; /* Sit on top */
        padding-top: 10%; /* Location of the box */
        left: 0;
        top: 0;
        width: 100%; /* Full width */
        height: 100%; /* Full height */
        overflow: auto; /* Enable scroll if needed */
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    }
    /* Modal Content */
    .modal-content {
        background-color: #fefefe;
        margin: auto;
        padding: 10px;
        border: 1px solid #888;
        width: 300px;
    }
    /* The Close Button */
    .close {
        color: #aaaaaa;
        float: right;
        font-size: 18px;
        font-weight: bold;
    }
    .close:hover,
    .close:focus {
        color: #132eaa;
        text-decoration: none;
        cursor: pointer;
    }
    .message{
        color: #132eaa;
        font-size: 16px;
    }
</style>
