<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"
    ></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column flex-sm-row justify-content-between">
              <h5 class="title-header">
                <i class="fa fa-arrow-left" @click="$router.go(-1)"></i> Attributes of {{ productName }}
              </h5>

              <button
                  @click="addProductAttribute"
                  class="
                  btn
                  text-capitalize
                  btn-add-product
                  text-nowrap
                  mt-2 mt-sm-0
                ">
                <span class="pr-3"><i class="fas fa-plus"></i></span>
                Add Attribute
              </button>
            </div>
          </div>
        </div>

        <!--    Table starts   -->
        <div class="row">
          <div class="col-md-12">
            <div class="table-bg">
              <table class="table mb-0 text-nowrap">
                <thead class="thead-bg">
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-center">Options</th>
                  <th class="text-right"></th>
                </tr>
                </thead>

                <tbody>
                <tr
                    v-for="attr in productAttributeList"
                    :key="attr.id"
                    class="t-rows">
                  <td class="text-left">
                    {{ attr.name }}
                  </td>
                  <td class="text-center">
                    <label v-for="v in attr.values" class="bg-success mr-1 p-2" :key="v">{{ v }}</label>
                  </td>
                  <td class="text-right pr-34">
                    <button class="btn btn-option" data-toggle="dropdown">
                      <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <div class="
                          dropdown-menu dropdown-menu-right
                          p-0
                          dropdown-bg">
                      <div
                          @click="updateProductAttribute(attr)"
                          class="list d-flex justify-content-between">
                        <span>Update</span>
                        <i class="far fa-edit"></i>
                      </div>
                      <div
                          @click="onDelete(attr.id)"
                          class="list d-flex justify-content-between">
                        <span>Delete</span>
                        <i class="far fa-trash-alt"></i>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--    Table ends   -->
      </div>
    </main>
  </div>
</template>

<script>
import SideBarLiveMart from "../../../SideBarLiveMart";
import ErrorComponent from "../../../ErrorComponent";
import PleaseWait from "../../../PleaseWait";
import Header from "../../../Header";
import axios from "axios";
import Settings from "../../../../common/settings";
import ProductAttributeAdd from "./ProductAttributeAdd";
import ProductAttributeUpdate from "./ProductAttributeUpdate";

export default {
  name: "ProductAttributesView",
  components: {
    SideBarLiveMart,
    ErrorComponent,
    PleaseWait,
    Header,
  },
  data() {
    return {
      // Flags
      isLoading: false,
      errors: "",

      productName: '',
      // Data
      productAttributeList: []
    };
  },
  mounted() {
    this.getProductDetails(this.$route.params.id);
  },
  methods: {
    getProductDetails: function (id) {
      this.isLoading = true;

      let pld = {
        query: `query { productByAdmin(productId: "${id}") { name attributes { id name values isRequired createdAt updateAt } } }`
      };

      axios.post(`${Settings.GetApiUrl()}`, pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        this.isLoading = false;

        let data = resp.data.data;
        if (data === null) {
          return
        }

        this.productAttributeList = data.productByAdmin.attributes;
        this.productName = data.productByAdmin.name;
      }).catch((err) => {
        console.log(err);
        this.isLoading = false;
        this.errors = err.response.data.title;
      });
    },
    addProductAttribute: function () {
      this.$dlg.modal(ProductAttributeAdd, {
        width: 400,
        height: 300,
        title: 'Add Product Attribute',
        params: {
          productId: this.$route.params.id
        },
        maxButton: false,
        // eslint-disable-next-line no-unused-vars
        callback: data => {
          if (data.isSuccess) {
            this.getProductDetails(this.$route.params.id);
          }
        }
      });
    },
    updateProductAttribute: function (attribute) {
      this.$dlg.modal(ProductAttributeUpdate, {
        width: 400,
        height: 300,
        title: 'Update Product Attribute',
        params: {
          productId: this.$route.params.id,
          attribute: attribute,
        },
        maxButton: false,
        // eslint-disable-next-line no-unused-vars
        callback: data => {
          if (data.isSuccess) {
            this.getProductDetails(this.$route.params.id);
          }
        }
      });
    },
    reloadPage: function () {
      window.location.reload();
    },
    onDelete: function (id) {
      this.isLoading = true;

      let pld = {
        query: `mutation { productRemoveAttribute(productId: "${this.$route.params.id}", attributeId: "${id}") }`
      };

      axios.post(`${Settings.GetApiUrl()}`, pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
        // eslint-disable-next-line no-unused-vars
      }).then((resp) => {
        this.isLoading = false;

        this.getProductDetails(this.$route.params.id);
      }).catch((err) => {
        console.log(err);
        this.isLoading = false;
        this.errors = err.response.data.title;
      });
    },
  }
}
</script>

<style scoped>
.content-wrapper {
  padding: 0 30px;
}

.bg-white,
.table-bg {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-top: 5px;
}

.btn-add-product {
  border-radius: 3px;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 11px 19px;
  border: 0;
  outline: none;
}

.btn-filter {
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #132eaa;
  padding: 15px 38px;
  border-right: 1px solid #e4eaec;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.helper img {
  display: block;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

input[type="text"] {
  height: 49px;
  font-size: 14px;
  border: 0;
  padding: 0 10px;
  outline: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaafbc;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

.field {
}

.table-bg {
  margin-top: 24px;
  overflow-y: visible;
}

.thead-bg {
  background-color: #ecf1fe;
}

.pl-34 {
  padding-left: 34px;
}

.pr-34 {
  padding-right: 34px;
}

.thead-bg th {
  border: 0;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
}

.t-rows {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373b3d;
}

tbody tr td {
  vertical-align: middle;
}

.t-rows td img {
  border: solid 1px #ced9f5;
  padding: 1px;
}

.dropdown-bg {
  min-width: 132px;
  border-bottom: 0;
}

.dropdown-bg .list {
  padding: 10px 12px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
  border-bottom: solid 1px #dbe2e6;
}

.dropdown-bg .list:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

.btn-published {
  cursor: default;
  border-radius: 14px;
  background-color: #caece7;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #287166;
  padding: 6px 17px;
  border: 0;
  outline: none;
}

.btn-reject {
  background-color: #e0e0e0;
  color: #333333;
}

.btn-digital {
  background-color: #d4d3d3;
  color: #087dcb;
}

.btn-physical {
  background-color: #d4d3d3;
  color: #06796e;
}

.btn-published:focus,
.btn-add-product:focus {
  outline: none;
  border: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-option {
  border-radius: 14px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b8c5cc;
  padding: 3px 10px;
  border: 0;
  outline: none;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }

  .btn-filter {
    border-right: 0;
    border-bottom: 1px solid #e4eaec;
  }
}
</style>