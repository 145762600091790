<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"
    ></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <div class="col-md-12">
            <div class="d-flex flex-column flex-sm-row justify-content-between">
              <h5 class="title-header">Products</h5>

              <button
                  @click="onClickAddProduct"
                  class="
                  btn
                  text-capitalize
                  btn-add-product
                  text-nowrap
                  mt-2 mt-sm-0
                ">
                <span class="pr-3"><i class="fas fa-plus"></i></span>
                Add product
              </button>
            </div>
          </div>
        </div>

        <!--    Filter starts   -->
        <div class="row">
          <div class="col-md-12">
            <div class="text-nowrap d-flex flex-column flex-sm-row">
              <!--              <button class="btn btn-filter">Add Filter</button>-->

              <select class="form-control col-2" v-on:change="onSortFilterChange" style="margin-top: 20px">
                <option class="form-control" v-bind:value="productLatest">Product: Latest</option>
                <option class="form-control" v-bind:value="stockHighToLow">Stock: High - Low</option>
                <option class="form-control" v-bind:value="stockLowToHigh">Stock: Low - High</option>
              </select>

              <div class="bg-white pl-4 w-100 d-flex align-items-center">
                <input
                    v-model="searchQuery"
                    v-on:keyup.enter="onKeyPressed"
                    type="text"
                    class="w-100 field"
                    placeholder="Search for Products"
                />

                <img v-on:click="fetchProductsAsAdmin" :src="'./assets/icons/page/search.svg'" class="pr-3"/>
              </div>
            </div>
          </div>
        </div>
        <!--    Filter ends   -->

        <!--    Table starts   -->
        <div class="row">
          <div class="col-md-12">
            <div class="table-bg">
              <table class="table mb-0 text-nowrap">
                <thead class="thead-bg">
                <tr>
                  <th class="text-left">Products Name</th>
                  <th class="text-center">Stock</th>
                  <th class="text-center">Price</th>
                  <th class="text-center">Type</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Views</th>
                  <th class="text-right"></th>
                </tr>
                </thead>

                <tbody>
                <tr
                    v-for="product in productList"
                    :key="product.id"
                    class="t-rows">
                  <td class="text-left d-flex align-items-center">
                    <img v-if="product.fullImages.length>0"
                         v-bind:src="product.fullImages[0]"
                         class="rounded-circle mr-2"
                         width="34"
                         height="34"
                         alt=""
                    />
                    <img v-else
                         class="rounded-circle mr-2"
                         width="34"
                         height="34"
                         alt=""
                    />
                    {{ product.name }}
                  </td>
                  <td class="text-center">
                    {{
                      product.isDigitalProduct ? "Unlimited" : product.stock === 0 ? "Stock Out" : product.stock
                    }}
                  </td>
                  <td class="text-center">
                    {{ toDisplayUnit(product.price) }} {{
                      currency
                    }}{{ product.productUnit !== null ? `/${product.productUnit}` : '' }}
                  </td>
                  <td class="text-center text-bold">
                    <span
                        v-if="product.isDigitalProduct"
                        class="btn btn-published btn-digital">D</span>
                    <span v-else class="btn btn-published btn-physical">P</span>
                  </td>
                  <td class="text-center">
                      <span
                          v-if="product.isPublished"
                          class="btn btn-published">Published</span>
                    <span v-else class="btn btn-published btn-reject">Draft</span>
                  </td>
                  <td class="text-center">
                    {{ product.views ? product.views : 0 }}
                  </td>
                  <td class="text-right pr-34">
                    <button class="btn btn-option" data-toggle="dropdown">
                      <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <div
                        class="
                          dropdown-menu dropdown-menu-right
                          p-0
                          dropdown-bg
                        ">
                      <div
                          @click="onClickEditProduct(product.id)"
                          class="list d-flex justify-content-between">
                        <span>Edit</span>
                        <i class="fas fa-pencil-alt"></i>
                      </div>
                      <div
                          @click="onClickProductAttributes(product.id)"
                          class="list d-flex justify-content-between">
                        <span>Attributes</span>
                        <i class="fas fa-store"></i>
                      </div>
                      <div v-if="product.isDigitalProduct"
                           @click="onDigitalContentsClick(product.id)"
                           class="list d-flex justify-content-between">
                        <span>Digital Items</span>
                        <i class="fas fa-cloud"></i>
                      </div>
                      <div
                          @click="copyToClipboard(product.id)"
                          class="list d-flex justify-content-between">
                        <span>Copy ProductId&nbsp;&nbsp;</span>
                        <i class="far fa-copy"></i>
                      </div>
                      <div
                          @click="copyToClipboard(product.slug)"
                          class="list d-flex justify-content-between">
                        <span>Copy Product Slug&nbsp;&nbsp;</span>
                        <i class="far fa-copy"></i>
                      </div>
                      <div
                          @click="onProductHistory(product.id)"
                          class="list d-flex justify-content-between">
                        <span>Stock History</span>
                        <i class="far fa-clock"></i>
                      </div>
                      <div
                          @click="onProductDelete(product.id)"
                          class="list d-flex justify-content-between">
                        <span>Delete</span>
                        <i class="far fa-trash-alt"></i>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--    Table ends   -->

        <div class="my-5">
          <BPagination
              align="center"
              :limit="10"
              :hide-goto-end-buttons="true"
              v-model="currentPage"
              v-on:change="onPaginationChange"
              :total-rows="rows"
              :per-page="perPage">
          </BPagination>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import {BPagination} from "bootstrap-vue";

import Header from "../../../components/Header";
import Settings from "../../../common/settings";
import PleaseWait from "../../PleaseWait";
import ErrorComponent from "../../ErrorComponent";
import SideBarLiveMart from "../../SideBarLiveMart";
import SessionStore from "../../../common/session_store";

export default {
  name: "ProductsView",
  components: {
    SideBarLiveMart,
    ErrorComponent,
    PleaseWait,
    Header,
    BPagination,
  },
  data() {
    return {
      // Flags
      isLoading: false,
      errors: "",

      // Variables
      rows: 10000000,
      currentPage: null,
      perPage: 25,
      searchQuery: '',

      // Data
      productList: [],

      currency: '',

      isSearchRequested: false,

      stockHighToLow: "stock_high_to_low",
      stockLowToHigh: "stock_low_to_high",
      productLatest: "product_latest",
      selectedSort: `{by: CreatedAt, direction: Desc}`
    };
  },
  mounted() {
    this.currency = Settings.GetStoreCurrency();
    this.currentPage = SessionStore.getCurrentPage('products');
    this.isSearchRequested = true;
    this.fetchProductsAsAdmin();
  },
  methods: {
    onPaginationChange: function (p) {
      console.log(`Page: ${p}`);
      this.currentPage = p;
      this.fetchProductsAsAdmin()
    },
    onDigitalContentsClick: function (id) {
      this.$router.push(`/store/products/digital-items/${id}/view`);
    },
    onKeyPressed: function () {
      this.isSearchRequested = true;
      this.fetchProductsAsAdmin();
    },
    fetchProductsAsAdmin: function () {
      if (!this.isSearchRequested) {
        return
      }

      this.isLoading = true;

      console.log(`Query: ${SessionStore.getQuery('products') !== this.searchQuery}`);

      if (SessionStore.getQuery('products') !== this.searchQuery) {
        this.currentPage = 1;
        SessionStore.setQuery('products', this.searchQuery);
      }

      let payload = {
        query: `
        { productSearchByAdmin(search: { query: "${this.searchQuery.toLowerCase()}", filters: [] }, sort: ${this.selectedSort}, pagination: { perPage: ${this.perPage} page: ${this.currentPage} }){
            id
            name
            slug
            description
            isPublished
            sku
            stock
            maxItemPerOrder
            price
            cost
            images
            fullImages
            isDigitalProduct
            productUnit
            views
            createdAt
            updatedAt
        }}`,
      };

      axios.post(Settings.GetApiUrl(), payload, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        this.isLoading = false;
        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
            return;
          }

          this.productList = rs.productSearchByAdmin;
          SessionStore.setCurrentPage('products', this.currentPage);
        }
      }).catch((err) => {
        this.isLoading = false;
        if (err.errors === undefined) {
          console.log(err);
          return
        }
        this.errors = err.errors[0].message
      });
    },
    getFullImagePath(subPath) {
      return Settings.GetMediaUrl() + subPath;
    },
    onProductDelete: function (id) {
      this.isLoading = true;

      let pld = {
        query: `mutation { deleteProduct(productId: "` + id + `") }`
      };

      axios.post(`${Settings.GetApiUrl()}`, pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then(() => {
        this.isLoading = false;
        window.location.reload();
      }).catch((err) => {
        this.isLoading = false;
        console.log(err);
      });
    },
    onProductHistory: function (id) {
      window.open(`/#/store/products/history/${id}`, '_blank')
    },
    onClickEditProduct: function (id) {
      window.open(`/#/store/products/update/${id}`, '_blank')
    },
    onClickProductAttributes: function (id) {
      window.open(`/#/store/products/attributes/${id}`, '_blank')
    },
    onClickAddProduct: function () {
      this.$router.push(`/store/products/add`);
    },
    toDisplayUnit: function (v) {
      return (v / 100).toFixed(2);
    },
    copyToClipboard: function (v) {
      let container = this.$refs.container
      this.$copyText(v, container)
    },
    onSortFilterChange: function (e) {
      const v = e.target.value;
      switch (v) {
        case this.stockHighToLow:
          this.selectedSort = `{by: Stock, direction: Desc}`
          break
        case this.stockLowToHigh:
          this.selectedSort = `{by: Stock, direction: Asc}`
          break
        default:
          this.selectedSort = `{by: CreatedAt, direction: Desc}`
      }

      this.fetchProductsAsAdmin()
    }
  },
};
</script>

<style scoped>
.content-wrapper {
  padding: 0 30px;
}

.bg-white,
.table-bg {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-top: 5px;
}

.btn-add-product {
  border-radius: 3px;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 11px 19px;
  border: 0;
  outline: none;
}

.btn-filter {
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #132eaa;
  padding: 15px 38px;
  border-right: 1px solid #e4eaec;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.helper img {
  display: block;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

input[type="text"] {
  height: 49px;
  font-size: 14px;
  border: 0;
  padding: 0 10px;
  outline: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaafbc;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

.field {
}

.table-bg {
  margin-top: 24px;
  overflow-y: visible;
}

.thead-bg {
  background-color: #ecf1fe;
}

.pl-34 {
  padding-left: 34px;
}

.pr-34 {
  padding-right: 34px;
}

.thead-bg th {
  border: 0;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
}

.t-rows {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373b3d;
}

tbody tr td {
  vertical-align: middle;
}

.t-rows td img {
  border: solid 1px #ced9f5;
  padding: 1px;
}

.dropdown-bg {
  min-width: 132px;
  border-bottom: 0;
}

.dropdown-bg .list {
  padding: 10px 12px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
  border-bottom: solid 1px #dbe2e6;
}

.dropdown-bg .list:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

.btn-published {
  cursor: default;
  border-radius: 14px;
  background-color: #caece7;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #287166;
  padding: 6px 17px;
  border: 0;
  outline: none;
}

.btn-reject {
  background-color: #e0e0e0;
  color: #333333;
}

.btn-digital {
  background-color: #d4d3d3;
  color: #087dcb;
}

.btn-physical {
  background-color: #d4d3d3;
  color: #06796e;
}

.btn-published:focus,
.btn-add-product:focus {
  outline: none;
  border: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-option {
  border-radius: 14px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b8c5cc;
  padding: 3px 10px;
  border: 0;
  outline: none;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }

  .btn-filter {
    border-right: 0;
    border-bottom: 1px solid #e4eaec;
  }
}
</style>
