class UrlPath {
    static SetPath(path) {
        localStorage.setItem('root_path', path)
    }

    static GetPath() {
        return localStorage.getItem('root_path')
    }
}

export default UrlPath;
