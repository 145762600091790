var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PleaseWait',{attrs:{"isLoading":_vm.isLoading}}),(_vm.errors !== '')?_c('ErrorComponent',{attrs:{"err-msg":_vm.errors},on:{"getErrorFlag":function (ev) {
        this$1.errors = ev;
      }}}):_vm._e(),_c('SideBarLiveMart'),_c('main',[_c('Header'),_c('div',{staticClass:"content-wrapper mt-2 mb-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-8 mb-5"},[_c('div',{staticClass:"row head"},[_c('div',{staticClass:"col-md-12 d-flex align-items-center"},[_c('i',{staticClass:"fas fa-chevron-left img-nav",on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('h5',{staticClass:"title-header"},[_vm._v("Pages")])])]),_c('div',{staticClass:"form-bg"},[_c('h5',{staticClass:"title-header pl-0"},[_vm._v("Add Page")]),_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"pname"}},[_vm._v("Title")]),_c('ValidationProvider',{attrs:{"rules":"required|minlen:1","bails":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control input-field",attrs:{"type":"text","id":"pname","placeholder":"Page title"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_vm._l((errors),function(error){return _c('li',{key:error,staticClass:"label text-danger"},[_vm._v("\n                      "+_vm._s(error)+"\n                    ")])})]}}])})],1)]),_c('div',{staticClass:"form-container"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label",attrs:{"for":"pname"}},[_vm._v("Slug")]),_c('ValidationProvider',{attrs:{"bails":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.slug),expression:"slug"}],staticClass:"form-control input-field",attrs:{"type":"text","id":"pname","placeholder":"Page slug"},domProps:{"value":(_vm.slug)},on:{"input":function($event){if($event.target.composing){ return; }_vm.slug=$event.target.value}}}),_vm._l((errors),function(error){return _c('li',{key:error,staticClass:"label text-danger"},[_vm._v("\n                      "+_vm._s(error)+"\n                    ")])})]}}])})],1)]),_c('div',{staticClass:"form-container mt-3"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"label"},[_vm._v("Page content")]),_c('ValidationProvider',{attrs:{"rules":"required|minlen:1","bails":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('vue-simplemde',{attrs:{"preview-class":"markdown-body"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_vm._l((errors),function(error){return _c('li',{key:error,staticClass:"label text-danger"},[_vm._v("\n                      "+_vm._s(error)+"\n                    ")])})]}}])})],1)])])],1)]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"row text-nowrap"},[_c('div',{staticClass:"col-md-6 col-lg-6 text-center"},[_c('button',{staticClass:"btn text-capitalize btn-sv-draft w-100",on:{"click":function($event){return _vm.onCreateNewPage(false)}}},[_vm._v("\n                Save Draft\n              ")])]),_c('div',{staticClass:"col-md-6 col-lg-6 text-center mt-2 mt-md-0"},[_c('button',{staticClass:"btn text-capitalize btn-publish w-100",on:{"click":function($event){return _vm.onCreateNewPage(true)}}},[_vm._v("\n                Publish\n              ")])])])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }