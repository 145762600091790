<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent v-if="errors!==''" v-on:getErrorFlag="(ev) => {this.errors = ev}"
                    :err-msg="errors"></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <div class="col-md-12 text-nowrap">
            <div class="d-flex flex-column flex-sm-row justify-content-between">
              <h5 class="title-header">Customers</h5>

              <button
                  @click="addCustomer"
                  class="btn text-capitalize btn-add-product mt-2 mt-sm-0"
              >
                <span class="pr-3"><i class="fas fa-plus"></i></span>
                Add Customer
              </button>
            </div>
          </div>
        </div>

        <!--    Filter starts   -->
        <div class="row">
          <div class="col-md-12">
            <div class="bg-white text-nowrap d-flex flex-column flex-sm-row">
              <button class="btn btn-filter">Add Filter</button>

              <div class="pl-4 w-100 d-flex align-items-center">
                <img :src="'./assets/icons/page/search.svg'">

                <input v-model="searchQuery"
                       @keyup="getCustomerList"
                       type="text" class="w-100  field" placeholder="Search customers by email">
              </div>
            </div>
          </div>
        </div>
        <!--    Filter ends   -->

        <!--    Table starts   -->
        <div class="row">
          <div class="col-md-12">
            <div class="table-bg">
              <table class="table mb-0 text-nowrap">
                <thead class="thead-bg">
                <tr>
                  <th class="text-left">Customer Name</th>
                  <th class="text-left">Email</th>
                  <th class="text-center">Due</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="customer in customerList" :key="customer.id" class="t-rows">
                  <td class="text-left">{{ customer.firstName }} {{ customer.lastName }}</td>
                  <td class="text-left">{{ customer.email }}</td>
                  <td class="text-center">{{ toDisplayUnit(customer.due) }} {{ currency }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--    Table ends   -->

        <div class="my-5">
          <BPagination
              align="center"
              :limit="3"
              :hide-goto-end-buttons="true"
              @input="getCustomerList"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage">
          </BPagination>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from 'axios';
import {BPagination} from 'bootstrap-vue'

import Header from "../components/Header";
import PleaseWait from "./PleaseWait";
import ErrorComponent from "./ErrorComponent";
import Settings from "../common/settings";
import SideBarLiveMart from "./SideBarLiveMart";
import AddCustomer from "./customers/AddCustomer";

export default {
  name: "CustomersView",
  components: {SideBarLiveMart, ErrorComponent, PleaseWait, Header, BPagination},
  data() {
    return {
      // Flags
      isLoading: false,
      errors: '',

      // Variables
      rows: 100000000,
      currentPage: 1,
      perPage: 25,
      searchQuery: '',

      // Data
      customerList: [],

      currency: ''
    }
  },
  mounted() {
    this.currency = Settings.GetStoreCurrency();
    this.getCustomerList();
  },
  methods: {
    addCustomer: function () {
      this.$dlg.modal(AddCustomer, {
        width: 350,
        height: 300,
        title: 'Add Customer',
        params: {},
        maxButton: false,
        // eslint-disable-next-line no-unused-vars
        callback: data => {
          if (data.isSuccess) {
            window.location.reload();
          }
        }
      });
    },
    getCustomerList: function () {
      this.isLoading = true;

      let emailQuery = ``;
      if (this.searchQuery !== null && this.searchQuery !== '') {
        emailQuery = `{ key: email value: "${this.searchQuery}" }`
      }

      let pld = {
        query: `query { customersByAdmin(params: { query: "" filters: [ ${emailQuery} ] }, sort: { by: CreatedAt direction: Desc }, pagination: { perPage: 25 page: ${this.currentPage} }) { id email phone firstName lastName profilePicture due } }`
      };

      axios.post(`${Settings.GetApiUrl()}`, pld, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret()
        }
      }).then(resp => {
        this.isLoading = false;

        if (resp.data.data === null) {
          return
        }

        this.customerList = resp.data.data.customersByAdmin;
      }).catch(err => {
        this.isLoading = false;
        this.errors = err.response.data.title;
      })
    },
    toDisplayUnit(v) {
      return (v / 100).toFixed(2);
    },
  },
}
</script>

<style scoped>
.content-wrapper {
  padding: 0 30px;
}

.bg-white,
.table-bg {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-top: 5px;
}

.btn-filter {
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #132eaa;
  padding: 15px 38px;
  border-right: 1px solid #e4eaec;
}

input[type=text] {
  height: 49px;
  font-size: 14px;
  border: 0;
  padding: 0 10px;
  outline: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaafbc;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

.table-bg {
  margin-top: 24px;
  overflow-y: visible;
}

.thead-bg {
  background-color: #ecf1fe;
}

.pl-34 {
  padding-left: 34px;
}

.thead-bg th {
  border: 0;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
}

.t-rows {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373b3d;
}

tbody tr td {
  vertical-align: middle;
}

.btn-confirmed,
.btn-pending,
.btn-reject,
.btn-view-details {
  border-radius: 14px;
  background-color: #caece7;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #287166;
  padding: 6px 16px;
  border: 0;
  outline: none;
}

.btn-pending {
  background-color: #fae5bd;
  color: #8e620b;
}

.btn-reject {
  background-color: #fac7bd;
  color: #8e210b;
}

.btn-view-details {
  border: solid 1px #c2d2ff;
  background-color: #ffffff;
  color: #132eaa;
}

.btn-confirmed:focus,
.btn-reject:focus,
.btn-pending:focus,
.btn-view-details:focus {
  outline: none;
  border: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-add-product {
  border-radius: 3px;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 11px 19px;
  border: 0;
  outline: none;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }

  .btn-filter {
    border-right: 0;
    border-bottom: 1px solid #e4eaec;
  }
}
</style>
