<template>
  <div class="card">
    <div v-if="customer" class="card-body overflow-auto">
      <div class="row">
        <div class="col">
          <h6><span class="text-primary">Customer Name:</span> {{ customer.firstName }} {{ customer.lastName }} </h6>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <h6><span class="text-primary">Customer Email:</span> {{ customer.email }}</h6>
        </div>
      </div>
    </div>
    <hr>
    <div class="card-body overflow-auto mt-1">
      <div class="row text-capitalize">
        <div class="col">
          <h6><span class="text-info">Subtotal:</span> {{ toDisplayUnit(subtotal) }} {{ currency }}</h6>
        </div>
      </div>
      <div class="row text-capitalize mt-1">
        <div class="col">
          <h6><span class="text-info">Shipping Charge:</span> {{ toDisplayUnit(shippingCharge) }} {{ currency }}</h6>
        </div>
      </div>
      <div class="row text-capitalize mt-1">
        <div class="col">
          <h6><span class="text-info">Payment Fee:</span> {{ toDisplayUnit(paymentProcessingFee) }} {{ currency }}</h6>
        </div>
      </div>
      <div class="row text-capitalize mt-1">
        <div class="col">
          <h6><span class="text-info">Discount:</span> {{ toDisplayUnit(discount) }} {{ currency }}</h6>
        </div>
      </div>
      <div class="row text-capitalize mt-3">
        <div class="col">
          <h5 class="card-title title-hover price">Grand total: {{ toDisplayUnit(grandTotal) }} {{ currency }}</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Settings from "../../common/settings";

export default {
  name: "NewOrderSummary",
  props: {
    grandTotal: {
      default: 0,
    },
    subtotal: {
      default: 0,
    },
    shippingCharge: {
      default: 0,
    },
    paymentProcessingFee: {
      default: 0,
    },
    discount: {
      default: 0,
    },
    customer: {
      default: null
    }
  },
  data() {
    return {
      currency: ''
    }
  },
  mounted() {
    this.currency = Settings.GetStoreCurrency();
  },
  methods: {
    toDisplayUnit(v) {
      return (v / 100).toFixed(2);
    },
  }
};
</script>

<style scoped>
.card:hover {
  box-shadow: -2px -3px 8px 0 rgba(255, 255, 255, 0.5),
  2px 3px 8px 0 rgba(209, 205, 199, 0.5);
  transition: 0.32s;
}

.price {
  border-radius: 3px;
  background-color: #ecf1fe;
  padding: 7px 22px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3f71f4;
}

.img-container {
  position: relative;
  width: auto;
  height: auto;
  background-color: rgba(6, 31, 96, 0.84);
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: 232px;
  transition: 0.5s ease;
  backface-visibility: hidden;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: auto;
}

@media screen and (max-width: 767px) {
  .image {
    height: 338px;
  }
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

/*.card:hover .image {
        opacity: 0.3;
    }*/

.card:hover .middle {
  opacity: 1;
}

.btn-addtocart {
  color: white;
  font-size: 14px;
  background-color: #3f71f4;
  padding: 9px 28px;
  border-radius: 3px;
}

.btn-addtocart:hover {
  color: white;
  font-size: 14px;
  background-color: #0e4df1;
  padding: 9px 28px;
  border-radius: 3px;
}

.btn-addtocart:active {
  background: rgba(52, 87, 244, 1);
  border-color: rgba(52, 87, 244, 0.23);
  box-shadow: 0 0 0 0.2rem rgba(27, 88, 255, 0.5);
}

.btn-added {
  color: white;
  font-size: 14px;
  background-color: #6b7694;
  padding: 9px 28px;
  border-radius: 3px;
  cursor: default;
}

.btn-view {
  color: white;
  font-size: 14px;
  border-radius: 3px;
  border: solid 1px #ffffff;
  padding: 9px 49px;
}

.btn-view:active {
  background: rgba(243, 239, 244, 0.33);
  border-color: rgba(255, 255, 255, 0.74);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.title-hover {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
