<template>
  <div class="card">
    <div class="img-container">
      <img
          class="card-img-top image"
          :src="getFullImagePath(coverImage)"
          :alt="title"
      />
    </div>

    <div class="card-body overflow-auto">
      <div class="row text-capitalize">
        <div v-if="isCategory" class="col">
          <h5 class="card-title title-hover">{{ title }}</h5>
        </div>
        <div v-if="!isCategory" class="col-md-7 col-sm-7 col-7">
          <h5 class="card-title title-hover">{{ title }}</h5>
          <!--<small class="text-muted">UI Kits</small>-->
        </div>
        <div
            v-if="!isCategory && !isCollections && price"
            class="col-md-5 col-sm-5 col-5">
          <div class="text-right">
            <span class="price">{{ currency }}{{ price }}{{ productUnit !== null ? `/${productUnit}` : '' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import Settings from "../../../common/settings";

export default {
  name: "CardComponent",
  props: {
    isCategory: {
      default: false,
    },
    isCollections: {
      default: false,
    },
    coverImage: {
      default: 'https://i.picsum.photos/id/1005/5760/3840.jpg'
    },
    title: "",
    price: {
      default: "",
    },
    productUnit: {
      default: null
    }
  },
  data() {
    return {
      currency: ''
    }
  },
  mounted() {
    this.currency = Settings.GetStoreCurrencySign();
  },
  methods: {
    getFullImagePath(subPath) {
      if (subPath.startsWith('http')) {
        return subPath;
      }
      return Settings.GetMediaUrl() + subPath;
    },
  },
};
</script>

<style scoped>
.card:hover {
  box-shadow: -2px -3px 8px 0 rgba(255, 255, 255, 0.5),
  2px 3px 8px 0 rgba(209, 205, 199, 0.5);
  transition: 0.32s;
}

.price {
  border-radius: 3px;
  background-color: #ecf1fe;
  padding: 7px 22px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #3f71f4;
}

.img-container {
  position: relative;
  width: auto;
  height: auto;
  background-color: rgba(6, 31, 96, 0.84);
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: 232px;
  transition: 0.5s ease;
  backface-visibility: hidden;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: auto;
}

@media screen and (max-width: 767px) {
  .image {
    height: 338px;
  }
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

/*.card:hover .image {
        opacity: 0.3;
    }*/

.card:hover .middle {
  opacity: 1;
}

.btn-addtocart {
  color: white;
  font-size: 14px;
  background-color: #3f71f4;
  padding: 9px 28px;
  border-radius: 3px;
}

.btn-addtocart:hover {
  color: white;
  font-size: 14px;
  background-color: #0e4df1;
  padding: 9px 28px;
  border-radius: 3px;
}

.btn-addtocart:active {
  background: rgba(52, 87, 244, 1);
  border-color: rgba(52, 87, 244, 0.23);
  box-shadow: 0 0 0 0.2rem rgba(27, 88, 255, 0.5);
}

.btn-added {
  color: white;
  font-size: 14px;
  background-color: #6b7694;
  padding: 9px 28px;
  border-radius: 3px;
  cursor: default;
}

.btn-view {
  color: white;
  font-size: 14px;
  border-radius: 3px;
  border: solid 1px #ffffff;
  padding: 9px 49px;
}

.btn-view:active {
  background: rgba(243, 239, 244, 0.33);
  border-color: rgba(255, 255, 255, 0.74);
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.title-hover {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
