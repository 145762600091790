<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"
    ></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>

      <div v-if="order!==undefined" class="content-wrapper mt-2">
        <div class="row">
          <div class="col-12">
            <!--    Head start     -->
            <div class="row head">
              <div class="col-md-12 d-flex align-items-center">
                <i
                    @click="$router.go(-1)"
                    class="fas fa-chevron-left img-nav"
                ></i>
                <h5 class="title-header">Order Details</h5>
              </div>
            </div>
            <!--    Head end     -->
          </div>

          <!--    Left side start     -->
          <div class="col-md-8 mb-5">
            <div class="bg-white p-24">
              <h5 class="title-header pl-0 mb-3">General Information</h5>
              <div class="information-container text-nowrap overflow-auto">
                <p class="d-flex justify-content-between">
                  <span class="pr-2">ID</span>
                  <span class="pl-3">{{ order.id }}</span>
                </p>
                <p class="d-flex justify-content-between">
                  <span class="pr-2">Hash</span>
                  <span class="pl-3">{{ order.hash }}</span>
                </p>
                <p class="d-flex justify-content-between">
                  <span>Ordered At</span>
                  <span class="pl-3">{{ formatDate(order.createdAt) }}</span>
                </p>
                <p class="d-flex justify-content-between">
                  <span>Order Status</span>
                  <span class="tag text-capitalize"
                        :class="{
                      'tag-lending': order.status === 'Lending',
                      'tag-rejected': order.status === 'Cancelled',
                      'tag-returned': order.status === 'Returned',
                      'tag-completed': order.status === 'Completed',
                      'tag-delivered': order.status === 'Delivered',
                      'tag-shipping': order.status === 'Shipping',
                      'tag-processing': order.status === 'Processing',
                      'tag-pending': order.status === 'Pending'
                    }">
                    {{ order.status }}
                  </span>
                </p>
                <p class="d-flex justify-content-between">
                  <span>Sales Channel</span>
                  <span class="pl-3">{{ order.salesChannel }}</span>
                </p>
              </div>
            </div>

            <!--    Ordered list start  -->
            <div class="form-bg p-0">
              <!--    Table starts   -->
              <div class="row">
                <div class="col-md-12">
                  <div class="table-bg">
                    <div class="table-responsive">
                      <table class="table mb-0 text-nowrap">
                        <thead class="thead-bg">
                        <tr>
                          <th class="text-left pl-24">Product Name</th>
                          <th class="text-center">Quantity</th>
                          <th class="text-center">Price</th>
                          <th class="text-right pr-24">Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="item in order.cart.cartItems"
                            :key="item.id"
                            class="t-rows">
                          <td class="pl-24 text-left">
                            <img v-if="item.product.fullImages.length>0"
                                 :src="item.product.fullImages[0]"
                                 class="rounded-circle mr-2"
                                 width="34"
                                 height="34"
                                 alt=""
                            />
                            <img v-else
                                 class="rounded-circle mr-2"
                                 width="34"
                                 height="34"
                                 alt=""
                            />
                            {{ item.product.name }}

                            <div class="row pt-1">
                              <span class="text-primary pl-1 pr-1 mr-1 border-left border-danger"
                                    v-for="attr in item.attributes"
                                    :key="attr.id">{{
                                  attr.name
                                }}: {{ attr.selectedValue }}</span>
                            </div>
                          </td>
                          <td class="text-center">{{ item.quantity }}</td>
                          <td class="text-center">
                            {{ toDisplayUnit(item.purchasePrice) }} {{
                              currency
                            }}{{ item.product.productUnit !== null ? `/${item.product.productUnit}` : '' }}
                          </td>
                          <td class="text-right pr-24">
                            {{ toDisplayUnit(item.purchasePrice * item.quantity) }} {{ currency }}
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!--    Table ends   -->
            </div>
            <!--    Ordered list end  -->

            <div class="form-bg p-0">
              <div class="container-info-more">
                <!-- Nav pills -->
                <ul class="nav pl-24 pr-24 py-2 flex-nowrap overflow-auto"
                    role="tablist">
                  <li class="lItem">
                    <a class="active" data-toggle="pill" href="#payment">Payment</a>
                  </li>
                  <li class="lItem">
                    <a data-toggle="pill" href="#billing">Billing</a>
                  </li>
                  <li class="lItem">
                    <a data-toggle="pill" href="#customer">Customer</a>
                  </li>
                </ul>

                <!-- Tab panes -->
                <div class="tab-content p-24">
                  <div id="payment" class="tab-pane active">
                    <p class="pill-title mb-3">Payments</p>

                    <div class="table-responsive">
                      <table class="table mb-0 text-nowrap">
                        <thead class="pill-thead text-uppercase">
                        <tr>
                          <th>Gateway</th>
                          <th class="text-center">Status</th>
                          <th></th>
                          <th class="text-right">Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in order.payments"
                            :key="item.id"
                            class="pill-trow">
                          <td>{{ item.gatewayName }}</td>
                          <td v-if="item.isPaid" class="text-center">PAID</td>
                          <td v-else-if="!item.isPaid" class="text-center">UNPAID</td>
                          <td></td>
                          <td class="text-right">
                            {{ toDisplayUnit(item.payableAmount) }} {{ currency }}
                          </td>
                        </tr>

                        <br/><br/>

                        <p class="pill-title mb-3">Payment details</p>

                        <tr class="pill-trow line-hr">
                          <td></td>
                          <td class="text-center"></td>
                          <td class="text-right">Subtotal:</td>
                          <td class="text-right">
                            {{ toDisplayUnit(order.subtotal) }} {{ currency }}
                          </td>
                        </tr>
                        <tr v-if="order.discountedAmount" class="pill-trow">
                          <td></td>
                          <td></td>
                          <td class="text-right">
                            Discount{{ order.couponCode !== null ? ' (' + order.couponCode.code + ')' : '' }}:
                          </td>
                          <td class="text-right">
                            {{ toDisplayUnit(order.discountedAmount) }} {{ currency }}
                          </td>
                        </tr>
                        <tr v-if="order.paymentProcessingFee" class="pill-trow">
                          <td></td>
                          <td></td>
                          <td class="text-right">Payment processing free:</td>
                          <td class="text-right">
                            {{ toDisplayUnit(order.paymentProcessingFee) }} {{ currency }}
                          </td>
                        </tr>
                        <tr v-if="order.shippingCharge" class="pill-trow">
                          <td></td>
                          <td></td>
                          <td class="text-right">Shipping charge:</td>
                          <td class="text-right">
                            {{ toDisplayUnit(order.shippingCharge) }} {{ currency }}
                          </td>
                        </tr>
                        <tr class="pill-trow line-yhr">
                          <td></td>
                          <td></td>
                          <td class="text-right">Total:</td>
                          <td class="text-right price-total">
                            {{ toDisplayUnit(order.grandTotal) }} {{ currency }}
                          </td>
                        </tr>
                        </tbody>
                      </table>

                      <div class="payment-info-container py-3 text-nowrap">
                        <p v-if="order.paymentMethod!=null" class="d-flex justify-content-between">
                          <span class="pr-2">Payment Method</span>
                          <span class="pl-3">{{ order.paymentMethod.displayName }} ({{
                              order.paymentMethod.currencyName
                            }})</span>
                        </p>
                        <p class="d-flex justify-content-between">
                          <span class="pr-2">Payment Status</span>
                          <span
                              class="tag text-capitalize ml-3"
                              :class="{
                              'tag-delivered':
                                order.paymentStatus === 'Paid',
                              'tag-reject':
                                order.paymentStatus === 'Failed',
                              'tag-shipping':
                                order.paymentStatus === 'Refunded',
                              'tag-pending':
                                order.paymentStatus === 'Pending',
                            }">
                            {{ order.paymentStatus }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div id="billing" class="tab-pane fade">
                    <p class="pill-title mb-3">Billing Information</p>
                    <div class="billing-info-container overflow-auto">
                      <p class="d-flex justify-content-between">
                        <span class="pr-2">Street:</span>
                        <span>{{ order.billingAddress.street }}</span>
                      </p>
                      <p class="d-flex justify-content-between">
                        <span class="pr-2">Street optional:</span>
                        <span>{{ order.billingAddress.streetTwo }}</span>
                      </p>
                      <p class="d-flex justify-content-between">
                        <span class="pr-2">City:</span>
                        <span>{{ order.billingAddress.city }}</span>
                      </p>
                      <p class="d-flex justify-content-between">
                        <span class="pr-2">State:</span>
                        <span>{{ order.billingAddress.state }}</span>
                      </p>
                      <p class="d-flex justify-content-between">
                        <span class="pr-2">Postcode:</span>
                        <span>{{ order.billingAddress.postcode }}</span>
                      </p>
                      <p class="d-flex justify-content-between">
                        <span class="pr-2">Country:</span>
                        <span>{{ order.billingAddress.location.name }}</span>
                      </p>
                      <p class="d-flex justify-content-between">
                        <span class="pr-2">Phone:</span>
                        <span>{{ order.billingAddress.phone }}</span>
                      </p>
                      <p class="d-flex justify-content-between">
                        <span class="pr-2">Email:</span>
                        <span>{{ order.billingAddress.email }}</span>
                      </p>
                    </div>
                  </div>

                  <div id="customer" class="tab-pane fade">
                    <p class="pill-title mb-3">Customer Information</p>

                    <div class="billing-info-container text-nowrap">
                      <p class="d-flex justify-content-between">
                        <span class="pr-2">Name:</span>
                        <span class="pl-3">{{ order.customer.firstName }} {{ order.customer.lastName }}</span>
                      </p>
                      <p class="d-flex justify-content-between mb-0">
                        <span class="pr-2">Email:</span>
                        <span class="pl-3">{{ order.customer.email }}</span>
                      </p>
                      <p v-if="order.customer.phone!==null" class="d-flex justify-content-between mb-0">
                        <span class="pr-2">Phone:</span>
                        <span class="pl-3">{{ order.customer.phone }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--    Left side end     -->

          <!--    Right side start     -->
          <div class="col-md-4">
            <div class="col-md-4">
              <div class="dropdown">
                <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                  Options
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <button class="dropdown-item" type="button" v-on:click="showUpdateStatusModal">Update Order Status
                  </button>
                  <button class="dropdown-item" type="button" v-on:click="showUpdatePaymentStatusModal">Update Payment
                    Status
                  </button>
                  <button class="dropdown-item" type="button" v-on:click="onGenerateInvoice">Generate Invoice</button>
                </div>
              </div>
            </div>

            <div class="form-bg">
              <h5 class="title-header pl-0 mb-3">Order History</h5>
              <div class="order-history-container">
                <ul class="list-unstyled text-nowrap overflow-auto">
                  <li class="d-flex justify-content-between">
                    <span class="pr-2">Order was placed {{ order.createdAt }}</span>
                  </li>
                </ul>
              </div>
            </div>

            <div v-if="order.shippingAddress" class="form-bg">
              <h5 class="title-header pl-0 mb-3">Shipping Information</h5>
              <div class="info overflow-auto">
                <p class="d-flex justify-content-between">
                  <span class="pr-2 text-info">Street:</span>
                  <span>{{ order.shippingAddress.street }}</span>
                </p>
                <p class="d-flex justify-content-between">
                  <span class="pr-2 text-info">Street optional:</span>
                  <span>{{ order.shippingAddress.streetTwo }}</span>
                </p>
                <p class="d-flex justify-content-between">
                  <span class="pr-2 text-info">City:</span>
                  <span>{{ order.shippingAddress.city }}</span>
                </p>
                <p class="d-flex justify-content-between">
                  <span class="pr-2 text-info">State:</span>
                  <span>{{ order.shippingAddress.state }}</span>
                </p>
                <p class="d-flex justify-content-between">
                  <span class="pr-2 text-info">Postcode:</span>
                  <span>{{ order.shippingAddress.postcode }}</span>
                </p>
                <p class="d-flex justify-content-between">
                  <span class="pr-2 text-info">Country:</span>
                  <span>{{ order.shippingAddress.location.name }}</span>
                </p>
                <p class="d-flex justify-content-between">
                  <span class="pr-2 text-info">Phone:</span>
                  <span>{{ order.shippingAddress.phone }}</span>
                </p>
                <p class="d-flex justify-content-between">
                  <span class="pr-2 text-info">Email:</span>
                  <span>{{ order.shippingAddress.email }}</span>
                </p>
                <p class="d-flex justify-content-between">
                  <span class="pr-2">-----</span>
                </p>
                <p class="d-flex justify-content-between">
                  <span class="pr-2 text-info">Shipping Method:</span>
                  <span>{{ order.shippingMethod.displayName }}</span>
                </p>
                <p class="d-flex justify-content-between">
                  <span class="pr-2 text-info">Estimated Shipping Time:</span>
                  <span>{{ order.shippingMethod.deliveryTimeInDays }} days</span>
                </p>
              </div>
            </div>
          </div>
          <!--    Right side end     -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";

import Header from "../../components/Header";
import PleaseWait from "../PleaseWait";
import ErrorComponent from "../ErrorComponent";
import Settings from "../../common/settings";
import moment from "moment";
import SideBarLiveMart from "../SideBarLiveMart";
import UpdateOrderStatus from "@/components/orders/UpdateOrderStatus";
import UpdatePaymentStatus from "@/components/orders/UpdatePaymentStatus";
import Helpers from "../../helpers/helpers";

export default {
  name: "OrderDetailsView",
  components: {SideBarLiveMart, ErrorComponent, PleaseWait, Header},
  data() {
    return {
      // Flags
      isLoading: false,
      errors: "",

      // Data
      order: undefined,
      currency: ''
    };
  },
  mounted() {
    this.currency = Settings.GetStoreCurrency();
    this.getProductDetails(this.$route.params.id);
  },
  methods: {
    formatDate: function (v) {
      return Helpers.ParseDate(v)
    },
    showUpdateStatusModal: function () {
      this.$dlg.modal(UpdateOrderStatus, {
        width: 350,
        height: 200,
        title: 'Update Order Status',
        params: {
          orderId: this.order.id
        },
        maxButton: false,
        // eslint-disable-next-line no-unused-vars
        callback: data => {
          if (data.isSuccess) {
            this.reloadPage();
          }
        }
      });
    },
    showUpdatePaymentStatusModal: function () {
      this.$dlg.modal(UpdatePaymentStatus, {
        width: 350,
        height: 200,
        title: 'Update Order Payment Status',
        params: {
          orderId: this.order.id
        },
        maxButton: false,
        // eslint-disable-next-line no-unused-vars
        callback: data => {
          if (data.isSuccess) {
            this.reloadPage();
          }
        }
      });
    },
    reloadPage: function () {
      window.location.reload();
    },
    getProductDetails: function (id) {
      this.isLoading = true;
      let payload = {
        query: `query {   orderByAdmin(id: "` + id + `") {     id     hash     shippingCharge     paymentProcessingFee     subtotal     grandTotal salesChannel     discountedAmount     status     paymentStatus     createdAt     updatedAt     billingAddress {       street       streetTwo       city       state       postcode       email       phone       location {         name         shortCode       }     }     shippingAddress {       street       streetTwo       city       state       postcode       email       phone       location {         name         shortCode       }     }     cart {       isShippingRequired       cartItems {         id         product {           id           name           slug           description productUnit           isPublished           sku           stock           maxItemPerOrder images fullImages         }         quantity         purchasePrice         purchaseTimeCost         attributes {           name           selectedValue         }         variation {           id           name           price           cost           sku           stock         }       }     }     customer {       email       phone       firstName       lastName       profilePicture     }     shop {       name       logo     }     paymentMethod {       id       displayName       currencyName       currencySymbol       isDigitalPayment     }     shippingMethod {       id       displayName       deliveryCharge       deliveryTimeInDays       WeightUnit       isFlat     }     couponCode {       code     }     payments {       isPaid       payableAmount       gatewayName     }     feedback {       rating       comment       createdAt     }   } }`,
      };

      axios.post(`${Settings.GetApiUrl()}`, payload, {
        headers: {
          'store-key': Settings.GetLiveMartKey(),
          'store-secret': Settings.GetLiveMartSecret()
        },
      }).then((resp) => {
        this.order = resp.data.data.orderByAdmin;
        console.log(this.order);

        // Loading flag
        this.isLoading = false;
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.response.data.title;
      });
    },
    getFullImagePath(subPath) {
      return Settings.GetMediaUrl() + subPath;
    },
    parseDateTime(date) {
      return moment(date, "YYYY-MM-DD").fromNow();
    },
    toDisplayUnit(v) {
      return (v / 100).toFixed(2);
    },
    onGenerateInvoice: function () {
      let id = this.$route.params.id;
      window.open(`${Settings.GetCoreApiUrl()}/invoice/generate/${id}?storeKey=${Settings.GetLiveMartKey()}&storeSecret=${Settings.GetLiveMartSecret()}`, '_blank');
    }
  },
};
</script>

<style scoped>
.line-hr {
  border-top: solid 1px #eaedec;
}

.line-yhr {
  border-top: solid 1px #eaedec;
  border-bottom: solid 1px #eaedec;
}

.content-wrapper {
  padding: 0 30px;
}

.head {
  height: 43px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-left: 15px;
}

.bg-white {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.img-nav:hover {
  cursor: pointer;
}

.information-container {
  position: relative;
  width: auto;
}

.information-container p {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
  margin-bottom: 14px;
}

.tag {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  border-radius: 12px;
  padding: 3px 14px;
}

.tag-delivered {
  color: #287166;
  background-color: #caece7;
}

.tag-completed {
  color: #287166;
  background-color: #caece7;
}

.tag-lending {
  color: #287166;
  background-color: #caece7;
}

.tag-pending {
  background-color: #fae5bd;
  color: #8e620b;
}

.tag-rejected {
  background-color: #fac7bd;
  color: #8e210b;
}

.tag-returned {
  background-color: #fac7bd;
  color: #8e210b;
}

.tag-shipping {
  background-color: #dcb3f5;
  color: #552871;
}

.tag-processing {
  background-color: #dcb3f5;
  color: #552871;
}

.tag-refund {
  background-color: #ecf1fe;
  color: #132eaa;
}

.form-bg {
  height: auto;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  padding: 24px;
  margin-top: 20px;
}

.p-24 {
  padding: 24px;
}

.pl-24 {
  padding-left: 24px;
}

.pr-24 {
  padding-right: 24px;
}

.container-info-more {
  width: auto;
  height: auto;
}

.container-info-more ul {
  border-bottom: solid 1px #e9eceb;
}

.container-info-more .lItem {
  margin-right: 28px;
}

/*.container-info-more .lItem:has(> a.active) {
        border-bottom: solid 2px #132eaa;
    }*/

.container-info-more .lItem a {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
  text-decoration: none;
}

.container-info-more ul a.active {
  color: #132eaa;
  border-bottom: solid 2px #132eaa;
}

.pill-title {
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
}

.pill-thead {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
}

.pill-thead tr th {
  padding: 8px 0;
  border-top: solid 1px #eaedec;
  border-bottom: solid 1px #eaedec;
}

.pill-trow {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3f4a5a;
}

.pill-trow td {
  border: 0;
  padding: 7px 0;
}

.price-total {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #3f4a5a;
}

.payment-info-container {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
}

.payment-info-container p {
  margin-bottom: 14px;
}

.billing-info-container {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
}

.billing-info-container p {
  margin-bottom: 14px;
}

#customer .billing-info-container {
  overflow-y: scroll;
}

.table-bg {
  width: auto;
  border-radius: 0;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  background-color: #ffffff;
}

.thead-bg {
  background-color: #ecf1fe;
}

.thead-bg th {
  border: 0;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
}

.t-rows {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373b3d;
}

.t-rows td img {
  border: solid 1px #ced9f5;
  padding: 1px;
}

.order-history-container {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
}

.order-history-container .dot {
  width: 10px;
  height: 10px;
  border-radius: 5px;
}

.dot-green {
  background-color: #1f7a6d;
}

.dot-yellow {
  background-color: #d69a23;
}

.dot-purple {
  background-color: #961fdf;
}

.dot-blue {
  background-color: #1f3c95;
}

.order-history-container ul li {
  margin-bottom: 24px;
}

/*.order-history-container ul li:before{
        content: "";
        width: 5px;
        height: 5px;
        background-color: #1f7a6d;
        display: block;
        float: left;
        margin-right: 5px;
    }*/
.info {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
}

.info p {
  margin-bottom: 8px;
}

.contact-info {
  position: relative;
  margin-top: 16px;
}

.contact-info-title {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  margin-bottom: 15px !important;
}

.contact-info p {
  margin-bottom: 14px;
}

.notes {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #6b7694;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }
}
</style>
