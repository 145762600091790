<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"
    ></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <div class="col-md-12 text-nowrap">
            <div class="d-flex flex-column flex-sm-row justify-content-between">
              <h5 class="title-header">Collections</h5>

              <button
                  @click="onCreateCollection"
                  class="btn text-capitalize btn-add-product mt-2 mt-sm-0">
                <span class="pr-3"><i class="fas fa-plus"></i></span>
                Add Collection
              </button>
            </div>
          </div>
        </div>
        <!--    Filter starts   -->
        <div class="row">
          <div class="col-md-12">
            <div class="bg-white text-nowrap d-flex flex-column flex-sm-row">
              <button class="btn btn-filter">Add Filter</button>

              <div class="pl-4 w-100 d-flex align-items-center">
                <input
                    v-model="searchQuery"
                    v-on:keyup.enter="onKeyPressed"
                    type="text"
                    class="w-100 field"
                    placeholder="Search for Products"
                />

                <img v-on:click="listCollectionsByAdmin" :src="'./assets/icons/page/search.svg'" class="pr-3"/>
              </div>
            </div>
          </div>
        </div>
        <!--    Filter ends   -->
        <!--    Table starts   -->
        <div class="row">
          <div class="col-md-12">
            <div class="table-bg">
              <table class="table mb-0">
                <thead class="thead-bg">
                <tr class="text-nowrap">
                  <th class="text-left">Collection Name</th>
                  <th class="text-center">Products</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Position</th>
                  <th class="text-right"></th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="collection in collectionList"
                    :key="collection.id"
                    class="t-rows">
                  <td class="text-left">
                    {{ collection.name }}
                  </td>
                  <td class="text-center">{{ collection.productCount ? collection.productCount : 0 }}</td>
                  <td class="text-center">
                      <span
                          v-if="collection.isPublished"
                          class="btn btn-published">Published</span>
                    <span v-else class="btn btn-published btn-reject">Draft</span>
                  </td>
                  <td class="text-center">{{ collection.position }}</td>
                  <td class="text-right pr-34">
                    <button class="btn btn-option" data-toggle="dropdown">
                      <i class="fas fa-ellipsis-h"></i>
                    </button>
                    <div
                        class="
                          dropdown-menu dropdown-menu-right
                          p-0
                          dropdown-bg
                        ">
                      <div
                          @click="onCollectionUpdate(collection.id)"
                          class="list d-flex justify-content-between">
                        <span>Edit</span>
                        <i class="fas fa-pencil-alt"></i>
                      </div>
                      <div
                          @click="onCollectionDelete(collection.id)"
                          class="list d-flex justify-content-between">
                        <span>Delete</span>
                        <i class="far fa-trash-alt"></i>
                      </div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--    Table ends   -->
        <div class="my-5">
          <BPagination
              align="center"
              :limit="10"
              :hide-goto-end-buttons="true"
              v-model="currentPage"
              v-on:change="onPaginationChange"
              :total-rows="rows"
              :per-page="perPage">
          </BPagination>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {BPagination} from "bootstrap-vue";
import axios from "axios";

import Header from "../../../components/Header";
import Settings from "../../../common/settings";
import PleaseWait from "../../PleaseWait";
import ErrorComponent from "../../ErrorComponent";
import SideBarLiveMart from "../../SideBarLiveMart";
import SessionStore from "../../../common/session_store";

export default {
  name: "CollectionsView",
  components: {
    SideBarLiveMart,
    ErrorComponent,
    PleaseWait,
    Header,
    BPagination,
  },
  data() {
    return {
      // Flags
      isLoading: false,
      errors: "",

      // Variables
      rows: 10000,
      currentPage: null,
      perPage: 25,
      searchQuery: "",
      filterKeyCollection: "",
      filterKeyCategory: "",

      // Data
      collectionList: [],
      isSearchRequested: false
    };
  },
  mounted() {
    this.isSearchRequested = true;
    this.currentPage = SessionStore.getCurrentPage('collections');
    this.listCollectionsByAdmin();
  },
  methods: {
    onPaginationChange: function (p) {
      console.log(`Page: ${p}`);
      this.currentPage = p;
      this.listCollectionsByAdmin()
    },
    onKeyPressed: function () {
      this.isSearchRequested = true;
      this.listCollectionsByAdmin();
    },
    listCollectionsByAdmin: function () {
      if (!this.isSearchRequested) {
        return
      }

      this.isLoading = true;

      if (SessionStore.getQuery('collections') !== this.searchQuery) {
        this.currentPage = 1;
      }
      SessionStore.setQuery('collections', this.searchQuery);

      let payload = {
        query: `{
            collectionsByAdmin(search: { query: "${this.searchQuery.toLowerCase()}", filters: [ { key: collection value: "${this.filterKeyCollection}" } { key: category value: "${this.filterKeyCategory}" } ] }, sort: { by: Position direction: Desc }, pagination: {perPage: ${this.perPage} page: ${this.currentPage}}){
                id
                name
                slug
                isPublished
                description
                image
                createdAt
                updatedAt
                productCount
                position
            }
        }`,
      };

      axios.post(Settings.GetApiUrl(), payload, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        this.isLoading = false;
        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
            return;
          }
          this.collectionList = rs.collectionsByAdmin;
          SessionStore.setCurrentPage('collections', this.currentPage);
        }
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.errors[0].message;
      });
    },
    onCollectionDelete: function (id) {
      this.isLoading = true;

      let pld = {
        query: `mutation { deleteCollection(id: "` + id + `") }`
      }

      axios.post(
          `${Settings.GetApiUrl()}`,
          pld,
          {
            headers: {
              "store-key": Settings.GetLiveMartKey(),
              "store-secret": Settings.GetLiveMartSecret(),
            },
          }
      ).then(() => {
        this.isLoading = false;
        window.location.reload();
        // eslint-disable-next-line no-unused-vars
      }).catch((err) => {
        this.isLoading = false;
      });
    },
    onCollectionUpdate: function (id) {
      this.$router.push(`/store/collections/update/${id}`);
    },
    onCreateCollection: function () {
      this.$router.push("/store/collections/add");
    },
  },
};
</script>

<style scoped>
.content-wrapper {
  padding: 0 30px;
}

.bg-white,
.table-bg {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-top: 5px;
}

.btn-add-product {
  border-radius: 3px;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 11px 19px;
  border: 0;
  outline: none;
}

.btn-filter {
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #132eaa;
  padding: 15px 38px;
  border-right: 1px solid #e4eaec;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.helper img {
  display: block;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

input[type="text"] {
  height: 49px;
  font-size: 14px;
  border: 0;
  padding: 0 10px;
  outline: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaafbc;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

.field {
}

.table-bg {
  margin-top: 24px;
  overflow-y: visible;
}

.thead-bg {
  background-color: #ecf1fe;
}

.pl-34 {
  padding-left: 34px;
}

.pr-34 {
  padding-right: 34px;
}

.thead-bg th {
  border: 0;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
}

.t-rows {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373b3d;
}

tbody tr td {
  vertical-align: middle;
}

.btn-published {
  cursor: default;
  border-radius: 14px;
  background-color: #caece7;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #287166;
  padding: 6px 17px;
  border: 0;
  outline: none;
}

.btn-reject {
  background-color: #e0e0e0;
  color: #333333;
}

.btn-published:focus,
.btn-add-product:focus {
  outline: none;
  border: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-option {
  border-radius: 14px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b8c5cc;
  padding: 3px 10px;
  border: 0;
  outline: none;
}

.dropdown-bg {
  min-width: 132px;
  border-bottom: 0;
}

.dropdown-bg .list {
  padding: 10px 12px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
  border-bottom: solid 1px #dbe2e6;
}

.dropdown-bg .list:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }

  .btn-filter {
    border-right: 0;
    border-bottom: 1px solid #e4eaec;
  }
}
</style>
