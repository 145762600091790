<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
  </div>
</template>

<script>
import PleaseWait from "@/components/PleaseWait";
import SessionStore from "@/common/session_store";
import axios from "axios";
import Settings from "@/common/settings";

export default {
  name: "PrepareCockpit",
  components: {PleaseWait},
  data() {
    return {
      isLoading: true
    }
  },
  mounted() {
    let storeKey = this.$route.query.storeKey;
    let storeSecret = this.$route.query.storeSecret;

    if (storeKey === undefined || storeSecret === undefined) {
      this.isLoading = false;
      this.$dlg.toast('You are not allowed to access');
      return
    }

    storeKey = storeKey.trim();
    storeSecret = storeSecret.trim();

    if (storeKey === '' || storeSecret === '') {
      this.isLoading = false;
      this.$dlg.toast('You are not allowed to access');
      return
    }

    SessionStore.SetStoreKey(storeKey);
    SessionStore.SetStoreSecret(storeSecret);

    this.getStore();
  },
  methods: {
    getStore: function () {
      let payload = {
        query: `query { storeBySecret { name title description tags metaName metaDescription metaTags logo favicon bannerImage isOpen currency website supportEmail createdAt updatedAt } }`,
      };

      axios.post(`${Settings.GetApiUrl()}`, payload, {
        headers: {
          'store-key': Settings.GetLiveMartKey(),
          'store-secret': Settings.GetLiveMartSecret()
        },
      }).then((resp) => {
        let store = resp.data.data.storeBySecret;
        SessionStore.SetStore(store);

        // Loading flag
        this.isLoading = false;
        this.$dlg.toast('Store dashboard loaded.');
        setTimeout(function () {
          window.location.href = '/#/store';
        }, 500)
        // eslint-disable-next-line no-unused-vars
      }).catch((err) => {
        this.isLoading = false;
        this.$dlg.toast('Unauthorized access request');
      });
    }
  }
}
</script>
