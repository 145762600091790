/* eslint-disable */
import Vue from 'vue';
import App from './App.vue';
import VueRouter from "vue-router";
import VueSimpleMarkdown from 'vue-simple-markdown';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
import VueApexCharts from "vue-apexcharts";
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import iView from 'iview';
import 'iview/dist/styles/iview.css';
import locale from 'iview/dist/locale/en-US';
import {store} from './store_states';
import VueClipboard from 'vue-clipboard2';

import Dashboard from "./components/Dashboard";
import ProductsView from "./components/catalog/products/ProductsView";
import ProductsAddView from "./components/catalog/products/ProductsAddView";
import OrdersView from "./components/orders/OrdersView";
import CollectionsView from "./components/catalog/collections/CollectionsView";
import CollectionsAddView from "./components/catalog/collections/CollectionsAddView";
import CategoryAddView from "./components/catalog/categories/CategoryAddView";
import CategoriesView from "./components/catalog/categories/CategoriesView";
import CustomersView from "./components/CustomersView";
import OrderDetailsView from "./components/orders/OrderDetailsView";
import CouponsView from "./components/coupons/CouponsView";
import CouponAddView from "./components/coupons/CouponAddView";
import StoreConfig from "./components/settings/StoreConfig";
import CategoryUpdateView from "./components/catalog/categories/CategoryUpdateView";
import CollectionUpdateView from "./components/catalog/collections/CollectionUpdateView";
import ProductUpdateView from "./components/catalog/products/ProductUpdateView";
import CouponUpdateView from "./components/coupons/CouponUpdateView";
import ShippingMethodListView from "./components/shipping_method/ShippingMethodListView";
import ShippingMethodCreateView from "./components/shipping_method/ShippingMethodCreateView";
import ShippingMethodUpdateView from "./components/shipping_method/ShippingMethodUpdateView";
import PaymentMethodListView from "./components/payment_method/PaymentMethodListView";
import PrepareCockpit from "./components/PrepareCockpit";
import Dialog from 'v-dialogs';
import BlogsView from "./components/blog/BlogsView";
import BlogUpdateView from "./components/blog/BlogUpdateView";
import BlogAddView from "./components/blog/BlogAddView";
import DigitalContentsView from "./components/catalog/products/digitalcontents/DigitalContentsView";
import DigitalItemsView from "./components/catalog/products/digitalitems/DigitalItemsView";
import DigitalItemAdd from "./components/catalog/products/digitalitems/DigitalItemAdd";
import DigitalItemUpdate from "./components/catalog/products/digitalitems/DigitalItemUpdate";
import DigitalContentAdd from "./components/catalog/products/digitalcontents/DigitalContentAdd";
import DigitalContentUpdate from "./components/catalog/products/digitalcontents/DigitalContentUpdate";
import OrdersNew from "./components/orders/OrdersNew";
import ProductAttributesView from "./components/catalog/products/attributes/ProductAttributesView";
import StoreMenuListView from "./components/storemenu/StoreMenuListView";
import StoreMenuAddView from "./components/storemenu/StoreMenuAddView";
import StoreMenuUpdateView from "./components/storemenu/StoreMenuUpdateView";
import StoreBannerListView from "./components/storebanner/StoreBannerListView";
import StoreBannerAddView from "./components/storebanner/StoreBannerAddView";
import StoreBannerUpdateView from "./components/storebanner/StoreBannerUpdateView";
import StorePagesView from "./components/storepage/StorePagesView";
import StorePageUpdateView from "./components/storepage/StorePageUpdateView";
import StorePageAddView from "./components/storepage/StorePageAddView";
import StoreMediaListing from "./components/StoreMedia/StoreMediaListing";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import ProductHistory from "./components/catalog/products/ProductHistory";

Vue.use(VueClipboard);
Vue.use(VueSweetalert2);

Vue.use(Dialog, {
    language: 'en'
});

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueSimpleMarkdown);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('apexchart', VueApexCharts);

Vue.use(VueRouter);
// iView
Vue.use(iView, {locale: locale});

const routes = [
    {
        path: '/cockpit',
        component: PrepareCockpit
    },
    {
        path: '/store',
        component: Dashboard
    },
    {
        path: '/store/store-config',
        component: StoreConfig
    },
    {
        path: '/store/products',
        component: ProductsView
    },
    {
        path: '/store/products/update/:id',
        component: ProductUpdateView
    },
    {
        path: '/store/products/history/:id',
        component: ProductHistory
    },
    {
        path: '/store/products/add',
        component: ProductsAddView
    },
    {
        path: '/store/products/digital-items/:id/view',
        component: DigitalItemsView
    },
    {
        path: '/store/products/digital-items/:id/update/:did',
        component: DigitalItemUpdate
    },
    {
        path: '/store/products/digital-items/:id/add',
        component: DigitalItemAdd
    },
    {
        path: '/store/products/digital-items/:id/digital-contents/:did/view',
        component: DigitalContentsView
    },
    {
        path: '/store/products/digital-items/:id/digital-contents/:did/add',
        component: DigitalContentAdd
    },
    {
        path: '/store/products/digital-items/:id/digital-contents/:did/update/:cid',
        component: DigitalContentUpdate
    },
    {
        path: '/store/products/attributes/:id',
        component: ProductAttributesView
    },
    {
        path: '/store/categories',
        component: CategoriesView
    },
    {
        path: '/store/categories/update/:id',
        component: CategoryUpdateView
    },
    {
        path: '/store/categories/add',
        component: CategoryAddView
    },
    {
        path: '/store/collections',
        component: CollectionsView
    },
    {
        path: '/store/collections/update/:id',
        component: CollectionUpdateView
    },
    {
        path: '/store/collections/add',
        component: CollectionsAddView
    },
    {
        path: '/store/orders',
        component: OrdersView
    },
    {
        path: '/store/orders/new',
        component: OrdersNew
    },
    {
        path: '/store/order/:id',
        component: OrderDetailsView
    },
    {
        path: '/store/customers',
        component: CustomersView
    },
    {
        path: '/store/coupons',
        component: CouponsView
    },
    {
        path: '/store/coupon/update/:id',
        component: CouponUpdateView
    },
    {
        path: '/store/coupon/add',
        component: CouponAddView
    },
    {
        path: '/store/shipping-methods',
        component: ShippingMethodListView
    },
    {
        path: '/store/shipping-methods/add',
        component: ShippingMethodCreateView
    },
    {
        path: '/store/shipping-methods/update/:id',
        component: ShippingMethodUpdateView
    },
    {
        path: '/store/payment-methods',
        component: PaymentMethodListView
    },
    {
        path: '/store/blog-posts',
        component: BlogsView
    },
    {
        path: '/store/blog-posts/update/:id',
        component: BlogUpdateView
    },
    {
        path: '/store/blog-posts/add',
        component: BlogAddView
    },
    {
        path: '/store/pages',
        component: StorePagesView
    },
    {
        path: '/store/pages/update/:id',
        component: StorePageUpdateView
    },
    {
        path: '/store/pages/add',
        component: StorePageAddView
    },
    {
        path: '/store/menus',
        component: StoreMenuListView
    },
    {
        path: '/store/menus/add',
        component: StoreMenuAddView
    },
    {
        path: '/store/menus/update/:id',
        component: StoreMenuUpdateView
    },
    {
        path: '/store/banners',
        component: StoreBannerListView
    },
    {
        path: '/store/banners/add',
        component: StoreBannerAddView
    },
    {
        path: '/store/banners/update/:id',
        component: StoreBannerUpdateView
    },
    {
        path: '/store/media',
        component: StoreMediaListing
    },
];

const router = new VueRouter({
    mode: 'hash',
    routes,
});

new Vue({
    store,
    render: h => h(App),
    router
}).$mount('#app');
