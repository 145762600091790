<template>
  <div>
    <input class="form-control" placeholder="Attribute Name" v-model="attributeName"/>
    <tags-input class="mt-2 mb-2" element-id="tags" v-model="options" :typeahead="false"
                :placeholder="attributeOptionsPlaceholder"></tags-input>
    <span class="mt-5">Is Required: <checkbox v-model="isRequired"></checkbox></span>
    <div class="mt-2">
      <button class="btn btn-primary" v-on:click="onUpdate">Update</button>
    </div>
  </div>
</template>

<script>
import VoerroTagsInput from "@voerro/vue-tagsinput";
import axios from "axios";
import Settings from "../../../../common/settings";

export default {
  name: "ProductAttributeUpdate",
  components: {
    'tags-input': VoerroTagsInput
  },
  props: {
    productId: String,
    attribute: Object,
  },
  data() {
    return {
      attributeName: '',
      attributeOptionsPlaceholder: '  Attribute Options',
      options: [],
      isRequired: false
    }
  },
  mounted() {
    this.attributeName = this.attribute.name;
    this.attribute.values.forEach(v => {
      this.options.push({
        key: v,
        value: v
      });
    })
    this.isRequired = this.attribute.isRequired;
  },
  methods: {
    onUpdate: function () {
      if (this.attributeName === '') {
        return
      }

      let optionQuery = '';
      this.options.forEach(it => {
        optionQuery += `"${it.value}"`;
      });

      let payload = {
        query: `mutation { productUpdateAttribute(productId: "${this.productId}", attributeId: "${this.attribute.id}" params: { name: "${this.attributeName}" values: [ ${optionQuery} ] isRequired: ${this.isRequired} }) { id } }`,
      };

      axios.post(`${Settings.GetApiUrl()}`, payload, {
        headers: {
          'store-key': Settings.GetLiveMartKey(),
          'store-secret': Settings.GetLiveMartSecret()
        },
        // eslint-disable-next-line no-unused-vars
      }).then((resp) => {
        this.$emit('close', {isSuccess: true});
        // eslint-disable-next-line no-unused-vars
      }).catch((err) => {
        this.$emit('close', {isSuccess: false});
        this.$dlg.toast('Failed to add attribute');
      });
    }
  }
}
</script>

<style scoped>

</style>