<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2 mb-5">
        <div class="row">
          <!--    Left side start     -->
          <div class="col-lg-8 mb-5">
            <!--    Head start     -->
            <div class="row head">
              <div class="col-md-12 d-flex align-items-center">
                <i
                    @click="$router.go(-1)"
                    class="fas fa-chevron-left img-nav"
                ></i>
                <h5 class="title-header">Store Banners</h5>
              </div>
            </div>

            <!--    Head end     -->
            <div class="form-bg">
              <h5 class="title-header pl-0">Add Banner</h5>

              <ValidationObserver ref="form">
                <!--    Product Name    -->
                <div class="form-container">
                  <div class="form-group">
                    <label class="label">Title</label>
                    <ValidationProvider
                        rules="required|minlen:1"
                        :bails="false"
                        v-slot="{ errors }">
                      <input
                          v-model="title"
                          type="text"
                          class="form-control input-field"
                          id="pname"
                          placeholder="Banner title"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="form-container">
                  <div class="form-group">
                    <label class="label">Button Text</label>
                    <ValidationProvider
                        :bails="false"
                        v-slot="{ errors }">
                      <input
                          v-model="btnValue"
                          type="text"
                          class="form-control input-field"
                          id="pname"
                          placeholder="Banner button text"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="form-container">
                  <div class="form-group">
                    <label class="label">Button URL</label>
                    <ValidationProvider
                        :bails="false"
                        v-slot="{ errors }">
                      <input
                          v-model="btnUrl"
                          type="text"
                          class="form-control input-field"
                          id="pname"
                          placeholder="Banner button URL"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="form-container">
                  <div class="form-group">
                    <label class="label">Banner Tag</label>
                    <ValidationProvider
                        :bails="false"
                        v-slot="{ errors }">
                      <input
                          v-model="tag"
                          type="text"
                          class="form-control input-field"
                          id="pname"
                          placeholder="Banner tag"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <div class="form-container">
                  <div class="form-group">
                    <label class="label">Banner Position</label>
                    <ValidationProvider
                        :bails="false"
                        rules="required"
                        v-slot="{ errors }">
                      <input
                          v-model="position"
                          type="number"
                          class="form-control input-field"
                          id="pname"
                          placeholder="Banner position"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>

                <!--    Product image upload  -->
                <div class="">
                  <div class="d-block d-md-flex">
                    <h5 class="label pl-0">Banner Image</h5>

                    <div v-if="coverImage" class="ml-auto mt-2 mt-md-0">
                      <button
                          class="btn text-capitalize file-added text-nowrap"
                          @click="$refs.file.click()">
                        <img
                            :src="'./assets/icons/page/cloud-upload.svg'"
                            alt=""
                        />
                        Upload Again
                      </button>
                    </div>
                  </div>

                  <input
                      @change="onChangePreview"
                      type="file"
                      ref="file"
                      style="display: none"
                  />
                  <div class="overlay text-center dropzone">
                    <PreviewImageComponent
                        v-if="coverImage"
                        :image-src="coverImage"
                    />
                    <div
                        @click="$refs.file.click()"
                        v-if="!coverImage"
                        class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">
                        Click here to
                        <span class="b-link">Browse on your computer</span>
                      </h3>
                      <div class="dropzone-custom-title">
                        Recommended image size is 1240x930px.
                      </div>
                    </div>
                  </div>
                </div>
                <!--    Product image upload end  -->

                <!--    Product description    -->
                <div class="form-container mt-3">
                  <div class="form-group">
                    <label class="label">Banner Description</label>
                    <ValidationProvider
                        rules="required|minlen:1"
                        :bails="false"
                        v-slot="{ errors }">
                      <vue-simplemde
                          v-model="description"
                          preview-class="markdown-body"
                      />
                      <li
                          v-for="error in errors"
                          :key="error"
                          class="label text-danger">
                        {{ error }}
                      </li>
                    </ValidationProvider>
                  </div>
                </div>
              </ValidationObserver>
            </div>
          </div>
          <!--    Left side end     -->

          <!--    Right side start     -->
          <div class="col-lg-4">
            <div class="row text-nowrap">
              <div class="col-md-6 col-lg-6 text-center">
                <button
                    @click="onCreateNewBanner(false)"
                    class="btn text-capitalize btn-sv-draft w-100">
                  Save Draft
                </button>
              </div>
              <div class="col-md-6 col-lg-6 text-center mt-2 mt-md-0">
                <button
                    @click="onCreateNewBanner(true)"
                    class="btn text-capitalize btn-publish w-100">
                  Publish
                </button>
              </div>
            </div>
            <div class="bg-white">
              <CardComponent
                  :title="title"
                  :cover-image="coverImage"
                  :is-category="true"
              />
            </div>
          </div>
          <!--    Right side end     -->
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import VueSimplemde from "vue-simplemde";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {extend} from "vee-validate";
import {required} from "vee-validate/dist/rules";

import SideBarLiveMart from "../SideBarLiveMart";
import PreviewImageComponent from "../catalog/products/PreviewImageComponent";
import PleaseWait from "../PleaseWait";
import ErrorComponent from "../ErrorComponent";
import CardComponent from "../catalog/products/CardComponent";
import Header from "../Header";
import Settings from "../../common/settings";
import MediaService from "../../common/media_service";

export default {
  name: "StoreBannerAddView",
  components: {
    SideBarLiveMart,
    PreviewImageComponent,
    PleaseWait,
    ErrorComponent,
    CardComponent,
    Header,
    VueSimplemde,
  },
  data() {
    return {
      // Flags
      isLoading: false,
      errors: "",

      // Fields
      title: "",
      description: "",
      btnValue: "",
      btnUrl: "",
      tag: "",
      position: 1,
      coverImage: "",
    };
  },
  methods: {
    onCreateNewBanner: function (isPublished) {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        this.isLoading = true;

        let pld = {
          'query': `mutation { newStoreBanner(params: { title: "` + this.title + `" image: "` + this.coverImage + `" description: "` + encodeURIComponent(this.description) + `" ${this.tag !== "" ? ` tag: "${this.tag}"` : ``} ${this.btnValue !== "" ? ` btnValue: "${this.btnValue}"` : ``} ${this.btnUrl !== "" ? ` btnUrl: "${this.btnUrl}"` : ``} isPublished: ` + isPublished + ` position: ${this.position} }) { id } }`
        };

        axios.post(
            `${Settings.GetApiUrl()}`,
            pld,
            {
              headers: {
                "store-key": Settings.GetLiveMartKey(),
                "store-secret": Settings.GetLiveMartSecret()
              },
            }
        ).then(res => {
          this.isLoading = false;
          let data = res.data.data;
          if (data !== null) {
            this.$router.push(`/store/banners`);
            return
          }

          alert(res.data.errors[0].message);
        }).catch((err) => {
          this.isLoading = false;
          if (err.response.status === 422) {
            this.errors = err.response.data.errors.message;
          } else {
            this.errors = err.response.data.title;
          }
        });
      });
    },
    onChangePreview: function (ev) {
      let files = ev.target.files || ev.dataTransfer.files;

      if (files.length > 0) {
        this.coverImage = "";
        this.isLoading = true;

        MediaService.uploadContent(files[0], "banners").then(resp => {
          this.isLoading = false;

          if (resp.status === 200) {
            let rs = resp.data.data;
            if (rs === null) {
              this.errors = resp.data.errors[0].message;
              return
            }

            this.coverImage = rs.newMedia;
          }
        }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}

/***    Validators   ***/
extend("required", {
  ...required,
  message: "This field is required",
});
</script>

<style scoped>
@import "~simplemde/dist/simplemde.min.css";
@import "~github-markdown-css";

.content-wrapper {
  padding: 0 30px;
}

.head {
  height: 43px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-left: 15px;
}

.bg-white {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.img-nav:hover {
  cursor: pointer;
}

.form-bg {
  height: auto;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  padding: 24px;
  margin-top: 20px;
}

.form-container {
  width: 100%;
  margin-top: 24px;
}

.label {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6b7694;
}

.file-added {
  border: solid 1px #dee2ed;
  background-color: #f6f8fc;
  color: #5c6366;
  padding: 10px 20px;
  border-radius: 30px;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
}

.file-added:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.file-added img {
  margin: 0 4px;
}

.input-field {
  border-radius: 3px;
  border: solid 1px rgba(90, 99, 124, 0.16);
}

.input-field:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #aaafbc;
  opacity: 1; /* Firefox */
}

.dropzone {
  margin-top: 20px;
  border-radius: 3px;
  border: dashed 2px rgba(90, 99, 124, 0.16);
}

.dropzone-custom-content:hover {
  cursor: pointer;
}

.dropzone-custom-title {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #b8c5cc;
}

.b-link {
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  color: #132eaa;
}

.btn-sv-draft {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #5a637c;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-sv-draft:hover {
  border: solid 2px #c2d2ff;
}

.btn-sv-draft:focus {
  outline: none;
  border: solid 2px #c2d2ff;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-publish {
  border-radius: 3px;
  border: solid 2px transparent;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-top: 11px;
  padding-bottom: 11px;
  outline: none;
}

.btn-publish:focus {
  outline: none;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }
}
</style>
