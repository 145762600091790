<template>
  <div>
    <PleaseWait :isLoading="isLoading"/>
    <ErrorComponent
        v-if="errors !== ''"
        v-on:getErrorFlag="
        (ev) => {
          this.errors = ev;
        }
      "
        :err-msg="errors"
    ></ErrorComponent>
    <SideBarLiveMart/>
    <main>
      <Header/>
      <div class="content-wrapper mt-2">
        <div class="row">
          <div class="col-md-12 text-nowrap">
            <div class="d-flex flex-column flex-sm-row justify-content-between">
              <h5 class="title-header">Store Media & Files</h5>

              <button
                  @click="$refs['media-upload'].click()"
                  class="btn text-capitalize btn-add-product mt-2 mt-sm-0">
                <span class="pr-3"><i class="fas fa-upload"></i></span>
                Upload
              </button>
            </div>
            <input
                @change="onUpload"
                type="file"
                ref="media-upload"
                style="display: none"
            />
          </div>
        </div>

        <div class="row" style="margin-top: 10px">
          <ul>
            <li v-for="m in mediaList" :key="m.path">
              <img :src="getThumb(m)">
              <div class="row" style="margin-left: 10px; margin-right: 10px; align-items: center; margin-top: 5px">
                <button @click="onCopy(m.permanentUrl)" class="btn btn-outline-secondary column"
                        style="margin-right: 5px">URL
                </button>
                <button @click="onCopy(m.url)" class="btn btn-outline-secondary column"
                        style="margin-right: 5px">
                  CDN
                  URL
                </button>
                <button @click="onDelete(m.path)" class="btn btn-outline-danger column">Delete</button>
              </div>
            </li>
          </ul>
        </div>

        <div class="my-5">
          <BPagination
              align="center"
              :limit="3"
              :hide-goto-end-buttons="true"
              :link-gen="refreshList"
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage">
          </BPagination>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {BPagination} from "bootstrap-vue";

import axios from "axios";
import Settings from "../../common/settings";
import Header from "../Header";
import SideBarLiveMart from "../SideBarLiveMart";
import ErrorComponent from "../ErrorComponent";
import PleaseWait from "../PleaseWait";
import SessionStore from "../../common/session_store";
import MediaService from "../../common/media_service";

export default {
  name: "StorePagesView",
  components: {
    SideBarLiveMart,
    ErrorComponent,
    PleaseWait,
    Header,
    BPagination,
  },
  data() {
    return {
      // Flags
      isLoading: false,
      errors: "",

      // Variables
      rows: 100000,
      currentPage: null,
      perPage: 12,

      // Data
      mediaList: [],
    };
  },
  mounted() {
    this.listStoreMediaByAdmin()
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    refreshList: function () {
      if (this.currentPage === null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentPage = SessionStore.getCurrentPage('media');
      }

      this.listStoreMediaByAdmin();
    }
  },
  methods: {
    listStoreMediaByAdmin: function () {
      this.isLoading = true;

      let payload = {
        query: `{
            mediaByAdmin(sort: { by: CreatedAt direction: Desc }, pagination: {perPage: ${this.perPage} page: ${this.currentPage}}){
                path
                url
                permanentUrl
            }
        }`,
      };

      axios.post(Settings.GetApiUrl(), payload, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        this.isLoading = false;
        if (resp.status === 200) {
          let rs = resp.data.data;
          if (rs === null) {
            this.errors = resp.data.errors[0].message;
            return;
          }
          this.mediaList = rs.mediaByAdmin;
          SessionStore.setCurrentPage('pages', this.currentPage);
        }
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.errors[0].message
      });
    },
    onUpload: function (ev) {
      let files = ev.target.files || ev.dataTransfer.files;

      if (files.length > 0) {
        this.coverImage = "";
        this.isLoading = true;

        MediaService.uploadContent(files[0], "media").then(resp => {
          this.isLoading = false;

          if (resp.status === 200) {
            let rs = resp.data.data;
            if (rs === null) {
              this.errors = resp.data.errors[0].message;
              return
            }

            this.listStoreMediaByAdmin();
          }
        }).catch(err => {
          console.log(err);
        });
      }
    },
    onDelete: function (path) {
      this.isLoading = true;

      let payload = {
        query: `mutation { deleteMedia(path: "${path}") }`,
      };

      axios.post(Settings.GetApiUrl(), payload, {
        headers: {
          "store-key": Settings.GetLiveMartKey(),
          "store-secret": Settings.GetLiveMartSecret(),
        },
      }).then((resp) => {
        this.isLoading = false;
        if (resp.status === 200) {
          this.listStoreMediaByAdmin();
        }
      }).catch((err) => {
        this.isLoading = false;
        this.errors = err.errors[0].message
      });
    },
    onCopy: function (url) {
      let container = this.$refs.container
      this.$copyText(url, container)
      alert('CDN URL Copied');
    },
    getThumb: function (m) {
      if (this.isImage(m.path)) {
        return m.url;
      }
      return './assets/icons/page/default_image.png';
    },
    isImage: function (path) {
      return path.toLowerCase().endsWith('jpg') || path.toLowerCase().endsWith('jpeg')
          || path.toLowerCase().endsWith('png') || path.toLowerCase().endsWith('bpm') ||
          path.toLowerCase().endsWith('gif')
    }
  },
}
</script>

<style scoped>

.content-wrapper {
  padding: 0 30px;
}

.bg-white,
.table-bg {
  position: relative;
  width: 100%;
  border-radius: 3px;
  box-shadow: 2px 3px 8px 0 rgba(209, 205, 199, 0.08);
  border: solid 1px #e9eceb;
  background-color: #ffffff;
  margin-top: 20px;
}

.title-header {
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #363b4a;
  padding-top: 5px;
}

.btn-add-product {
  border-radius: 3px;
  background-color: #132eaa;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 11px 19px;
  border: 0;
  outline: none;
}

.btn-filter {
  border-radius: 0;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.2px;
  text-align: center;
  color: #132eaa;
  padding: 15px 38px;
  border-right: 1px solid #e4eaec;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.helper img {
  display: block;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
}

input[type="text"] {
  height: 49px;
  font-size: 14px;
  border: 0;
  padding: 0 10px;
  outline: none;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #aaafbc;
  font-size: 14px;
  opacity: 1; /* Firefox */
}

.field {
}

.table-bg {
  margin-top: 24px;
  overflow-y: visible;
}

.thead-bg {
  background-color: #ecf1fe;
}

.pl-34 {
  padding-left: 34px;
}

.pr-34 {
  padding-right: 34px;
}

.thead-bg th {
  border: 0;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
}

.t-rows {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #373b3d;
}

tbody tr td {
  vertical-align: middle;
}

.btn-published {
  border-radius: 14px;
  background-color: #caece7;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #287166;
  padding: 6px 17px;
  border: 0;
  outline: none;
}

.btn-reject {
  background-color: #e0e0e0;
  color: #333333;
}

.btn-published:focus,
.btn-add-product:focus {
  outline: none;
  border: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

.btn-option {
  border-radius: 14px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #b8c5cc;
  padding: 3px 10px;
  border: 0;
  outline: none;
}

.dropdown-bg {
  min-width: 132px;
  border-bottom: 0;
}

.dropdown-bg .list {
  padding: 10px 12px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5c6366;
  border-bottom: solid 1px #dbe2e6;
}

.dropdown-bg .list:hover {
  cursor: pointer;
  background-color: #f6f6f6;
}

/* Small screens < 600px */
@media only screen and (max-width: 600px) {
  main {
    margin-left: 60px;
    padding: 0;
  }

  .btn-filter {
    border-right: 0;
    border-bottom: 1px solid #e4eaec;
  }
}

ul {
  display: flex;
  flex-wrap: wrap;
}

li {
  height: 40vh;
  flex-grow: 1;
  padding: 2px;
}

img {
  max-height: 80%;
  object-fit: cover;
  vertical-align: bottom;
}

</style>
